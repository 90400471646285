import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { useAgencyStore, useAuthStore, useUserStore } from "../lib/store";
import { UPDATE_AGENCY } from "../lib/gql/Agency";
import { QueryResult, useMutation } from "@apollo/client";
import { agencyFactory } from "../lib/models/Agency";
import { UPDATE_ME } from "../lib/gql/Me";
import { userFactory } from "../lib/models";

export interface Fees {
  from: number;
  to: number;
  percent: number;
}

export const AgencyFeesCreatorScreen = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const agency = useAgencyStore();
  const auth = useAuthStore();
  const user = useUserStore();

  const [fees, setFees] = useState<Fees[]>([]);

  const [from, setFrom] = useState<string>("");
  const [to, setTo] = useState<string>("");
  const [percent, setPercent] = useState<string>("");

  const notGood =
    from.length === 0 ||
    isNaN(Number(from)) ||
    to.length === 0 ||
    isNaN(Number(to)) ||
    percent.length === 0 ||
    isNaN(Number(percent)) ||
    Number(from) > Number(to);

  const handleAdd = () => {
    if (notGood) return;
    setFees([
      ...fees,
      { from: Number(from), to: Number(to), percent: Number(percent) }
    ]);
    setFrom("");
    setTo("");
    setPercent("");
  };

  useEffect(() => {
    if (fees.length === 0) return;

    setFrom(String(fees[fees.length - 1].to));
  }, [fees]);

  const handleLogout = () => {
    auth.logout();
    agency.reset();

    navigate("/");
  };

  const [updateCurrUser] = useMutation(UPDATE_ME, {
    onError: (e) => {
      console.log(e);
    }
  });

  const [mutateFunction] = useMutation(UPDATE_AGENCY, {
    variables: {
      id: agency.agency?.id,
      data: { honoraire: JSON.stringify(fees) }
    },
    onCompleted: async ({ updateAgency }: { updateAgency: QueryResult }) => {
      const me = await updateCurrUser({
        variables: {
          id: user.currentUser!.id,
          data: { role: 3 }
        }
      });

      agency.setAgency(agencyFactory(updateAgency.data));
      user.setCurrentUser(userFactory(me.data.updateUsersPermissionsUser.data));

      toast.success(t("agencyCreationFinish"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });

      navigate("/dashboard");
    },
    onError: (e) => {
      console.log("e :>> ", e);
    }
  });

  const handleValidate = () => {
    mutateFunction();
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex justify-center items-center w-10/12 xl:w-8/12">
        <div className="bg-white px-10 pt-10 pb-4 rounded-md shadow-lg my-10">
          <div className="flex justify-between items-center mb-10">
            <div className="basis-1/3 flex justify-center items-center">
              <img className="w-1/2" src={agency.agency!.thumbnail} alt="" />
            </div>
            <div className="basis-2/3 flex flex-col ">
              <h2 className="font-bold">{t("justFees")}</h2>
              <p>{t("enterAgencyFees")}</p>
            </div>
          </div>
          {fees.length > 0 && (
            <div className="flex flex-col items-center xl:items-start justify-center border-b-2 border-grey-500 mb-6 pb-4">
              {fees.map((fee, i) => (
                <p className="text-base" key={i}>
                  {t("from")}{" "}
                  <span className="text-nodimodarkblue font-bold mx-1">
                    {fee.from} €
                  </span>{" "}
                  {t("to")}
                  <span className="text-nodimodarkblue font-bold mx-1">
                    {" "}
                    {fee.to} €
                  </span>
                  :
                  <span className="text-nodimodarkblue font-bold ml-1">
                    {fee.percent}
                  </span>{" "}
                  {fee.percent > 99
                    ? t("commisionEuros")
                    : t("commisionPercent")}
                </p>
              ))}
            </div>
          )}
          <div className="flex flex-row items-center justify-between border-grey-500 mb-2">
            <p className="text-sm">{t("from")}</p>
            <input
              type="text"
              placeholder="0"
              className={`feesInput w-full ${
                isNaN(Number(from)) ? "errorField" : null
              }`}
              value={from}
              onChange={(f) => setFrom(f.target.value)}
            />
            <p className="text-sm">{t("to")}</p>
            <input
              type="text"
              placeholder="50 000"
              className={`feesInput w-full ${
                (to !== "" && Number(from) > Number(to)) || isNaN(Number(to))
                  ? "errorField"
                  : null
              }`}
              value={to}
              onChange={(f) => setTo(f.target.value)}
            />
          </div>
          <div className="flex flex-row items-center justify-between border-b-2 border-grey-500 mb-6 pb-4">
            <div className="flex items-center">
              <input
                type="text"
                placeholder="7"
                className={`feesInput feesInputMax ${
                  isNaN(Number(percent)) ? "errorField" : null
                }`}
                value={percent}
                onChange={(f) => setPercent(f.target.value)}
              />
              <p className="text-sm">
                {Number(percent) > 99
                  ? t("commisionEuros")
                  : t("commisionPercent")}
              </p>
            </div>
            <div
              onClick={handleAdd}
              className={`w-10 h-10 rounded-full flex items-center justify-center ml-4 ${
                notGood
                  ? "bg-nodilightgray cursor-not-allowed"
                  : "bg-nodimodarkblue cursor-pointer"
              } `}
            >
              <i className="fa-solid fa-plus text-white text-xl"></i>
            </div>
          </div>
          <div className="flex flex-col">
            <button
              onClick={handleValidate}
              className="rounded-full w-full bg-nodimoblue my-2"
              disabled={fees.length === 0}
            >
              {t("continue")}
            </button>{" "}
            <button
              className="rounded-full w-full bg-red-400 hover:bg-red-600 my-2"
              onClick={handleLogout}
            >
              {t("logout")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
