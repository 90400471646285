import {
  action,
  observable,
  runInAction,
  makeAutoObservable,
  computed
} from "mobx";
import { makePersistable } from "mobx-persist-store";

import { AccomodationAgency } from "../../models/AccomodationAgency";
import RootStore from "../RootStore";

/**
 * AccomodationStore used to manage room entity.
 */
export default class AccomodationStore {
  /** Accomodation fetch by API link to current login user */
  @observable
  accomodations: AccomodationAgency[] | null = null;

  @observable
  selected: AccomodationAgency | undefined = undefined;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "AccomodationStore",
      properties: ["accomodations", "selected"],
      storage: window.localStorage
    });
  }

  /** Used to set this.accomodations */
  @action
  async setAccomodations(newAccs: AccomodationAgency[] | null) {
    runInAction(() => {
      this.accomodations = newAccs;
    });
  }

  /** Used to set this.selected */
  @action
  async setSelected(newAccs: AccomodationAgency | undefined) {
    runInAction(() => {
      this.selected = newAccs;
    });
  }

  /** Used to reset store value */
  @action
  async reset() {
    this.setAccomodations(null);
    this.setSelected(undefined);
  }

  /** Return true if agency have accomodation, false otherwise */
  @computed
  get haveAccs() {
    return this.accomodations && this.accomodations.length > 0;
  }

  /** Used to get number of property by different slug */
  @computed
  get byLength() {
    const apartmentLength =
      this.accomodations?.filter(
        (accR) => accR.accomodation.type.slug === "apartment"
      ).length || 0;

    const houseLength =
      this.accomodations?.filter(
        (accR) => accR.accomodation.type.slug === "house"
      ).length || 0;

    const fieldLength =
      this.accomodations?.filter(
        (accR) => accR.accomodation.type.slug === "field"
      ).length || 0;

    const othersLength = this.accomodations
      ? this.accomodations.length - apartmentLength - houseLength - fieldLength
      : 0;

    const totalLength = this.accomodations?.length || 0;

    return {
      apartmentLength,
      houseLength,
      fieldLength,
      othersLength,
      totalLength
    };
  }
}
