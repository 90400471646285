import { CUSTOM_ENV } from "./env.type";

export const LOCAL: CUSTOM_ENV = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL_LOCAL!,
  REACT_APP_ENV_NAME: process.env.REACT_APP_ENV_NAME_LOCAL!,
  NODE_ENV: process.env.NODE_ENV,
  PUBLIC_URL: process.env.PUBLIC_URL
};

export const STAGING: CUSTOM_ENV = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL_STAGING!,
  REACT_APP_ENV_NAME: process.env.REACT_APP_ENV_NAME_STAGING!,
  NODE_ENV: process.env.NODE_ENV,
  PUBLIC_URL: process.env.PUBLIC_URL
};

export const PROD: CUSTOM_ENV = {
  REACT_APP_API_URL: process.env.REACT_APP_API_URL!,
  REACT_APP_ENV_NAME: process.env.REACT_APP_ENV_NAME!,
  NODE_ENV: process.env.NODE_ENV,
  PUBLIC_URL: process.env.PUBLIC_URL
};

export const DEFAULT: CUSTOM_ENV =
  process.env.NODE_ENV === "production" ? PROD : STAGING;
