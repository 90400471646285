import { clearObserving } from "mobx/dist/internal";
import { IRole, Role, roleFactory } from "./Role";

/**
 * UserModel interface from API
 */
export interface UserModel {
  /** User id */
  id: string;
  /** User username */
  username: string;
  /** User email */
  email: string;
  /** User first name */
  firstName: string;
  /** User last name */
  lastName: string;
  /** Tell if user confirm email or not */
  confirmed: boolean;
  /** Tell if user is blocked by app */
  blocked: boolean;
  /** Role of user */
  role: Role | null;
}

export interface IUser {
  id: string;
  attributes: {
    username?: string;
    email: string;
    firstName: string;
    lastName: string;
    confirmed?: boolean;
    blocked?: boolean;
    role?: {
      data: IRole;
    };
  };
}

export class User {
  public id: string;

  public username?: string;

  public email: string;

  public firstName: string;

  public lastName: string;

  public confirmed?: boolean;

  public blocked?: boolean;

  public role?: Role | null;

  constructor(userFromFetch: IUser) {
    this.id = userFromFetch.id;
    this.username = userFromFetch.attributes.username || "";
    this.email = userFromFetch.attributes.email;
    this.firstName = userFromFetch.attributes.firstName;
    this.lastName = userFromFetch.attributes.lastName;
    this.confirmed = userFromFetch.attributes.confirmed;
    this.blocked = userFromFetch.attributes.blocked;
    this.role = userFromFetch.attributes.role
      ? roleFactory(userFromFetch.attributes.role.data)
      : null;
  }
}

export const userFactory = (data: IUser): User => {
  return new User(data);
};
