import { IMessage, Message, messageFactory } from "./Message";
import { IUser, User, userFactory } from "./User";

export interface IConversation {
  id: string;
  attributes: {
    user: {
      data: IUser;
    };
    messages: {
      data: IMessage[] | [];
    };
  };
}

export class Conversation {
  public id?: string;

  public user: User;

  public messages: Message[];

  constructor(conversationFromFetch: IConversation) {
    this.id = conversationFromFetch.id;
    this.user = userFactory(conversationFromFetch.attributes.user.data);
    this.messages = conversationFromFetch.attributes.messages.data
      ? conversationFromFetch.attributes.messages.data.map((mes) =>
          messageFactory(mes)
        )
      : [];
  }
}

export const conversationFactory = (data: IConversation): Conversation => {
  return new Conversation(data);
};
