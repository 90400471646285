import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import { useAccomodationStore, useAgencyStore } from "../lib/store";
import { Header } from "../components/Header/Header";
import nodimoLogo from "../assets/images/nodimo_house.png";
import { getCityFromAddress, formatPrice } from "../lib/utils/utils";
import { ACCOMODATIONS_TOTAL } from "../lib/gql/Accomodation";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DashBoardScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [percent, setPercent] = useState<string | null>(null);

  const agencyStore = useAgencyStore();
  const accomodationStore = useAccomodationStore();

  const { data: totalAccomodations } = useQuery(ACCOMODATIONS_TOTAL);

  useEffect(() => {
    if (!totalAccomodations) return;

    const percent = (
      (totalLength * 100) /
      totalAccomodations.accomodationsTotal
    ).toFixed(0);

    setPercent(percent);
  }, [totalAccomodations]);

  const {
    apartmentLength,
    houseLength,
    fieldLength,
    othersLength,
    totalLength
  } = accomodationStore.byLength;

  const circumference = 80 * 2 * Math.PI;

  const haveAccs = accomodationStore.haveAccs;

  const redirectTo = (id: string) => {
    navigate(`/accomodations/${id}`);
  };

  const data = {
    labels: [
      `${t("apartment")} (${Math.floor(
        (apartmentLength * 100) / totalLength
      )}%)`,
      `${t("house")} (${Math.floor((houseLength * 100) / totalLength)}%)`,
      `${t("field")} (${Math.floor((fieldLength * 100) / totalLength)}%)`,
      `${t("others")} (${Math.floor((othersLength * 100) / totalLength)}%)`
    ],
    datasets: [
      {
        label: "# of property",
        data: [apartmentLength, houseLength, fieldLength, othersLength],
        backgroundColor: ["#01CCD2", "#005376", "#FF922B", "#E2E9F5"],
        borderColor: "transparent",
        borderWidth: 1
      }
    ]
  };

  const accsWithoutSigned = accomodationStore.accomodations
    ? accomodationStore.accomodations
        .filter((f) => !f.mandat || f.mandat.signatureAgency === null)
        .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt))
    : [];

  const accsReadyToSell = accomodationStore.accomodations
    ? accomodationStore.accomodations
        .filter((f) => f.mandat && f.mandat.isSigned)
        .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt))
    : [];

  const amdWithoutSigned = accomodationStore.accomodations
    ? accomodationStore.accomodations
        .filter(
          (accAg) =>
            accAg.amendments.length > 0 &&
            accAg.amendments.find((amd) => amd.signatureAgency === null)
        )
        .sort((a, b) => moment(b.updatedAt).diff(a.updatedAt))
    : [];

  return (
    <div className="wrapper relative fullPage w-full">
      <Header agency={agencyStore.agency!} />

      <div className="z-10 p-6">
        <div className="grid grid-cols-6 2xl:grid-cols-4 grid-rows-2 grid-flow-row gap-6">
          <div className="col-span-2 2xl:col-span-1 shadow-lg border-2 border-grey-500 rounded-lg bg-white h-96 2xl:h-[30rem] ">
            <div className="flex flex-row items-center p-4">
              <div className="bg-gray-200 rounded-full w-12 h-12 flex items-center justify-center mx-5">
                <i className="fa-solid fa-house text-nodimodarkblue text-xl"></i>
              </div>
              <div className="w-10/12">
                <p className="text-xl font-bold text-nodimodarkblue">
                  {t("propertiesForSale")}
                </p>
                <p className="text-lg text-gray-400">
                  {t("repartitionByType")}
                </p>
              </div>
            </div>
            <div className="border-t-2 border-grey-500 ">
              {haveAccs ? (
                <div className="p-4 h-60 2xl:h-80">
                  <Doughnut
                    data={data}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      aspectRatio: 1
                    }}
                  />
                </div>
              ) : (
                <div className="flex flex-col h-full justify-center items-center">
                  <i className="fa-regular fa-file text-8xl text-gray-400"></i>
                  <p className="text-lg text-gray-400 mt-4">
                    {t("notYetObtainedMandate")}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-4 2xl:col-span-3 shadow-lg border-2 border-grey-500 rounded-lg h-96 2xl:h-[30rem] bg-white">
            <div className="flex flex-col p-4 pl-6">
              <p className="text-xl font-bold text-nodimodarkblue">
                {t("findOutGetMoreMandates")}
              </p>
              <p className="text-lg text-gray-400">{t("improveVisibility")}</p>
            </div>
            <div className="flex flex-row border-t-2 border-grey-500 items-center w-full justify-center p-6">
              <div className="bg-nodimodarkblue rounded-2xl h-60 2xl:h-80 w-3/6 mx-4 flex flex-row items-center justify-center pr-4">
                {percent ? (
                  <>
                    <div className="relative flex items-center justify-center">
                      <svg width="200" height="200">
                        <circle
                          className="text-nodimolightblue"
                          strokeWidth="10"
                          stroke="currentColor"
                          fill="transparent"
                          r="80"
                          cx="100"
                          cy="100"
                        />
                        <circle
                          className="text-nodilightgray origin-center -rotate-90"
                          strokeWidth="10"
                          strokeDasharray={circumference}
                          strokeDashoffset={
                            circumference -
                            (Number(percent) / 100) * circumference
                          }
                          strokeLinecap="round"
                          stroke="currentColor"
                          fill="transparent"
                          r="80"
                          cx="100"
                          cy="100"
                        />
                      </svg>
                      <p className="text-nodilightgray text-3xl font-bold absolute">
                        {percent} %
                      </p>
                    </div>

                    <div className="ml-4">
                      <p className="text-white text-base 2xl:text-lg">
                        {t("salesMandate")}
                      </p>
                      <p className="text-white text-base 2xl:text-lg font-bold">
                        {totalLength &&
                        totalAccomodations &&
                        totalAccomodations.accomodationsTotal
                          ? `${totalLength} /
                              ${totalAccomodations.accomodationsTotal}
                            `
                          : t("Loading...")}
                      </p>
                    </div>
                  </>
                ) : (
                  <div>
                    <p className="text-white text-base 2xl:text-lg font-bold">
                      {t("Loading...")}
                    </p>
                  </div>
                )}
              </div>
              <div className="bg-nodibluelessopacity rounded-2xl h-60 2xl:h-80 w-3/6 mx-4 flex flex-col items-center justify-center p-4">
                <p className="text-nodimodarkblue text-lg 2xl:text-xl font-bold mt-8 text-center">
                  {t("boostIncome")}
                </p>
                <p className="text-nodimodarkblue text-base 2xl:text-lg my-4 2xl:my-10 text-center">
                  {t("gainVisibility")}
                </p>
                <div className="bg-transparent border-4 border-nodimodarkblue py-2 px-8 rounded-lg mb-4">
                  <p className="text-nodimodarkblue text-base 2xl:text-lg font-bold">
                    {t("discover")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-2 2xl:col-span-1 flex flex-col shadow-lg border-2 border-grey-500 rounded-lg h-96 2xl:h-[30rem] bg-white">
            <div className="flex flex-row items-center w-full p-4">
              <div className="bg-gray-200 rounded-full  w-12 h-12 flex items-center justify-center mx-4">
                <i className="fa-solid fa-file text-nodimodarkblue text-xl"></i>
              </div>
              <div className="w-10/12">
                <p className="text-xl font-bold text-nodimodarkblue">
                  {t("documents")} ({accsWithoutSigned.length})
                </p>
                <p className="text-lg text-gray-400">
                  {t("waitingYourActions")}
                </p>
              </div>
            </div>
            <div className="border-t-2 border-grey-500 h-full overflow-y-scroll">
              {haveAccs ? (
                <div className="flex flex-col h-full items-center w-full">
                  {accsWithoutSigned.map((accR) => (
                    <div
                      key={accR.id}
                      className="border-b-2 border-grey-500 w-full p-4 hover:bg-gray-100 cursor-pointer select-none flex justify-between items-center"
                      onClick={() => {
                        redirectTo(accR.accomodation.id);
                      }}
                    >
                      <div>
                        <p className=" text-base 2xl:text-lg text-gray-400 mb-2">
                          <span className="text-nodimodarkblue font-bold ">
                            {!accR.mandat
                              ? t("createMandate")
                              : t("waitingForAgencySign")}
                          </span>{" "}
                          - {moment(accR.updatedAt).fromNow()}
                        </p>
                        <p className="text-gray-400 text-sm">
                          {t(accR.accomodation.type.slug)} {t("in")}{" "}
                          {getCityFromAddress(accR.accomodation)} -{" "}
                          {accR.accomodation.firstLastName} -{" "}
                          {formatPrice(accR.accomodation.price)}
                        </p>
                      </div>
                      <i className="fa-solid fa-chevron-right text-nodimodarkblue justify-self-end "></i>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col my-16 w-full justify-center items-center">
                  <i className="fa-regular fa-file text-8xl text-gray-400"></i>
                  <p className="text-lg text-gray-400 mt-4">
                    {t("notYetObtainedMandate")}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-2 2xl:col-span-1 flex flex-col shadow-lg border-2 border-grey-500 rounded-lg h-96 2xl:h-[30rem] bg-white">
            <div className="flex flex-row items-center w-full p-4">
              <div className="bg-gray-200 rounded-full  w-12 h-12 flex items-center justify-center mx-4">
                <i className="fa-solid fa-file text-nodimodarkblue text-xl"></i>
              </div>
              <div className="w-10/12">
                <p className="text-xl font-bold text-nodimodarkblue">
                  {t("documents")}
                </p>
                <p className="text-lg text-gray-400">
                  {t("recentlyCompleted")}
                </p>
              </div>
            </div>
            <div className="border-t-2 border-grey-500 h-full overflow-y-scroll">
              {haveAccs ? (
                <div className="flex flex-col h-full items-center w-full">
                  {accsReadyToSell.map((accR) => (
                    <div
                      key={accR.id}
                      className="border-b-2 border-grey-500 w-full p-4 hover:bg-gray-100 cursor-pointer select-none flex justify-between items-center"
                      onClick={() => {
                        redirectTo(accR.accomodation.id);
                      }}
                    >
                      <div>
                        <p className=" text-base 2xl:text-lg text-gray-400 mb-2">
                          <span className="text-nodimodarkblue font-bold ">
                            {t("ReadyToSell")}
                          </span>{" "}
                          - {moment(accR.updatedAt).fromNow()}
                        </p>
                        <p className="text-gray-400 text-sm">
                          {t(accR.accomodation.type.slug)} {t("in")}{" "}
                          {getCityFromAddress(accR.accomodation)} -{" "}
                          {accR.accomodation.firstLastName} -{" "}
                          {formatPrice(accR.accomodation.price)}
                        </p>
                      </div>
                      <i className="fa-solid fa-chevron-right text-nodimodarkblue justify-self-end "></i>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col my-16 w-full justify-center items-center">
                  <i className="fa-regular fa-file text-8xl text-gray-400"></i>
                  <p className="text-lg text-gray-400 mt-4">
                    {t("notYetObtainedMandate")}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-2 2xl:col-span-1 flex flex-col shadow-lg border-2 border-grey-500 rounded-lg h-96 2xl:h-[30rem] bg-white">
            <div className="flex flex-row items-center w-full p-4">
              <div className="bg-gray-200 rounded-full  w-12 h-12 flex items-center justify-center mx-4">
                <i className="fa-solid fa-file text-nodimodarkblue text-xl"></i>
              </div>
              <div className="w-10/12">
                <p className="text-xl font-bold text-nodimodarkblue">
                  {t("Avenant à signer")}
                </p>
                <p className="text-lg text-gray-400">
                  {t("waitingYourActions")}
                </p>
              </div>
            </div>
            <div className="border-t-2 border-grey-500 h-full overflow-y-scroll">
              {haveAccs ? (
                <div className="flex flex-col h-full items-center w-full">
                  {amdWithoutSigned.map((accR) => (
                    <div
                      key={accR.id}
                      className="border-b-2 border-grey-500 w-full p-4 hover:bg-gray-100 cursor-pointer select-none flex justify-between items-center"
                      onClick={() => {
                        redirectTo(accR.accomodation.id);
                      }}
                    >
                      <div>
                        <p className=" text-base 2xl:text-lg text-gray-400 mb-2">
                          <span className="text-nodimodarkblue font-bold ">
                            {t("awaitingAmendment")}
                          </span>{" "}
                          - {moment(accR.updatedAt).fromNow()}
                        </p>
                        <p className="text-gray-400 text-sm">
                          {t(accR.accomodation.type.slug)} {t("in")}{" "}
                          {getCityFromAddress(accR.accomodation)} -{" "}
                          {accR.accomodation.firstLastName} -{" "}
                          {formatPrice(accR.accomodation.price)}
                        </p>
                      </div>
                      <i className="fa-solid fa-chevron-right text-nodimodarkblue justify-self-end "></i>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="flex flex-col my-16 w-full justify-center items-center">
                  <i className="fa-regular fa-file text-8xl text-gray-400"></i>
                  <p className="text-lg text-gray-400 mt-4">
                    {t("notYetObtainedMandate")}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end absolute bottom-0 right-0 z-0">
        <div className="w-9/12">
          <img src={nodimoLogo} className="opacity-30" />
        </div>
      </div>
    </div>
  );
});
