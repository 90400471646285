import { FeatureCollection } from "geojson";

import { Mandat } from "../models/Mandat";

type WithNull<T> = {
  [P in keyof T]: T[P] | undefined;
};
export interface FeatureMandat extends WithNull<Mandat> {
  accId: string;
  coordinates: number[];
  refused: boolean;
}

export const makeFeatureCollection = (
  acc: FeatureMandat[]
): FeatureCollection => {
  return {
    type: "FeatureCollection",
    features: acc.map((acc) => {
      return {
        type: "Feature",
        properties: {
          ...acc
        },
        geometry: {
          type: "Point",
          coordinates: acc.coordinates
        }
      };
    })
  };
};
