import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { GET_LEGAL_TEXT } from "../lib/gql/LegalText";
import { LegalText, legalTextFactory } from "../lib/models/LegalText";

export const CGVScreen = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [CGV, setCGU] = useState("");
  const { data } = useQuery(GET_LEGAL_TEXT, {
    variables: {
      type: "CGV"
    },
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    if (!data) return;
    if (!data.legalTexts.data[0]) return;
    const legalText: LegalText = legalTextFactory(data.legalTexts.data[0]);

    setCGU(legalText.content);
  }, [data]);

  return (
    <div className="policy">
      <div className="header">
        <i
          onClick={() => navigate("/auth/register")}
          className="fa-solid fa-chevron-left"
        ></i>
        <h2>{t("CGV")}</h2>
        <span />
      </div>
      {CGV !== "" && (
        <div
          className="innerHTML"
          dangerouslySetInnerHTML={{ __html: CGV }}
        ></div>
      )}
    </div>
  );
});
