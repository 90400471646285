import { object, string, ref } from "yup";

export type ForgotFormValues = {
  email: string;
};

export const ForgotFormScheme = object().shape({
  email: string()
    .email("Ce champ doit correspondre à un e-mail !")
    .required("Champs requis !")
});

export type ResetFormValues = {
  password: string;
  passwordConfirmation: string;
};

export const ResetFormScheme = object().shape({
  password: string().required("Champs requis !"),
  passwordConfirmation: string().oneOf(
    [ref("password"), null],
    "Les mots de passe ne correspondent pas"
  )
});
