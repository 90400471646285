import { gql } from "@apollo/client";

export const CREATE_AGENCY = gql`
  mutation ($data: AgencyInput!) {
    createAgency(data: $data) {
      data {
        id
        attributes {
          name
          address
          postalCode
          city
          lonlat
          thumbnail {
            data {
              attributes {
                url
              }
            }
          }
          email
          phoneNumber
          juridicForm
          description
          siret
          legalRepresentative
          proCardNumber
          deliveredByDPT
          financialGuarantee
          enseigne
          honoraire
          website
          searchVisible
        }
      }
    }
  }
`;

export const UPDATE_AGENCY = gql`
  mutation ($id: ID!, $data: AgencyInput!) {
    updateAgency(id: $id, data: $data) {
      data {
        id
        attributes {
          name
          address
          postalCode
          city
          lonlat
          thumbnail {
            data {
              attributes {
                url
              }
            }
          }
          email
          phoneNumber
          juridicForm
          description
          siret
          legalRepresentative
          proCardNumber
          deliveredByDPT
          financialGuarantee
          enseigne
          honoraire
          website
          searchVisible
          users {
            data {
              id
              attributes {
                firstName
                lastName
                email
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_AGENCY_FROM_USER = gql`
  query GetAgencyUser($id: ID) {
    usersPermissionsUser(id: $id) {
      data {
        attributes {
          agency {
            data {
              id
              attributes {
                name
                address
                postalCode
                city
                lonlat
                thumbnail {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                email
                phoneNumber
                juridicForm
                description
                siret
                legalRepresentative
                proCardNumber
                deliveredByDPT
                financialGuarantee
                enseigne
                honoraire
                website
                searchVisible
                users {
                  data {
                    id
                    attributes {
                      firstName
                      lastName
                      email
                      role {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ACCOMODATION_FROM_AGENCY = gql`
  query GetAccomodationFromAgency($id: ID!) {
    agency(id: $id) {
      data {
        attributes {
          agencyAccomodations(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                accomodation {
                  data {
                    id
                    attributes {
                      type {
                        data {
                          id
                          attributes {
                            label
                            weigth
                            slug
                            type_rules(pagination: { limit: -1 }) {
                              data {
                                attributes {
                                  label
                                }
                              }
                            }
                          }
                        }
                      }
                      address
                      floor
                      buildUpArea
                      totalLandArea
                      notSureTotalLandArea
                      dependenciesArea
                      dependenciesDescription
                      buildingDate
                      energyClass
                      ges
                      notRealised
                      condition
                      conditionDetails
                      majorWork
                      tenYearGuarantee
                      propertyCasualityInsurance
                      propertyTax
                      facing
                      commonWalls
                      numberCommonWalls
                      garage
                      numberGarage
                      coOwned
                      annualFees
                      expansion
                      exceptionalView
                      landscapedGarden
                      pool
                      tennis
                      quiet
                      noOpposite
                      trainStationWalk
                      forestAccessOnFoot
                      shopsWalkingDistance
                      singleStorey
                      prm
                      typeHeat
                      secondTypeHeat
                      othersTypeHeat
                      boilerLess10Y
                      doubleGlazing
                      insulation
                      sewageConnection
                      balcony
                      individualSanitation
                      convertibleAttic
                      spa
                      waterRoom
                      gym
                      petanque
                      summerKitchen
                      barbecue
                      wineCellar
                      gardenShed
                      workshop
                      greenhouse
                      poolHouse
                      bikeStorage
                      carport
                      horseStall
                      river
                      well
                      horseStable
                      bonfire
                      terrace
                      basement
                      parkingPlaces
                      description
                      price
                      accomodationRooms {
                        data {
                          id
                          attributes {
                            quantity
                            label
                            room {
                              data {
                                attributes {
                                  slug
                                }
                              }
                            }
                          }
                        }
                      }
                      photos(pagination: { limit: -1 }) {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      videos(pagination: { limit: -1 }) {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      plans(pagination: { limit: -1 }) {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      visits {
                        data {
                          id
                          attributes {
                            day
                            timeslot
                          }
                        }
                      }
                      beWarned
                      propertyProof {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                      mandats {
                        data {
                          id
                          attributes {
                            mandatpdf(pagination: { limit: -1 }) {
                              data {
                                id
                                attributes {
                                  url
                                }
                              }
                            }
                            mandateNumber
                            customFees
                            signatureDate
                            isSigned
                            signature {
                              data {
                                attributes {
                                  url
                                }
                              }
                            }
                            agency {
                              data {
                                id
                                attributes {
                                  name
                                  address
                                  postalCode
                                  city
                                  thumbnail {
                                    data {
                                      attributes {
                                        url
                                        formats
                                      }
                                    }
                                  }
                                  email
                                  phoneNumber
                                  lonlat
                                }
                              }
                            }
                          }
                        }
                      }
                      firstLastName
                      email
                      forest
                      vegetableGarden
                      wood
                      unobstructedView
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const UPDATE_ACCOMODATION_AGENCY = gql`
  mutation ($id: ID!, $data: AccomodationAgencyInput!) {
    updateAccomodationAgency(id: $id, data: $data) {
      data {
        id
        attributes {
          accomodation {
            data {
              id
              attributes {
                accomodationRooms(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      label
                      quantity
                      room {
                        data {
                          id
                          attributes {
                            icon {
                              data {
                                id
                                attributes {
                                  url
                                }
                              }
                            }
                            isLivable
                            label
                            slug
                            weigth
                          }
                        }
                      }
                    }
                  }
                }
                address
                annualFees
                balcony
                individualSanitation
                convertibleAttic
                spa
                waterRoom
                gym
                petanque
                summerKitchen
                barbecue
                wineCellar
                gardenShed
                workshop
                greenhouse
                poolHouse
                bikeStorage
                carport
                horseStall
                river
                well
                horseStable
                bonfire
                basement
                beWarned
                boilerLess10Y
                buildUpArea
                buildingDate
                coOwned
                commonWalls
                condition
                conditionDetails
                dependenciesArea
                doubleGlazing
                insulation
                description
                dependenciesDescription
                email
                energyClass
                exceptionalView
                expansion
                facing
                firstLastName
                floor
                forest
                forestAccessOnFoot
                garage
                ges
                hasNotaire
                isRented
                majorWork
                landscapedGarden
                noOpposite
                notRealised
                notSureTotalLandArea
                notaireAdress
                notaireName
                numberCommonWalls
                numberGarage
                othersTypeHeat
                owner {
                  data {
                    attributes {
                      username
                      email
                      firstName
                      lastName
                      phoneNumber
                      role {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                    id
                  }
                }
                parkingPlaces
                photos(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                plans(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                pool
                poolHeating
                price
                poolSize
                prm
                propertyCasualityInsurance
                propertyProof(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                propertyTax
                quiet
                rentersContactInformation
                secondTypeHeat
                sewageConnection
                belongsToSci {
                  data {
                    id
                    attributes {
                      name
                      form
                      siret
                      address
                      postalCode
                      city
                      legalRepresentative
                      email
                    }
                  }
                }
                shopsWalkingDistance
                singleStorey
                tenYearGuarantee
                tennis
                terrace
                totalLandArea
                trainStationWalk
                type {
                  data {
                    id
                    attributes {
                      slug
                      label
                      weigth
                      type_rules(pagination: { limit: -1 }) {
                        data {
                          attributes {
                            label
                          }
                        }
                      }
                      thumbnail {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                typeHeat
                unobstructedView
                updatedAt
                vegetableGarden
                videos(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                visits(pagination: { limit: -1 }) {
                  data {
                    attributes {
                      day
                      timeslot
                    }
                    id
                  }
                }
                wood
              }
            }
          }
          mandat {
            data {
              id
              attributes {
                completed
                isSigned
                mandateNumber
                customFees
                mandatpdf(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                ownerSigned
                ownersValidated
                signature {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                signatureAgency {
                  data {
                    attributes {
                      url
                    }
                    id
                  }
                }
                signatureDate
              }
            }
          }
          refused
        }
      }
    }
  }
`;

export const REMOVE_USER_FROM_AGENCY = gql`
  mutation ($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
      }
    }
  }
`;
