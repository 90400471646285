import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { socket } from "../lib/config/web-socket";
import { FETCH_ONE_COV } from "../lib/gql/Conversations";
import { conversationFactory } from "../lib/models/Conversation";
import { useLoaderStore, useUserStore } from "../lib/store";
import { useConversationStore } from "../lib/store/conversation/useConversationStore";
import { fetchFromImgProxy } from "../lib/utils/utils";
// import FsLightbox from "fslightbox-react";
import { UPLOAD_MULTIPLE_MEDIA } from "../lib/gql/Mandat";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageNotAvailable = require("../assets/images/image-not-available.jpeg");

export const ChatScreen = observer(() => {
  const conversationStore = useConversationStore();
  const userStore = useUserStore();
  const loader = useLoaderStore();
  const { t } = useTranslation();

  const { userId } = useParams();

  const bottomRef = useRef<HTMLDivElement>(null);

  const [message, setMessage] = useState<string>("");
  const [content_file] = useState(null);

  // const [togglerAttachments, setTogglerAttachments] = useState<boolean>(false);
  // const [imgIndex, setImgIndex] = useState<number>(0);
  // const [picToDisplay, setPicToDisplay] = useState<string[]>([]);

  const { data, refetch } = useQuery(FETCH_ONE_COV, {
    variables: { id: userId },
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      scrollToBottom();
      loader.stopLoading();
    },
    onError: () => loader.stopLoading()
  });

  const [uploadFiles] = useMutation(UPLOAD_MULTIPLE_MEDIA, {
    onError: (e) => {
      console.log("e", e);
    }
  });

  const scrollToBottom = () => {
    setTimeout(
      () => bottomRef.current?.scrollIntoView({ behavior: "smooth" }),
      500
    );
  };

  useEffect(() => {
    if (!data) return;

    conversationStore.setSelectedConversation(
      conversationFactory(data.conversation.data)
    );

    // if (conversationStore.selectedConversation) {
    //   const picArray: string[] = [];

    //   conversationStore.selectedConversation.messages.forEach((mes) => {
    //     if (mes.type === "file") {
    //       picArray.push(
    //         ...(mes.contentFile
    //           .flatMap((pic) => {
    //             if (pic.slice(-3).toLowerCase() !== "pdf") {
    //               return pic;
    //             }
    //             return null;
    //           })
    //           .filter((n) => n) as string[])
    //       );
    //     }
    //   });

    //   console.log("picArray :>> ", picArray);

    //   setPicToDisplay(picArray);
    // }
  }, [data]);

  // useEffect(() => {
  //   if (!loader.loading) {
  //     if (loading) {
  //       loader.startLoading();
  //     } else {
  //       loader.stopLoading();
  //     }
  //   }
  // }, [loading]);

  // const handleDisplayAttachments = (index: number) => {
  //   setImgIndex(index);
  //   setTogglerAttachments(!togglerAttachments);
  // };

  const sendMessage = () => {
    if (message && message.length > 0) {
      socket.emit(
        "sendMessage",
        {
          content: message,
          date: new Date().toISOString(),
          type: content_file ? "file" : "text",
          content_file: content_file,
          conversation: conversationStore.selectedConversation?.id,
          owner: userStore.currentUser?.id
        },
        (error: any) => {
          if (error) {
            alert(error);
          }
        }
      );
      setMessage("");
      refetch();
    } else {
      alert("Message can't be empty");
    }
  };

  const handleSelectAttachments = async (e: FileList) => {
    if (!e) return;
    await uploadFiles({ variables: { files: e } })
      .then((val) => {
        const ids = val.data.multipleUpload.map((up: any) => up.data.id);

        socket.emit(
          "sendMessage",
          {
            content: "",
            date: new Date().toISOString(),
            type: "file",
            content_file: ids,
            conversation: conversationStore.selectedConversation?.id,
            owner: userStore.currentUser?.id
          },
          (error: any) => {
            if (error) {
              console.log(
                "error send conversationStore.currentMessage :>> ",
                error
              );
            }
          }
        );
        refetch();
      })
      .catch((e) => {
        console.log("e", e);
        loader.stopLoading();
      });
  };

  return (
    <div className="messagesBloc">
      {conversationStore.selectedConversation ? (
        <>
          <div className="messagesHeader h-16">
            <div>
              <div className="messagesUserWrapper">
                <p className="messagesUser ml-4 ">
                  {conversationStore.selectedConversation.user.firstName +
                    " " +
                    conversationStore.selectedConversation.user.lastName}{" "}
                </p>
              </div>
            </div>
            {/*<div className="hidden messagesTools mr-4">
              <i className="mr-2 fa-solid fa-video"></i>
              <i className="mr-2 fa-solid fa-phone"></i>
              <i className="mr-2 fa-solid fa-magnifying-glass"></i>
              <i className="fa-solid fa-ellipsis"></i>
                  </div>*/}
          </div>
          <div className="messagesBody">
            {conversationStore.selectedConversation.messages.length > 0 ? (
              conversationStore.selectedConversation.messages.map((mes) => (
                <div
                  key={mes.id}
                  className={`flex ${
                    mes.owner.id === userStore.currentUser?.id
                      ? "justify-end"
                      : "justify-start"
                  }`}
                >
                  {mes.type === "text" ? (
                    <div
                      className={`messages messages--${
                        mes.owner.id === userStore.currentUser?.id
                          ? "send"
                          : "recieve"
                      }`}
                    >
                      <p>{mes.content}</p>
                    </div>
                  ) : (
                    <div>
                      <div
                        className={`flex flex-grow flex-wrap flex-row max-w-2xl ${
                          mes.owner.id === userStore.currentUser?.id
                            ? "justify-end"
                            : "justify-start"
                        }`}
                      >
                        {mes.contentFile.map((file, i) => (
                          <div
                            key={i}
                            className={`mb-4 ${
                              mes.contentFile.length > 2
                                ? "basis-1/3"
                                : mes.contentFile.length > 1
                                ? "basis-6/12"
                                : "basis-6/12"
                            }`}
                          >
                            {file.slice(-3).toLowerCase() === "pdf" ? (
                              <div
                                className={`flex items-center justify-center rounded-md bg-nodilightgray cursor-pointer select-none ${
                                  mes.contentFile.length > 2
                                    ? "w-52 h-52 mx-2 "
                                    : mes.contentFile.length > 1
                                    ? "w-60 h-60 mx-2 "
                                    : "w-[22rem] h-[40rem] mx-2"
                                }`}
                                onClick={() =>
                                  window.open(
                                    file,
                                    "_blank",
                                    "noopener, noreferrer"
                                  )
                                }
                              >
                                <i className="fa-regular fa-file-pdf text-5xl"></i>
                              </div>
                            ) : (
                              <img
                                src={
                                  file
                                    ? fetchFromImgProxy(file, {
                                        format: "webp",
                                        width: 800
                                      })
                                    : imageNotAvailable
                                }
                                className={`object-cover rounded-md ${
                                  mes.contentFile.length > 2
                                    ? "w-52 h-52 mx-2"
                                    : mes.contentFile.length > 1
                                    ? "w-60 h-60 mx-2"
                                    : "w-full h-full mx-2"
                                }`}
                                alt={t("loadingError")}
                                onClick={() =>
                                  window.open(
                                    file,
                                    "_blank",
                                    "noopener, noreferrer"
                                  )
                                }
                                // onClick={() => handleDisplayAttachments(i)}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))
            ) : (
              <div>
                <p>{t("noMessage")}</p>
              </div>
            )}
            <div ref={bottomRef} />
          </div>
          <div className="messagesBar">
            <div>
              <input
                id="attachmentId"
                name="attachment"
                type="file"
                accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
                multiple
                className="hidden"
                onChange={(event) => {
                  handleSelectAttachments(event.target.files!);
                }}
              />
              <label
                htmlFor="attachmentId"
                className="cursor-pointer select-none"
              >
                <i className="fa-solid fa-paperclip"></i>
              </label>
            </div>

            <div className="messagesBarSend">
              <input
                type="text"
                placeholder={t("Message...")}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <i
                onClick={sendMessage}
                className="fa-regular fa-paper-plane"
              ></i>
            </div>
          </div>
          {/* <FsLightbox
            sourceIndex={imgIndex}
            toggler={togglerAttachments}
            sources={picToDisplay.map((m) =>
              fetchFromImgProxy(m, {
                format: "webp",
                width: 1000
              })
            )}
          /> */}
        </>
      ) : (
        <div className="flex justify-center items-center ">
          <h2>{t("noConversationSelect")}</h2>
        </div>
      )}
    </div>
  );
});
