import { AccomodationDTO } from "./Accomodation";
import { IRoom, Room, roomFactory } from "./Room";

export interface AccomodationRoomDTO {
  accomodation?: number;
  room?: number;
  quantity: number;
  label?: string;
}

export interface IAccomodationRoom {
  id?: string;
  attributes: {
    accomodation?: AccomodationDTO;
    room: { data?: IRoom };
    quantity: number;
    label?: string;
  };
}

export class AccomodationRoom {
  public id?: string;

  public accomodation?: AccomodationDTO;

  public room?: Room | null;

  public quantity: number;

  public label?: string;

  constructor(accRoomFromFetch: IAccomodationRoom) {
    this.id = accRoomFromFetch.id;
    this.accomodation = accRoomFromFetch.attributes.accomodation;
    this.room = accRoomFromFetch.attributes.room.data
      ? roomFactory(accRoomFromFetch.attributes.room.data)
      : null;
    this.quantity = accRoomFromFetch.attributes.quantity;
    this.label = accRoomFromFetch.attributes.label;
  }
}

export const accomodationRoomFactory = (
  data: IAccomodationRoom
): AccomodationRoom => {
  return new AccomodationRoom(data);
};
