import { gql } from "@apollo/client";

export const FETCH_ALL_CONVS = gql`
  query getConvers {
    conversations(pagination: { limit: -1 }) {
      data {
        id
        attributes {
          user {
            data {
              id
              attributes {
                username
                email
                firstName
                lastName
                phoneNumber
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          messages(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                content
                date
                type
                isRead
                content_file {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                owner {
                  data {
                    id
                    attributes {
                      role {
                        data {
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_ONE_COV = gql`
  query getConvers($id: ID!) {
    conversation(id: $id) {
      data {
        id
        attributes {
          user {
            data {
              id
              attributes {
                username
                email
                firstName
                lastName
                phoneNumber
                role {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
              }
            }
          }
          messages(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                content
                date
                type
                isRead
                content_file {
                  data {
                    attributes {
                      url
                    }
                  }
                }
                owner {
                  data {
                    id
                    attributes {
                      role {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const MARK_AS_READ = gql`
  query markAsRead($id: String!) {
    markAsRead(id: $id)
  }
`;
