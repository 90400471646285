import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { reaction } from "mobx";
import mapboxgl from "mapbox-gl";
import { lowerCase, zip } from "lodash";

import { useAgencyStore } from "../lib/store";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";
import {
  displayAmendment,
  distanceCalculator,
  fetchFromImgProxy,
  formatPrice,
  getCityFromAddress
} from "../lib/utils/utils";
import { FeatureMandat, makeFeatureCollection } from "../lib/cluster/feature";
import { Header } from "../components/Header/Header";
import { AccomodationAgency } from "../lib/models/AccomodationAgency";
import { Pastille } from "../components/Pastille/Pastille";
import { Amendment } from "../lib/models/Amendment";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageNotAvailable = require("../assets/images/image-not-available.jpeg");

mapboxgl.accessToken = process.env.MAP_BOX_API_KEY!;

export const AccomodationsListScreen = observer(() => {
  const agencyStore = useAgencyStore();
  const accomodationStore = useAccomodationStore();

  const navigate = useNavigate();

  const mapContainer = useRef(null);

  const [search, setSearch] = useState<string>("");
  const [filteredAccs, setFilteredAccs] = useState<AccomodationAgency[]>([]);

  const [filter, setFilter] = useState<string>("");
  const [onlySigned, setOnlySigned] = useState<boolean>(false);
  const [onlyRefused, setOnlyRefused] = useState<boolean>(false);
  const [onlyAmendment, setOnlyAmendment] = useState<boolean>(false);

  const makeFilter = (accs: AccomodationAgency[]) => {
    if (!accs || accs.length === 0) {
      setFilteredAccs([]);
      return;
    }

    let newAccs: AccomodationAgency[] = accs.filter((f) => {
      if (onlyRefused) {
        return (
          (search === "" && f.refused) ||
          (f.accomodation.address.address
            .toLowerCase()
            .includes(search.toLowerCase()) &&
            f.refused) ||
          (t(f.accomodation.type.slug)
            .toLowerCase()
            .includes(search.toLowerCase()) &&
            f.refused) ||
          (f.accomodation.price.toString().includes(search.toLowerCase()) &&
            f.refused)
        );
      } else {
        return (
          (search === "" && !f.refused) ||
          (f.accomodation.address.address
            .toLowerCase()
            .includes(search.toLowerCase()) &&
            !f.refused) ||
          (t(f.accomodation.type.slug)
            .toLowerCase()
            .includes(search.toLowerCase()) &&
            !f.refused) ||
          (f.accomodation.price.toString().includes(search.toLowerCase()) &&
            !f.refused)
        );
      }
    });

    if (onlyAmendment) {
      newAccs = newAccs.filter(
        (accAg) =>
          accAg.amendments.length > 0 &&
          accAg.amendments.find((amd) => amd.signatureAgency === null)
      );
    }

    if (onlySigned) {
      newAccs = newAccs.filter((f) => f.mandat?.isSigned);
    }

    if (filter === "type+") {
      newAccs.sort((a, b) =>
        t(a.accomodation.type.slug).localeCompare(t(b.accomodation.type.slug))
      );
    }

    if (filter === "type-") {
      newAccs.sort((a, b) =>
        t(b.accomodation.type.slug).localeCompare(t(a.accomodation.type.slug))
      );
    }

    if (filter === "price+") {
      newAccs.sort((a, b) => a.accomodation.price - b.accomodation.price);
    }

    if (filter === "price-") {
      newAccs.sort((a, b) => b.accomodation.price - a.accomodation.price);
    }

    if (filter === "loc+") {
      newAccs.sort(
        (a, b) =>
          distanceCalculator(
            a.accomodation.address.coordinates,
            agencyStore.agency?.lonlat!
          ) -
          distanceCalculator(
            b.accomodation.address.coordinates,
            agencyStore.agency?.lonlat!
          )
      );
    }

    if (filter === "loc-") {
      newAccs.sort(
        (a, b) =>
          distanceCalculator(
            b.accomodation.address.coordinates,
            agencyStore.agency?.lonlat!
          ) -
          distanceCalculator(
            a.accomodation.address.coordinates,
            agencyStore.agency?.lonlat!
          )
      );
    }

    setFilteredAccs(newAccs);
  };

  useEffect(
    () =>
      reaction(
        () => accomodationStore.accomodations,
        () => {
          makeFilter(accomodationStore.accomodations!);
        }
      ),
    []
  );

  useEffect(() => {
    makeFilter(accomodationStore.accomodations!);
  }, [search]);

  const { t } = useTranslation();

  useEffect(() => {
    if (!filteredAccs || filteredAccs.length === 0) return;
    const map = new mapboxgl.Map({
      container: mapContainer.current!,
      style: "mapbox://styles/mapbox/light-v10",
      center: [
        agencyStore.agency?.lonlat[0] || 3.882936,
        agencyStore.agency?.lonlat[1] || 45.042768
      ],
      zoom: 9
    });

    map.on("load", () => {
      map.loadImage(
        "https://nidomo-dev.s3.fr-par.scw.cloud/file_white_07d7890243.png?updated_at=2022-10-20T12:53:44.525Z",
        (error, image) => {
          if (error) throw error;

          // Add the image to the map style.
          map.addImage("file-custom", image!);

          map.loadImage(
            "https://nidomo-dev.s3.fr-par.scw.cloud/clock_e9ab2cda87.png?updated_at=2022-10-20T12:53:42.568Z",
            (error, image) => {
              if (error) throw error;

              // Add the image to the map style.
              map.addImage("clock-custom", image!);

              if (filteredAccs.length > 0) {
                const newFtrs: FeatureMandat[] = filteredAccs.map((m) => {
                  return {
                    accId: m.accomodation.id,
                    coordinates: m.accomodation.address.coordinates,
                    refused: m.refused,
                    ...m.mandat!
                  };
                });

                map.addSource("point", {
                  type: "geojson",
                  data: makeFeatureCollection(newFtrs),
                  cluster: true,
                  clusterMaxZoom: 14,
                  clusterRadius: 50
                });

                // Add a layer to use the image to represent the data.
                map.addLayer({
                  id: "clusters",
                  type: "circle",
                  source: "point",
                  filter: ["has", "point_count"],
                  paint: {
                    "circle-color": [
                      "step",
                      ["get", "point_count"],
                      "#01CCD2",
                      100,
                      "#01CCD2",
                      750,
                      "#01CCD2"
                    ],
                    "circle-radius": [
                      "step",
                      ["get", "point_count"],
                      32,
                      100,
                      44,
                      750,
                      52
                    ]
                  }
                });

                map.addLayer({
                  id: "cluster-count",
                  type: "symbol",
                  source: "point",
                  filter: ["has", "point_count"],
                  layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["Open Sans Bold"],
                    "text-size": 16
                  }
                });

                map.addLayer({
                  id: "unclustered-point",
                  type: "circle",
                  source: "point",
                  filter: ["!", ["has", "point_count"]],
                  paint: {
                    "circle-color": [
                      "case",
                      ["==", ["get", "isSigned"], true],
                      "#01CCD2",
                      ["==", ["get", "refused"], true],
                      "#E83C6B",
                      "#A5ACB7"
                    ],
                    "circle-radius": 24
                  }
                });

                map.addLayer({
                  id: "clock",
                  type: "symbol",
                  source: "point", // reference the data source
                  filter: ["!", ["has", "point_count"]],
                  layout: {
                    "icon-image": [
                      "case",
                      ["==", ["get", "isSigned"], true],
                      "file-custom",
                      "clock-custom"
                    ],
                    "icon-size": 0.08
                  }
                });

                map.on("click", "unclustered-point", (e) => {
                  const feature = e.features![0];
                  if (!feature) return;

                  navigate(`/accomodations/${feature.properties!.accId}`);
                });

                map.on("mouseenter", "unclustered-point", () => {
                  map.getCanvas().style.cursor = "pointer";
                });

                // Change it back to a pointer when it leaves.
                map.on("mouseleave", "unclustered-point", () => {
                  map.getCanvas().style.cursor = "";
                });

                map.on("click", "clusters", (e) => {
                  const feature = e.features![0];
                  if (!feature) return;

                  const zoom = map.getZoom();

                  map.easeTo({
                    center: e.lngLat,
                    zoom: zoom + 2,
                    duration: 500
                  });
                });

                map.on("mouseenter", "clusters", () => {
                  map.getCanvas().style.cursor = "pointer";
                });

                // Change it back to a pointer when it leaves.
                map.on("mouseleave", "clusters", () => {
                  map.getCanvas().style.cursor = "";
                });
              }
            }
          );
        }
      );
    });

    // cleanup function to remove map on unmount
    return () => map.remove();
  }, [filteredAccs]);

  const mandatState = (state: boolean | undefined, amendments: Amendment[]) => {
    if (displayAmendment(amendments)) return t("sellerWantAmendment");
    if (state === undefined) return t("YouMustSendMandate");
    if (!state) return t("WaitingForSeller");

    return t("ReadyToSell");
  };

  useEffect(() => {
    makeFilter(accomodationStore.accomodations!);
  }, [filter, onlySigned, onlyRefused, onlyAmendment]);

  const displayPrice = (acc: AccomodationAgency) => {
    const lastAmendmentCompleted = acc.amendments
      .slice()
      .sort((a, b) => Number(b.id) - Number(a.id))
      .find((amd) => amd.completed);

    return lastAmendmentCompleted
      ? formatPrice(lastAmendmentCompleted.price)
      : formatPrice(acc.accomodation.price);
  };

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />

      <div className="agencyList">
        <h2 className="text-3xl mb-6 font-bold text-nodimodarkblue">
          {t("propertyList")}
        </h2>
        <div className="agencyBar">
          <div className="messagesInputWrapper">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              className="agencySearch"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <span
            className="px-4 py-2 border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 text-nodimodarkblue font-bold"
            onClick={() => {
              if (filter.includes("loc+")) setFilter("loc-");
              else setFilter("loc+");
            }}
          >
            {t("location")}{" "}
            {filter.includes("loc+") ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : filter.includes("loc-") ? (
              <i className="fa-solid fa-chevron-down"></i>
            ) : (
              ""
            )}
          </span>
          <span
            className="px-4 py-2 border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 text-nodimodarkblue font-bold"
            onClick={() => {
              if (filter.includes("type+")) setFilter("type-");
              else setFilter("type+");
            }}
          >
            {t("propertyType")}{" "}
            {filter.includes("type+") ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : filter.includes("type-") ? (
              <i className="fa-solid fa-chevron-down"></i>
            ) : (
              ""
            )}
          </span>
          <span
            className="px-4 py-2 border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 text-nodimodarkblue font-bold"
            onClick={() => {
              if (filter.includes("price+")) setFilter("price-");
              else setFilter("price+");
            }}
          >
            {t("price")}{" "}
            {filter.includes("price+") ? (
              <i className="fa-solid fa-chevron-up"></i>
            ) : filter.includes("price-") ? (
              <i className="fa-solid fa-chevron-down"></i>
            ) : (
              ""
            )}
          </span>
          <span
            className="px-4 py-2 border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 text-nodimodarkblue font-bold"
            onClick={() => setFilter("caracteristiques")}
          >
            {t("features")}
          </span>
          <span
            className={`px-4 py-2 ${
              onlySigned
                ? "text-white bg-nodimodarkblue"
                : "text-nodimodarkblue"
            } border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 font-bold`}
            onClick={() => {
              setOnlySigned(!onlySigned);
              setOnlyRefused(false);
              setOnlyAmendment(false);
            }}
          >
            {t("onlySignedMandat")}
          </span>
          <span
            className={`px-4 py-2 ${
              onlyRefused
                ? "text-white bg-nodimodarkblue"
                : "text-nodimodarkblue"
            } border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 font-bold`}
            onClick={() => {
              setOnlyRefused(!onlyRefused);
              setOnlySigned(false);
              setOnlyAmendment(false);
            }}
          >
            {t("onlyRefusedMandates")}
          </span>
          <span
            className={`px-4 py-2 ${
              onlyAmendment
                ? "text-white bg-nodimodarkblue"
                : "text-nodimodarkblue"
            } border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 font-bold`}
            onClick={() => {
              setOnlyAmendment(!onlyAmendment);
              setOnlySigned(false);
              setOnlyRefused(false);
            }}
          >
            {t("seeAmendmentToSign")}
          </span>
          <span
            className="px-4 py-2 border-solid border-nodimodarkblue rounded-md cursor-pointer select-none border-2 text-nodimodarkblue font-bold"
            onClick={() => setFilter("plus")}
          >
            {t("moreFilters")}
          </span>
        </div>
      </div>
      {filteredAccs && filteredAccs.length > 0 && (
        <div className="flex justifsy-center listWrapper">
          <div className="h-full overflow-y-scroll w-full lg:w-0 lg:min-w-max px-6">
            {filteredAccs.map((acc: AccomodationAgency) => (
              <div
                key={acc.id}
                className="mt-4 pb-4 flex border-solid border-b border-gray-300 relative"
              >
                <Pastille
                  type={
                    acc.refused
                      ? "refused"
                      : acc.mandat?.isSigned
                      ? "available"
                      : "waiting"
                  }
                />
                <img
                  src={
                    acc.accomodation.photos[0]
                      ? fetchFromImgProxy(acc.accomodation.photos[0].url, {
                          format: "webp",
                          width: 400
                        })
                      : imageNotAvailable
                  }
                  className="accThumb w-1/2 lg:w-80"
                />
                <div className="ml-6 pr-12 flex flex-col justify-between">
                  <div>
                    <p className="boldText">
                      {t(acc.accomodation.type.slug)} {t("in")}{" "}
                      {getCityFromAddress(acc.accomodation)}
                    </p>
                    <p className="boldText">
                      {t("price")} : {displayPrice(acc)}
                    </p>
                    <p>{`- ${t(acc.accomodation.type.slug)} ${
                      acc.accomodation.buildUpArea
                    } m²`}</p>
                    <p>
                      -{" "}
                      {
                        acc.accomodation.accomodationRooms.map(
                          (a) => a.room?.slug === "room"
                        ).length
                      }{" "}
                      {lowerCase(t("room_plural"))}
                    </p>
                  </div>
                  <div>
                    <p className="boldItalicText mb-2">
                      {mandatState(acc.mandat?.isSigned, acc.amendments)}
                    </p>
                    <Link
                      to={`${acc.accomodation.id}`}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <p className="seeAccomodation">
                        {t("seeTheFile")}{" "}
                        <i className="fa-solid fa-chevron-right"></i>
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="hidden lg:block w-full h-full ">
            <div ref={mapContainer} style={{ width: "100%", height: "100%" }} />
          </div>
        </div>
      )}
    </div>
  );
});
