import { MediaAttributes } from "./Media";
import { TypeRuleFromFetch } from "./TypeRule";

export interface IAccomodationType {
  id: string;
  attributes: {
    label?: string;
    weigth?: number;
    slug: string;
    thumbnail?: { data: MediaAttributes };
    type_rules?: {
      data: TypeRuleFromFetch[];
    };
  };
}
export class AccomodationType {
  public id: string;

  public label: string;

  public slug: string;

  public weigth: number;

  public type_rules: string[];

  constructor(typeFromFetch: IAccomodationType) {
    this.id = typeFromFetch.id;
    this.label = typeFromFetch.attributes.label || "";
    this.slug = typeFromFetch.attributes.slug;
    this.weigth = typeFromFetch.attributes.weigth || 0;
    this.type_rules = typeFromFetch.attributes.type_rules
      ? typeFromFetch.attributes.type_rules.data.map(
          (rules) => rules.attributes.label
        )
      : [];
  }
}

export const accomodationTypeFactory = (
  data: IAccomodationType
): AccomodationType => {
  return new AccomodationType(data);
};
