import {
  Accomodation,
  accomodationFactory,
  IAccomodation
} from "./Accomodation";
import { Agency, agencyFactory, IAgency } from "./Agency";
import { Amendment, amendmentFactory, IAmendment } from "./Amendment";
import { IMandat, Mandat, mandatFactory } from "./Mandat";

export interface AccomodationAgencyDTO {
  accomodation: string;
  agency: string;
}

export interface IAccomodationAgency {
  id: string;
  attributes: {
    accomodation: { data: IAccomodation };
    mandat: { data: IMandat | null };
    agency?: { data: IAgency };
    amendments: { data: IAmendment[] };
    refused: boolean;
    updatedAt: Date;
  };
}

export class AccomodationAgency {
  public id: string;

  public accomodation: Accomodation;

  public mandat?: Mandat;

  public agency?: Agency;

  public amendments: Amendment[];

  public refused: boolean;

  public updatedAt: Date;

  constructor(accAgencyFromFetch: IAccomodationAgency) {
    this.id = accAgencyFromFetch.id;
    this.accomodation = accomodationFactory(
      accAgencyFromFetch.attributes.accomodation.data
    );
    this.mandat =
      accAgencyFromFetch.attributes.mandat &&
      accAgencyFromFetch.attributes.mandat.data
        ? mandatFactory(accAgencyFromFetch.attributes.mandat.data!)
        : undefined;
    this.amendments = accAgencyFromFetch.attributes.amendments.data
      ? accAgencyFromFetch.attributes.amendments.data.map((amendment) =>
          amendmentFactory(amendment)
        )
      : [];
    this.agency =
      accAgencyFromFetch.attributes.agency &&
      accAgencyFromFetch.attributes.agency.data &&
      agencyFactory(accAgencyFromFetch.attributes.agency.data);
    this.refused = accAgencyFromFetch.attributes.refused;
    this.updatedAt = accAgencyFromFetch.attributes.updatedAt;
  }
}

export const accomodationAgencyFactory = (
  data: IAccomodationAgency
): AccomodationAgency => {
  return new AccomodationAgency(data);
};
