import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { upperFirst } from "lodash";
import { observer } from "mobx-react-lite";

import { TimeSlotShape } from "../lib/models";
import { useTimeSlotStore } from "../lib/store";
import { Id, Slide, toast } from "react-toastify";
import { UndoToast } from "../components/Toast/UndoToast/UndoToast";
import { timeSlotWriter } from "../lib/utils/TimeSlot";
import { TimeSlot } from "../components/TimeSlot/TimeSlot";

export const TimeSlotScreen = observer(() => {
  const [shape, setShape] = useState<TimeSlotShape[]>([
    {
      day: "0",
      timeslot: [
        ["0800", "1200"],
        ["1300", "1700"],
        ["1915", "2030"],
        ["2100", "2135"]
      ],
      step: "0025"
    },
    {
      day: "1",
      timeslot: [
        ["0800", "1200"],
        ["1300", "1700"]
      ],
      step: "0100"
    },
    {
      day: "2",
      timeslot: [
        ["0800", "1200"],
        ["1300", "1700"]
      ],
      step: "0215"
    },
    {
      day: "3",
      timeslot: [
        ["0800", "1200"],
        ["1300", "1700"]
      ],
      step: "0100"
    },
    {
      day: "4",
      timeslot: [
        ["0800", "1200"],
        ["1300", "1700"]
      ],
      step: "0115"
    },
    {
      day: "5",
      timeslot: [["0800", "1200"]],
      step: "0100"
    }
  ]);

  const toastRef = useRef<Id | null>(null);

  const timeSlotStore = useTimeSlotStore();

  useEffect(() => {
    timeSlotStore.setShape(shape);
  }, [shape]);

  const handleChangeWeek = (mode: string) => {
    mode === "add"
      ? timeSlotStore.setWeek(timeSlotStore.week + 1)
      : timeSlotStore.setWeek(timeSlotStore.week - 1);
  };

  const handleSelectTimeSlot = (date: string, ts: string[]) => {
    if (
      timeSlotStore.current &&
      timeSlotStore.current.date === date &&
      timeSlotStore.current.timeSlot === ts
    ) {
      timeSlotStore.setCurrent(null);
    } else {
      timeSlotStore.setCurrent({ date, timeSlot: ts });
    }
  };

  const handleUndo = () => {
    timeSlotStore.setCurrent(null);
    timeSlotStore.makeTimeStep();
  };

  const notify = () =>
    (toastRef.current! = toast.info(
      <UndoToast
        title="Créneau choisis !"
        description={`Le ${upperFirst(
          moment(timeSlotStore.current?.date).format("dddd")
        )} ${moment(timeSlotStore.current?.date).format(
          "LL"
        )} de ${timeSlotWriter(timeSlotStore.current?.timeSlot!, " à ", true)}`}
        onUndo={handleUndo}
        update={update}
      />,
      {
        position: "top-center",
        autoClose: 5000,
        className: "undoToastWrapper",
        hideProgressBar: false,
        pauseOnHover: true,
        closeOnClick: false
      }
    ));

  const update = () =>
    toast.update(toastRef.current!, {
      render: "Créneau annulé !",
      type: toast.TYPE.INFO,
      transition: Slide
    });

  const handleValidate = () => {
    if (timeSlotStore.current) {
      timeSlotStore.makeTimeStep();
      notify();
    }
  };

  return (
    <div>
      <h2>TimeSlotScreen</h2>
      {timeSlotStore.timeSlot && timeSlotStore.timeSlot.length > 0 && (
        <TimeSlot
          timeSlot={timeSlotStore.timeSlot}
          current={timeSlotStore.current}
          handleChangeWeek={handleChangeWeek}
          handleSelectTimeSlot={handleSelectTimeSlot}
          handleValidate={handleValidate}
        />
      )}
    </div>
  );
});
