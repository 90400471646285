import { useTranslation } from "react-i18next";
import React, { FC } from "react";

import { UndoToastComponent } from "./UndoToast.type";

/**
 * UndoToast used to display undoable toast.
 */
export const UndoToast: FC<UndoToastComponent> = ({
  title,
  description,
  onUndo,
  update
}) => {
  const handleClick = () => {
    onUndo();
    update && update();
  };

  const { t } = useTranslation();

  return (
    <div className="undoToast">
      <p className="undoTitle">{title}</p>
      <p className="undoDescription">{description}</p>
      <p className="undoButton" onClick={handleClick}>
        {t("cancel")}
      </p>
    </div>
  );
};
