import { MediaAttributes } from "./Media";

export interface IMessage {
  id: string;
  attributes: {
    content: string;
    date: Date;
    type: string;
    isRead: boolean;
    content_file: {
      data: MediaAttributes[];
    };
    owner: {
      data: {
        id: string;
        attributes: {
          role: {
            data: {
              attributes: {
                name: string;
              };
            };
          };
        };
      };
    };
  };
}

export class Message {
  public id: string;

  public content: string;

  public date: Date;

  public type: string;

  public isRead: boolean;

  public contentFile: string[];

  public owner: { id: string; roleName: string };

  constructor(messageFromFetch: IMessage) {
    this.id = messageFromFetch.id;
    this.content = messageFromFetch.attributes.content;
    this.date = messageFromFetch.attributes.date;
    this.type = messageFromFetch.attributes.type;
    this.isRead = messageFromFetch.attributes.isRead;
    this.contentFile = messageFromFetch.attributes.content_file
      ? messageFromFetch.attributes.content_file.data.map(
          (itm) => itm.attributes.url
        )
      : [];
    this.owner = {
      id: messageFromFetch.attributes.owner.data.id,
      roleName:
        messageFromFetch.attributes.owner.data.attributes.role.data.attributes
          .name
    };
  }
}

export const messageFactory = (data: IMessage): Message => {
  return new Message(data);
};
