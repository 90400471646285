import { isNil } from "lodash";
import React, { FC } from "react";

import { rootStore, storeContext } from "./storeContext";

interface StoreProviderComponent {
  children: any;
}

const StoreProvider: FC<StoreProviderComponent> = ({ children }) => {
  if (isNil(rootStore)) return null;
  return (
    <storeContext.Provider value={rootStore}>{children}</storeContext.Provider>
  );
};

export { StoreProvider, storeContext };
