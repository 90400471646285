import { gql } from "@apollo/client";

export const SEND_RESERVATION_MESSAGE = gql`
  query ($id: String!, $content: String!, $date: String!) {
    sendReservationMessage(id: $id, content: $content, date: $date)
  }
`;

export const SEND_REFUSED_MESSAGE = gql`
  query ($id: String!, $content: String!, $date: String!) {
    sendRefusedMessage(id: $id, content: $content, date: $date)
  }
`;
