import { MediaAttributes } from "./Media";
import { User, userFactory } from "./User";

export interface AgencyDTO {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  siret: string;
  legalRepresentative: string;
  proCardNumber: string;
  deliveredByDPT: string;
  financialGuarantee: string;
  enseigne: string;
  website: string;
  users: string[];
  lonlat: string;
  thumbnail?: string;
  searchVisible: boolean;
  description: string;
}

export type AgencyToUpdate = Omit<Partial<AgencyDTO>, "users">;

export interface IHonoraire {
  from: number;
  to: number;
  percent: number;
}

export interface IAgency {
  id: string;
  attributes: {
    name: string;
    address: string;
    postalCode: string;
    city: string;
    email: string;
    phoneNumber: string;
    lonlat: number[];
    thumbnail: {
      data: MediaAttributes;
    };
    juridicForm: string;
    siret: string;
    legalRepresentative: string;
    proCardNumber: string;
    deliveredByDPT: string;
    financialGuarantee: string;
    enseigne: string;
    honoraire: string;
    website: string;
    searchVisible: boolean;
    description: string;
    users: {
      data: {
        id: string;
        attributes: {
          firstName: string;
          lastName: string;
          email: string;
          phoneNumber: string;
        };
      }[];
    };
  };
}

export class Agency {
  public id: string;

  public name: string;

  public address: string;

  public postalCode: string;

  public city: string;

  public email: string;

  public phoneNumber: string;

  public lonlat: number[];

  public thumbnail: string;

  public juridicForm: string;

  public siret: string;

  public legalRepresentative: string;

  public proCardNumber: string;

  public deliveredByDPT: string;

  public financialGuarantee: string;

  public enseigne: string;

  public honoraire: IHonoraire[];

  public website: string;

  public searchVisible: boolean;

  public description: string;

  public users: User[];

  constructor(agencyFromFetch: IAgency) {
    this.id = agencyFromFetch.id;
    this.name = agencyFromFetch.attributes.name;
    this.address = agencyFromFetch.attributes.address;
    this.postalCode = agencyFromFetch.attributes.postalCode;
    this.city = agencyFromFetch.attributes.city;
    this.email = agencyFromFetch.attributes.email;
    this.phoneNumber = agencyFromFetch.attributes.phoneNumber;
    this.lonlat = agencyFromFetch.attributes.lonlat;
    this.thumbnail = agencyFromFetch.attributes.thumbnail.data
      ? agencyFromFetch.attributes.thumbnail.data.attributes.url
      : "";
    this.juridicForm = agencyFromFetch.attributes.juridicForm;
    this.siret = agencyFromFetch.attributes.siret;
    this.legalRepresentative = agencyFromFetch.attributes.legalRepresentative;
    this.proCardNumber = agencyFromFetch.attributes.proCardNumber;
    this.deliveredByDPT = agencyFromFetch.attributes.deliveredByDPT;
    this.financialGuarantee = agencyFromFetch.attributes.financialGuarantee;
    this.enseigne = agencyFromFetch.attributes.enseigne;
    this.honoraire = agencyFromFetch.attributes.honoraire
      ? JSON.parse(agencyFromFetch.attributes.honoraire)
      : [];
    this.website = agencyFromFetch.attributes.website;
    this.searchVisible = agencyFromFetch.attributes.searchVisible;
    this.description = agencyFromFetch.attributes.description;
    this.users = agencyFromFetch.attributes.users
      ? agencyFromFetch.attributes.users.data.map((user) => userFactory(user))
      : [];
  }
}

export const agencyFactory = (data: IAgency): Agency => {
  return new Agency(data);
};
