import React, { ChangeEvent, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation, QueryResult } from "@apollo/client";
import { toast } from "react-toastify";

import { useSettingsStore } from "../lib/store/settings/useSettingsStore";
import { useAgencyStore, useAuthStore, useLoaderStore } from "../lib/store";
import { UPDATE_AGENCY } from "../lib/gql/Agency";
import { Agency, agencyFactory, AgencyToUpdate } from "../lib/models/Agency";
import { Formik, Field } from "formik";
import { AgencyFormScheme, AgencyFormValues } from "../lib/shape/AgencyForm";

import placeholder from "../assets/images/placeholder.png";
import {
  formatPhone,
  formatPhoneReverse,
  makeLonLat
} from "../lib/utils/utils";
import { UPLOAD_FILE } from "../lib/gql/Mandat";
import { isEqual } from "lodash";

export const SettingsScreen = observer(() => {
  const settingsStore = useSettingsStore();
  const authStore = useAuthStore();
  const agency = useAgencyStore();
  const loader = useLoaderStore();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const currAgency = agency.agency!;

  const [tmpPicture, setTmpPicture] = useState<string | null>(
    currAgency.thumbnail || null
  );

  const handleChangeEnv = (e: ChangeEvent<HTMLSelectElement>) => {
    authStore.logout();
    settingsStore.setEnv(e.target.value);
    navigate("/");
  };

  const [mutateFunction] = useMutation(UPDATE_AGENCY, {
    onCompleted: async ({ updateAgency }: { updateAgency: QueryResult }) => {
      agency.setAgency(agencyFactory(updateAgency.data));

      toast.success(t("agencyHasBeenUpdated"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      loader.stopLoading();
    },
    onError: (e) => {
      console.log("e :>> ", e);
      loader.stopLoading();
    }
  });

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onError: (e) => {
      console.log("error on UploadFile", e);
      loader.stopLoading();
    }
  });

  const handleUpdate = async (val: AgencyFormValues) => {
    loader.startLoading();
    const lonlat = await makeLonLat(val.address, val.postalCode, val.city);
    let updateAgency: AgencyToUpdate = {};
    if (typeof val.thumbnail === "string") {
      const newVal = Object.keys(val).reduce((acc: any, key: string) => {
        if (key !== "thumbnail") {
          acc[key] = val[key as keyof AgencyFormValues];
        }
        return acc;
      }, {});

      updateAgency = {
        ...newVal,
        lonlat,
        phoneNumber: formatPhone(val.phoneNumber),
        searchVisible: true
      };
    } else {
      await uploadFile({ variables: { file: val.thumbnail } }).then((pic) => {
        updateAgency = {
          ...val,
          lonlat,
          phoneNumber: formatPhone(val.phoneNumber),
          thumbnail: pic.data.upload.data.id,
          searchVisible: true
        };
      });
    }

    mutateFunction({ variables: { id: currAgency.id, data: updateAgency } });
  };

  const toMatchThis = Object.keys(currAgency).reduce(
    (acc: any, key: string) => {
      if (key === "phoneNumber") {
        acc[key] = formatPhoneReverse(currAgency[key]);
      } else if (
        !["honoraire", "id", "lonlat", "users", "searchVisible"].includes(key)
      ) {
        acc[key] = currAgency[key as keyof Agency];
      }
      return acc;
    },
    {}
  );

  return (
    <div className="p-6">
      <h1>{t("settings")}</h1>
      {settingsStore.env.NODE_ENV !== "production" && (
        <div className="w-6/12">
          <p>Environement actuel : {settingsStore.env.REACT_APP_ENV_NAME}</p>
          <select name="env" id="env-select" onChange={handleChangeEnv}>
            <option value="DEFAULT">-- Choisir un environnement --</option>
            <option value="LOCAL">LOCAL</option>
            <option value="STAGING">STAGING</option>
            <option value="PROD">PROD</option>
          </select>
        </div>
      )}
      <div className="flex flex-col w-6/12 mt-8">
        <h2>{t("agencyUpdate")}</h2>
        <div className="flex flex-col">
          <Formik
            initialValues={{
              name: currAgency.name || "",
              address: currAgency.address || "",
              postalCode: currAgency.postalCode || "",
              city: currAgency.city || "",
              email: currAgency.email || "",
              phoneNumber: currAgency.phoneNumber
                ? formatPhoneReverse(currAgency.phoneNumber)
                : "",
              siret: currAgency.siret || "",
              legalRepresentative: currAgency.legalRepresentative || "",
              proCardNumber: currAgency.proCardNumber || "",
              deliveredByDPT: currAgency.deliveredByDPT || "",
              financialGuarantee: currAgency.financialGuarantee || "",
              enseigne: currAgency.enseigne || "",
              website: currAgency.website || "",
              thumbnail: currAgency.thumbnail || "",
              juridicForm: currAgency.juridicForm || "",
              description: currAgency.description || ""
            }}
            validationSchema={AgencyFormScheme}
            onSubmit={(values: AgencyFormValues, actions) => {
              handleUpdate(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              initialErrors,
              setFieldValue,
              values
            }) => (
              <form onSubmit={handleSubmit} className="agencyForm">
                <div className="formField">
                  <label htmlFor="name">{t("agencyName")}</label>
                  <Field
                    type="text"
                    name="name"
                    id="nameID"
                    placeholder={t("agencyName")}
                  />
                  <span className="field-required">
                    {errors.name && touched.name && errors.name}
                  </span>
                </div>
                <div className="formField">
                  <label htmlFor="address">{t("address")}</label>
                  <Field
                    type="text"
                    name="address"
                    id="addressID"
                    placeholder={t("address")}
                  />
                  <span className="field-required">
                    {errors.address && touched.address && errors.address}
                  </span>
                </div>
                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="postalCode">{t("postalCode")}</label>
                    <Field
                      type="text"
                      name="postalCode"
                      id="postalCodeID"
                      placeholder={t("postalCode")}
                    />
                    <span className="field-required">
                      {errors.postalCode &&
                        touched.postalCode &&
                        errors.postalCode}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="city">{t("city")}</label>
                    <Field
                      type="text"
                      name="city"
                      id="cityID"
                      placeholder={t("city")}
                    />
                    <span className="field-required">
                      {errors.city && touched.city && errors.city}
                    </span>
                  </div>
                </div>
                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="email">{t("email")}</label>
                    <Field
                      type="text"
                      name="email"
                      id="emailID"
                      placeholder={t("email")}
                    />
                    <span className="field-required">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="phoneNumber">{t("phoneNumber")}</label>
                    <Field
                      type="text"
                      name="phoneNumber"
                      id="phoneNumberID"
                      placeholder={t("phoneNumber")}
                    />
                    <span className="field-required">
                      {errors.phoneNumber &&
                        touched.phoneNumber &&
                        errors.phoneNumber}
                    </span>
                  </div>
                </div>
                <div className="formField">
                  <label htmlFor="siret">{t("enterpriseSiret")}</label>
                  <Field
                    type="text"
                    name="siret"
                    id="siretID"
                    placeholder={t("enterpriseSiret")}
                  />
                  <span className="field-required">
                    {errors.siret && touched.siret && errors.siret}
                  </span>
                </div>
                <div className="formField">
                  <label htmlFor="description">{t("description")}</label>
                  <Field
                    type="text"
                    name="description"
                    id="descriptionID"
                    placeholder={t("description")}
                    component="textarea"
                    rows={4}
                  />
                  <span className="field-required">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </span>
                </div>
                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="juridicForm">{t("juridicForm")}</label>
                    <Field
                      type="text"
                      name="juridicForm"
                      id="juridicFormID"
                      placeholder={t("juridicForm")}
                    />
                    <span className="field-required">
                      {errors.juridicForm &&
                        touched.juridicForm &&
                        errors.juridicForm}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="enseigne">{t("signLinkedToAgency")}</label>
                    <Field
                      type="text"
                      name="enseigne"
                      id="enseigneID"
                      placeholder={t("signLinkedToAgency")}
                    />
                    <span className="field-required">
                      {errors.enseigne && touched.enseigne && errors.enseigne}
                    </span>
                  </div>
                </div>

                <div className="formField">
                  <label htmlFor="legalRepresentative">
                    {t("legalRepresentative")}
                  </label>
                  <Field
                    type="text"
                    name="legalRepresentative"
                    id="legalRepresentativeID"
                    placeholder={t("legalRepresentative")}
                  />
                  <span className="field-required">
                    {errors.legalRepresentative &&
                      touched.legalRepresentative &&
                      errors.legalRepresentative}
                  </span>
                </div>

                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="proCardNumber">{t("proCardNumber")}</label>
                    <Field
                      type="text"
                      name="proCardNumber"
                      id="proCardNumberID"
                      placeholder={t("proCardNumber")}
                    />
                    <span className="field-required">
                      {errors.proCardNumber &&
                        touched.proCardNumber &&
                        errors.proCardNumber}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="deliveredByDPT">
                      {t("deliveredByDPT")}
                    </label>
                    <Field
                      type="text"
                      name="deliveredByDPT"
                      id="deliveredByDPTID"
                      placeholder={t("deliveredByDPT")}
                    />
                    <span className="field-required">
                      {errors.deliveredByDPT &&
                        touched.deliveredByDPT &&
                        errors.deliveredByDPT}
                    </span>
                  </div>
                </div>
                <div className="formField">
                  <label htmlFor="financialGuarantee">
                    {t("financialGuarantee")}
                  </label>
                  <Field
                    type="text"
                    name="financialGuarantee"
                    id="financialGuaranteeID"
                    placeholder={t("financialGuarantee")}
                  />
                  <span className="field-required">
                    {errors.financialGuarantee &&
                      touched.financialGuarantee &&
                      errors.financialGuarantee}
                  </span>
                </div>
                <div className="formField">
                  <label htmlFor="website">{t("agencyWebsite")}</label>
                  <Field
                    type="text"
                    name="website"
                    id="websiteID"
                    placeholder={t("agencyWebsite")}
                  />
                  <span className="field-required">
                    {errors.website && touched.website && errors.website}
                  </span>
                </div>
                <div className="your-file">
                  <div className="formField">
                    <label className="mb-2" htmlFor="thumbnail">
                      {t("agencyLogo")}
                    </label>
                    <div className="your-file-preview">
                      <img src={tmpPicture || placeholder} alt="" />
                    </div>
                  </div>
                  <label className="button rounded-full bg-nodimoblue text-center">
                    {t("import")}
                  </label>
                  <input
                    id="thumbnailID"
                    name="thumbnail"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setFieldValue("thumbnail", event.target.files![0]);
                      setTmpPicture(
                        URL.createObjectURL(event.target.files![0])
                      );
                    }}
                  />
                  <span className="field-required">
                    {errors.thumbnail && touched.thumbnail && errors.thumbnail}
                  </span>
                </div>

                <div className="formField">
                  <button
                    type="submit"
                    className="rounded-full bg-nodimoblue w-4/12"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !!initialErrors.name ||
                      !!initialErrors.address ||
                      !!initialErrors.postalCode ||
                      !!initialErrors.city ||
                      !!initialErrors.email ||
                      !!initialErrors.phoneNumber ||
                      !!initialErrors.siret ||
                      !!initialErrors.legalRepresentative ||
                      !!initialErrors.proCardNumber ||
                      !!initialErrors.deliveredByDPT ||
                      !!initialErrors.financialGuarantee ||
                      !!initialErrors.enseigne ||
                      !!initialErrors.juridicForm ||
                      !!initialErrors.website ||
                      !tmpPicture ||
                      isEqual(values, toMatchThis)
                    }
                  >
                    {t("update")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
