import { observer } from "mobx-react-lite";
import React from "react";
import { Outlet } from "react-router-dom";

import layoutBg from "../assets/images/introHouse.webp";

export const OnboardingScreen = observer(() => {
  return (
    <div className="flex justify-between min-h-screen">
      <img src={layoutBg} alt="" className="imageOnboarding hidden lg:block" />

      <Outlet />
    </div>
  );
});
