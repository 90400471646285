import React from "react";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { isEqual } from "lodash";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "mapbox-gl/dist/mapbox-gl.css";
import { createUploadLink } from "apollo-upload-client";

import "./App.css";
import { Loader } from "./components/Loader/Loader";
import { ProtectedRoute } from "./components/ProtectedRoutes/ProtectedRoutes";
import {
  useAgencyStore,
  useAuthStore,
  useSettingsStore,
  useUserStore
} from "./lib/store";
import { StoreProvider } from "./lib/store/StoreProvider";
import {
  AgencyCreatorScreen,
  CGUScreen,
  CGVScreen,
  ChatScreen,
  ChatSelectorScreen,
  CreateMandatScreen,
  DashBoardScreen,
  DebugScreen,
  HomeScreen,
  LoginScreen,
  RegisterScreen,
  SettingsScreen,
  SignatureScreen,
  TimeSlotScreen,
  PasswordForgotScreen,
  PasswordResetScreen,
  AgencyFeesCreatorScreen,
  AccomodationForwardScreen,
  AgencyManagerScreen,
  AmendmentScreen
} from "./screens";
import { AccomodationsListScreen } from "./screens/AccomodationListScreen";
import { AccomodationScreen } from "./screens/AccomodationScreen";
import { OnboardingScreen } from "./screens/OnboardingScreen";

console.log(process.env.REACT_APP_VERSION);

export const App = observer(() => {
  const auth = useAuthStore();
  const user = useUserStore();
  const settings = useSettingsStore();
  const agency = useAgencyStore();

  const navigate = useNavigate();

  const codeShape = process.env.REACT_APP_CODE!.split(" ");
  const code: string[] = [];

  const httpLink = createUploadLink({
    uri: `${settings.env.REACT_APP_API_URL}/graphql`
  });

  const authLink = setContext((_, { headers }) => {
    const token = auth.userToken;

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });

  const handleCode = (e: KeyboardEvent) => {
    code.unshift(e.key);

    if (code.length > codeShape.length) {
      code.pop();
    }

    if (code.length === codeShape.length) {
      const reverseCode = [""].concat(code).reverse();

      if (
        isEqual(
          reverseCode.filter((n) => n),
          codeShape
        )
      )
        navigate("/debug");
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleCode);

    return () => {
      document.removeEventListener("keydown", handleCode);
    };
  }, []);

  const haveAgencyRole =
    (user.currentUser && user.currentUser.role?.name === "Agency") || false;

  const haveFees =
    (agency.agency &&
      agency.agency.honoraire &&
      agency.agency.honoraire.length > 0) ||
    false;

  return (
    <ApolloProvider client={client}>
      <StoreProvider>
        <Routes>
          <Route
            index
            element={
              !user.currentUser ? (
                <ProtectedRoute
                  redirectPath={"/auth/login"}
                  isAllowed={!!user.currentUser}
                >
                  <HomeScreen />
                </ProtectedRoute>
              ) : !haveAgencyRole ? (
                <ProtectedRoute
                  redirectPath={"/auth/agency-creator"}
                  isAllowed={haveAgencyRole}
                >
                  <HomeScreen />
                </ProtectedRoute>
              ) : !haveFees ? (
                <ProtectedRoute
                  redirectPath={"/auth/agency-fees"}
                  isAllowed={haveFees}
                >
                  <HomeScreen />
                </ProtectedRoute>
              ) : (
                <HomeScreen />
              )
            }
          />
          <Route
            path="/auth"
            element={
              <ProtectedRoute
                redirectPath="/"
                isAllowed={!haveAgencyRole || !user.currentUser || !haveFees}
              >
                <OnboardingScreen />
              </ProtectedRoute>
            }
          >
            <Route path="login" element={<LoginScreen />} />
            <Route path="register" element={<RegisterScreen />} />
            <Route path="agency-creator" element={<AgencyCreatorScreen />} />
            <Route path="agency-fees" element={<AgencyFeesCreatorScreen />} />
            <Route path="password-forgot" element={<PasswordForgotScreen />} />
            <Route path="reset" element={<PasswordResetScreen />} />
          </Route>

          <Route
            path="/"
            element={
              !user.currentUser ? (
                <ProtectedRoute
                  redirectPath={"/auth/login"}
                  isAllowed={!!user.currentUser}
                >
                  <HomeScreen />
                </ProtectedRoute>
              ) : !haveAgencyRole ? (
                <ProtectedRoute
                  redirectPath={"/auth/agency-creator"}
                  isAllowed={haveAgencyRole}
                >
                  <HomeScreen />
                </ProtectedRoute>
              ) : !haveFees ? (
                <ProtectedRoute
                  redirectPath={"/auth/agency-fees"}
                  isAllowed={haveFees}
                >
                  <HomeScreen />
                </ProtectedRoute>
              ) : (
                <HomeScreen />
              )
            }
          >
            <Route path="dashboard" element={<DashBoardScreen />} />
            <Route path="accomodations" element={<AccomodationsListScreen />} />
            <Route
              path="accomodations/:accomodationId"
              element={<AccomodationScreen />}
            />
            <Route
              path="accomodations/:accomodationId/create-mandat"
              element={<CreateMandatScreen />}
            />
            <Route
              path="accomodations/:accomodationId/create-mandat/sign"
              element={<SignatureScreen />}
            />
            <Route
              path="accomodations/:accomodationId/amendment"
              element={<AmendmentScreen />}
            />
            <Route
              path="accomodations/:accomodationId/amendment/sign"
              element={<SignatureScreen />}
            />

            <Route path="settings" element={<SettingsScreen />} />
            <Route path="debug" element={<DebugScreen />} />
            <Route path="timeslot" element={<TimeSlotScreen />} />
            <Route path="chat" element={<ChatSelectorScreen />}>
              <Route path=":userId" element={<ChatScreen />} />
            </Route>
            <Route path="agency" element={<AgencyManagerScreen />} />
          </Route>
          <Route path="cgu" element={<CGUScreen />} />
          <Route path="cgv" element={<CGVScreen />} />
          <Route
            path="accomodation-forward/:accomodationId"
            element={<AccomodationForwardScreen />}
          />
        </Routes>

        <ToastContainer />
        <Loader />
      </StoreProvider>
    </ApolloProvider>
  );
});
