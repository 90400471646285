import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import SignatureCanvas from "react-signature-canvas";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";

import { Header } from "../components/Header/Header";
import {
  useAgencyStore,
  useAmendmentCreatorStore,
  useLoaderStore
} from "../lib/store";
import { UPDATE_MANDAT, UPLOAD_FILE } from "../lib/gql/Mandat";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";
import { UPDATE_AMENDMENT } from "../lib/gql/Amendment";

export const SignatureScreen = observer(() => {
  const agencyStore = useAgencyStore();
  const accomodationStore = useAccomodationStore();
  const amendmentStore = useAmendmentCreatorStore();
  const loader = useLoaderStore();

  const { t } = useTranslation();

  const mode = window.location.href.includes("create-mandat")
    ? "MANDAT"
    : "AMENDMENT";

  const navigate = useNavigate();

  const signRef = useRef<SignatureCanvas>(null);

  const [signature, setSignature] = useState<string>("");
  const [error, setError] = useState<boolean>(false);

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onError: (e) => {
      console.log("e", e);
    }
  });

  const [updateMandat] = useMutation(UPDATE_MANDAT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("errored here", e);
    }
  });

  const [updateAmendment] = useMutation(UPDATE_AMENDMENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("errored here", e);
    }
  });

  const handleClear = () => {
    setError(false);
    signRef.current!.clear();
  };

  const handleConfirm = () => {
    setSignature(signRef.current!.toDataURL("base64"));
  };

  useEffect(() => {
    if (!signature) return;

    if (signRef.current && signRef.current.isEmpty()) {
      setError(true);
      return;
    }
    setError(false);
    (async () => {
      loader.startLoading();
      let file;

      fetch(signature)
        .then((res) => res.blob())
        .then(async (blob) => {
          file = new File([blob], "signature agency", { type: "image/png" });
          await uploadFile({ variables: { file } }).then((val) => {
            if (mode === "MANDAT") {
              updateMandat({
                variables: {
                  id: accomodationStore.selected?.mandat?.id,
                  data: {
                    signatureAgency: val.data.upload.data.id
                  }
                }
              })
                .then(() => {
                  navigate(
                    `/accomodations/${
                      accomodationStore.selected!.accomodation.id
                    }`
                  ),
                    loader.stopLoading();
                })
                .catch(() => loader.stopLoading());
            } else {
              updateAmendment({
                variables: {
                  id: accomodationStore.selected?.amendments
                    .slice()
                    .sort((a, b) => Number(b.id) - Number(a.id))
                    .find((am) => !am.completed)!.id,
                  data: {
                    signatureAgency: val.data.upload.data.id
                  }
                }
              })
                .then(() => {
                  navigate(
                    `/accomodations/${
                      accomodationStore.selected!.accomodation.id
                    }`
                  ),
                    loader.stopLoading();
                })
                .catch(() => loader.stopLoading());
              amendmentStore.reset();
            }
          });
        });
    })();
  }, [signature]);

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />

      <div className="flex justify-center items-center w-full mt-12 mb-12">
        <div className="bg-white w-11/12 2xl:w-8/12 flex flex-col items-center justify-center p-10 rounded-lg shadow-lg">
          <h2 className="text-3xl mb-12 font-bold text-nodimodarkblue">
            {mode === "MANDAT" ? t("signMandate") : t("signAmendment")}
          </h2>

          <div>
            <SignatureCanvas
              ref={signRef}
              penColor="black"
              canvasProps={{
                className: `m-signature-pad ${error && "errorPad"}`
              }}
            />
            {error && <span className="signError">{t("signHere")}</span>}
          </div>
          <div className="w-full mt-6">
            <button
              type="submit"
              className="w-2/12 bg-nodimodarkblue hover:bg-nodibluelessopacity hover:text-nodimodarkblue mt-6"
              onClick={handleClear}
            >
              {t("reset")}
            </button>
            <button
              type="submit"
              className="w-full bg-nodimoblue mt-6"
              onClick={handleConfirm}
            >
              {t("sign")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
