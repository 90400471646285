import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    login(input: { identifier: $email, password: $password }) {
      jwt
      user {
        id
        username
        email
        firstName
        lastName
        confirmed
        blocked
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation RegisterMutation($input: UsersPermissionsRegisterInput!) {
    register(input: $input) {
      jwt
      user {
        id
        username
        email
        firstName
        lastName
        confirmed
        blocked
      }
    }
  }
`;
