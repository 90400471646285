import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { QueryResult, useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";

import { Header } from "../components/Header/Header";
import { useAgencyStore, useAuthStore, useLoaderStore } from "../lib/store";
import { REGISTER_MUTATION } from "../lib/gql/Auth";
import { formatPhone } from "../lib/utils/utils";
import {
  GET_AGENCY_FROM_USER,
  REMOVE_USER_FROM_AGENCY
} from "../lib/gql/Agency";
import { agencyFactory } from "../lib/models/Agency";
import { UPDATE_ME } from "../lib/gql/Me";
import { AddUserFormScheme, AddUserFormValues } from "../lib/shape/UserForm";

export const AgencyManagerScreen = observer(() => {
  const { t } = useTranslation();

  const loader = useLoaderStore();
  const agencyStore = useAgencyStore();
  const auth = useAuthStore();

  const [updateCurrUser] = useMutation(UPDATE_ME, {
    onError: () => {
      toast.error(t("agencyLogError"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      loader.stopLoading();
    }
  });

  const [fetchAgency] = useLazyQuery(GET_AGENCY_FROM_USER, {
    fetchPolicy: "network-only",
    onCompleted: ({
      usersPermissionsUser
    }: {
      usersPermissionsUser: QueryResult;
    }) => {
      if (!usersPermissionsUser) {
        loader.stopLoading();
        return;
      }

      agencyStore.setAgency(
        agencyFactory(usersPermissionsUser.data.attributes.agency.data)
      );
      loader.stopLoading();
    },
    onError: () => {
      toast.error(t("agencyLogError"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      loader.stopLoading();
    }
  });

  const [removeUser] = useMutation(REMOVE_USER_FROM_AGENCY, {
    onCompleted: () => {
      toast.success(t("userHasBeenDeleted"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      fetchAgency({ variables: { id: auth.user.currentUser!.id } });
    },
    onError: () => {
      toast.error(t("agencyLogError"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      loader.stopLoading();
    }
  });

  const [mutateFunction, { loading }] = useMutation(REGISTER_MUTATION, {
    onCompleted: ({ register }) => {
      toast.success(t("userHasBeenAdded"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      updateCurrUser({
        variables: {
          id: register.user.id,
          data: { role: 3 }
        }
      });

      fetchAgency({ variables: { id: auth.user.currentUser!.id } });
    },
    onError: () => {
      toast.error(t("agencyLogError"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
      loader.stopLoading();
    }
  });

  const handleRemoveUser = (id: string) => {
    loader.startLoading();
    removeUser({
      variables: { id: id, data: { agency: null } }
    });
  };

  useEffect(() => {
    if (loading) {
      loader.startLoading();
    }
  }, [loader, loading]);

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />

      <div className="flex flex-row justify-around w-full mt-12">
        <div className="w-5/12">
          <h2 className="text-nodimodarkblue font-bold  text-center">
            {t("collaboratorsList")}
          </h2>
          <div>
            {agencyStore.agency && agencyStore.agency.users ? (
              agencyStore.agency.users.map((user) => (
                <div
                  key={user.id}
                  className="border-b-2 border-grey-500 w-full p-4 hover:bg-gray-100 flex justify-between items-center"
                >
                  <p className="text-sm">
                    {user.firstName} {user.lastName} - {user.email}
                  </p>
                  <i
                    className="fa-solid fa-xmark text-nodimoerror cursor-pointer select-none"
                    onClick={() => handleRemoveUser(user.id)}
                  ></i>
                </div>
              ))
            ) : (
              <div>
                <p className="text-sm text-center">{t("noCollaborator")}</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-5/12">
          <h2 className="text-nodimodarkblue font-bold  text-center">
            {t("addCollaborator")}
          </h2>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              phoneNumber: "",
              readCondition: false
            }}
            validationSchema={AddUserFormScheme}
            onSubmit={(values: AddUserFormValues, { resetForm }) => {
              mutateFunction({
                variables: {
                  input: {
                    ...values,
                    username: values.email,
                    phoneNumber: formatPhone(values.phoneNumber),
                    agency: agencyStore.agency!.id
                  }
                }
              });
              resetForm({
                values: {
                  email: "",
                  firstName: "",
                  lastName: "",
                  password: "",
                  phoneNumber: ""
                },
                isSubmitting: false,
                isValidating: false
              });
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              initialErrors
            }) => (
              <form onSubmit={handleSubmit} className="mt-8">
                <div className="formField">
                  <Field
                    type="text"
                    name="firstName"
                    id="firstNameID"
                    placeholder={t("firstName")}
                  />
                  <span className="field-required">
                    {errors.firstName && touched.firstName && errors.firstName}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="text"
                    name="lastName"
                    id="lastNameID"
                    placeholder={t("lastName")}
                  />
                  <span className="field-required">
                    {errors.lastName && touched.lastName && errors.lastName}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="email"
                    name="email"
                    id="loginID"
                    placeholder={t("email")}
                  />
                  <span className="field-required">
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="password"
                    name="password"
                    id="passwordID"
                    placeholder={t("password")}
                    autoComplete="password"
                  />
                  <span className="field-required">
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="text"
                    name="phoneNumber"
                    id="phoneNumberID"
                    placeholder={t("phoneNumber")}
                  />
                  <span className="field-required">
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </span>
                </div>
                <div className="formField">
                  <button
                    type="submit"
                    className="rounded-full w-full bg-nodimoblue"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !!initialErrors.email ||
                      !!initialErrors.password ||
                      !!initialErrors.firstName ||
                      !!initialErrors.lastName ||
                      !!initialErrors.phoneNumber
                    }
                  >
                    {t("registerCollaborator")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
