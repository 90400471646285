import { boolean, object, string } from "yup";

export type RegisterFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  phoneNumber: string;
  readCondition: boolean;
};

export const RegisterFormScheme = object().shape({
  firstName: string()
    .matches(/^[a-zÀ-ÿ ,.'-]+$/i, "Doit être composé uniquement de lettres !")
    .min(2, "Trop court")
    .max(50, "Trop long")
    .required("Champ obligatoire"),
  lastName: string()
    .matches(/^[a-zÀ-ÿ ,.'-]+$/i, "Doit être composé uniquement de lettres !")
    .min(2, "Trop court")
    .max(50, "Trop long")
    .required("Champ obligatoire"),
  email: string()
    .email("Ce champ doit correspondre à un e-mail !")
    .required("Champs requis !"),
  password: string()
    .min(8, "Trop court")
    .max(50, "Trop long")
    .required("Champ obligatoire"),
  phoneNumber: string()
    .matches(/^[0-9]+$/, "Doit être composé uniquement de chiffres !")
    .min(10, "Numéro de téléphone non valide")
    .max(10, "Numéro de téléphone non valide")
    .required("Champ obligatoire"),
  readCondition: boolean().required("Champs requis !")
});
