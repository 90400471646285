import { MediaReturn } from "./Accomodation";
import { MediaAttributes } from "./Media";

export interface IAmendment {
  id: string;
  attributes: {
    price: number;
    fees: string;
    amendmentPdf: {
      data: MediaAttributes[];
    };
    isSigned: boolean;
    signatureDate: Date;
    completed: boolean;
    signature: {
      data: MediaAttributes[] | null;
    };
    signatureAgency: {
      data: MediaAttributes | null;
    };
    relatedUsers?: string;
  };
}

export class Amendment {
  public id: string;

  public price: number;

  public fees: string;

  public amendmentPdf: string[];

  public signatureDate: Date;

  public isSigned: boolean;

  public signature: MediaReturn[];

  public signatureAgency: MediaReturn | null;

  public completed: boolean;

  public relatedUsers: string;

  constructor(amendmentFromFetch: IAmendment) {
    this.id = amendmentFromFetch.id;
    this.amendmentPdf = amendmentFromFetch.attributes.amendmentPdf.data.map(
      (itm) => itm.attributes.url
    );
    this.signatureDate = amendmentFromFetch.attributes.signatureDate;
    this.isSigned = amendmentFromFetch.attributes.isSigned;
    this.signature = amendmentFromFetch.attributes.signature.data
      ? amendmentFromFetch.attributes.signature.data.map((sign) => {
          return {
            url: sign.attributes.url,
            id: sign.id
          };
        })
      : [];
    this.signatureAgency = amendmentFromFetch.attributes.signatureAgency.data
      ? {
          url: amendmentFromFetch.attributes.signatureAgency.data.attributes
            .url,
          id: amendmentFromFetch.attributes.signatureAgency.data.id
        }
      : null;
    this.price = amendmentFromFetch.attributes.price;
    this.completed = amendmentFromFetch.attributes.completed;
    this.fees = amendmentFromFetch.attributes.fees || "";
    this.relatedUsers = amendmentFromFetch.attributes.relatedUsers || "";
  }
}

export const amendmentFactory = (data: IAmendment): Amendment => {
  return new Amendment(data);
};
