import { gql } from "@apollo/client";

export const UPLOAD_MULTIPLE_MEDIA = gql`
  mutation ($files: [Upload]!) {
    multipleUpload(files: $files) {
      data {
        id
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation ($file: Upload!) {
    upload(file: $file) {
      data {
        id
      }
    }
  }
`;

export const CREATE_MANDAT = gql`
  mutation ($data: MandatInput!) {
    createMandat(data: $data) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_MANDAT = gql`
  mutation ($id: ID!, $data: MandatInput!) {
    updateMandat(id: $id, data: $data) {
      data {
        id
        attributes {
          mandatpdf(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                url
              }
            }
          }
          mandateNumber
          customFees
          completed
          ownerSigned
          ownersValidated
          signatureAgency {
            data {
              attributes {
                url
              }
              id
            }
          }
          signatureDate
          isSigned
          signature {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
