import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import { useAuthStore } from "../../lib/store/auth/useAuthStore";
import { useUserStore } from "../../lib/store/user/useUserStore";
import { useAgencyStore } from "../../lib/store";
import { useAccomodationStore } from "../../lib/store";
import nodimoLogo from "../../assets/images/nodimo.png";
import { useConversationStore } from "../../lib/store/conversation/useConversationStore";

interface NavigationItem {
  icon: string;
  link: string;
  text: string;
  pastille?: boolean;
}

const NavigationItem = ({
  icon,
  link,
  text,
  pastille = false
}: NavigationItem) => {
  return (
    <li className="navListItem navListIstem--board hover:bg-nodimodarkblue  text-nodimogray hover:text-white  focus:text-white active:text-white hover:2xl:bg-nodimoblue">
      <Link
        to={link}
        className="relative flex justify-center items-center text-inherit 2xl:justify-start "
      >
        {pastille && (
          <span className="absolute -top-3 -left-1 ">
            <span className="inline-flex rounded-full h-2 w-2 bg-nodimoblue"></span>
          </span>
        )}
        <i
          className={`fa-solid fa-${icon} text-inherit text-lg 2xl:text-sm `}
        ></i>
        <p className="hidden 2xl:inline ml-2 text-sm text-inherit font-bold">
          {text}
        </p>
      </Link>
    </li>
  );
};

export const Navigation = observer(() => {
  const auth = useAuthStore();
  const user = useUserStore();
  const agency = useAgencyStore();
  const accomodation = useAccomodationStore();
  const conversation = useConversationStore();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const navItems: NavigationItem[] = [
    {
      icon: "border-all",
      link: "dashboard",
      text: t("dashboard")
    },
    {
      icon: "house",
      link: "accomodations",
      text: t("propertyMenu")
    },
    /*{
      icon: "file",
      link: "accomodations",
      text: "Documents"
    },*/
    {
      icon: "message",
      link: "chat",
      text: t("messages"),
      pastille: conversation.haveNewMessage
    },
    {
      icon: "users",
      link: "agency",
      text: t("team")
    },
    {
      icon: "gear",
      link: "settings",
      text: t("settings")
    }
  ];

  const handleLogout = () => {
    auth.logout();
    agency.reset();
    conversation.reset();
    accomodation.reset();
    user.resetCurrentUser();

    navigate("/");
  };

  return (
    <div className="fixed w-20 2xl:w-52 h-screen px-4 shadow-lg z-10 flex flex-col items-center bg-white">
      <p className="navLogo">
        <img src={nodimoLogo} alt="" />
      </p>
      <ul className="mt-6 cursor-pointer select-none">
        {navItems.map((item) => {
          return <NavigationItem key={item.text} {...item} />;
        })}
        <li
          className="navListItem navListIstem--board hover:bg-nodimodarkblue hover:2xl:bg-nodimoblue  text-nodimogray hover:text-white  focus:text-white active:text-white"
          onClick={handleLogout}
        >
          <div className="flex justify-center items-center text-inherit 2xl:justify-start ">
            <i className="fa-solid fa-arrow-right-from-bracket text-inherit"></i>
            <p className="hidden 2xl:inline ml-2 text-sm text-inherit font-bold">
              {t("logout")}
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
});
