import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import mapboxgl from "mapbox-gl";
import { upperFirst } from "lodash";
import ReactPlayer from "react-player";
import FsLightbox from "fslightbox-react";

import {
  ConditionType,
  EnergyClass,
  FacingType,
  TypeHeat
} from "../lib/models/Accomodation";
import { GET_ACC_BY_ID } from "../lib/gql/Accomodation";
import {
  fetchFromImgProxy,
  formatPrice,
  getCityFromAddress,
  getPostalCodeFromAddress,
  priceWithHonoraireCalculator
} from "../lib/utils/utils";

import { AccomodationManagement } from "../components/AccomodationManagement/AccomodationManagement";

import {
  AccomodationAgency,
  accomodationAgencyFactory
} from "../lib/models/AccomodationAgency";
import axios from "axios";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageNotAvailable = require("../assets/images/image-not-available.jpeg");

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API_KEY!;

export const AccomodationForwardScreen = observer(() => {
  const { accomodationId } = useParams();
  const { t } = useTranslation();

  const mapContainer = useRef(null);

  const [accAg, setAccAg] = useState<AccomodationAgency | null>(null);
  const { data } = useQuery(GET_ACC_BY_ID, {
    variables: {
      id: accomodationId
    },
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    if (!data) return;
    if (!data.accomodationAgency) return;
    const acc: AccomodationAgency = accomodationAgencyFactory(
      data.accomodationAgency.data
    );

    setAccAg(acc);
  }, [data]);

  const req = axios.create({
    baseURL: "https://nominatim.openstreetmap.org/"
  });

  const getValue = async () =>
    await req.get(
      `search.php?city=${getCityFromAddress(
        accAg!.accomodation
      )}&postalcode=${getPostalCodeFromAddress(
        accAg!.accomodation
      )}&polygon_geojson=1&format=json`
    );

  useEffect(() => {
    (async () => {
      if (!mapContainer.current) return;
      const rslt = await getValue();

      const map = new mapboxgl.Map({
        container: mapContainer.current!,
        style: "mapbox://styles/mapbox/light-v10",
        center: [rslt.data[0].lon || 3.882936, rslt.data[0].lat || 45.042768],
        zoom: 9
      });

      if (accAg) {
        map.on("load", () => {
          map.fitBounds([
            [rslt.data[0].boundingbox[2], rslt.data[0].boundingbox[0]],
            [rslt.data[0].boundingbox[3], rslt.data[0].boundingbox[1]]
          ]);

          map.addSource("bondary", {
            type: "geojson",
            data: {
              properties: {},
              type: "Feature",
              geometry: rslt.data[0].geojson
            }
          });

          map.addLayer({
            id: "bondary",
            type: "fill",
            source: "bondary", // reference the data source
            layout: {},
            paint: {
              "fill-color": "#01CCD2", // blue color fill
              "fill-opacity": 0.1
            }
          });
          // Add a black outline around the polygon.
          map.addLayer({
            id: "outline",
            type: "line",
            source: "bondary",
            layout: {},
            paint: {
              "line-color": "#005376",
              "line-width": 3
            }
          });
        });
      }

      // cleanup function to remove map on unmount
      return () => map.remove();
    })();
  }, [accAg]);

  const [togglerPicture, setTogglerPicture] = useState<boolean>(false);
  const [togglerPlan, setTogglerPlan] = useState<boolean>(false);
  const [imgIndex, setImgIndex] = useState<number>(0);

  const handleDisplayPicture = (index: number) => {
    setImgIndex(index);
    setTogglerPicture(!togglerPicture);
  };

  const handleDisplayPlan = (index: number) => {
    setImgIndex(index);
    setTogglerPlan(!togglerPlan);
  };

  const displayPrice = (acc: AccomodationAgency) => {
    try {
      const lastAmendmentCompleted = acc.amendments
        .slice()
        .sort((a, b) => Number(b.id) - Number(a.id))
        .find((amd) => amd.completed);

      return lastAmendmentCompleted
        ? lastAmendmentCompleted.price
        : acc.accomodation.price;
    } catch (e) {
      return acc.accomodation.price;
    }
  };

  return (
    <div className="wrapper">
      <div className="mt-6 px-6">
        {accAg && (
          <div>
            <div className="flex flex-row items-center my-12 justify-center">
              <img
                src={
                  accAg.agency!.thumbnail
                    ? fetchFromImgProxy(accAg.agency!.thumbnail, {
                        format: "webp",
                        width: 400
                      })
                    : imageNotAvailable
                }
                className="object-contain w-24 h-24"
              />
              <p className="text-2xl font-bold text-nodimodarkblue ml-4">
                {t("agencyPropose", { name: accAg.agency!.name })}
              </p>
            </div>
            <div className="flex justify-center my-6">
              <div className="w-full xl:w-7/12 bg-nodimodarkblue p-6 text-white rounded-lg">
                <h2 className="mb-4">{t("contactAgencyAgain")} :</h2>
                <div className="flex flex-col xl:flex-row xl:justify-between">
                  <p className="mb-2 xl:mb-0">
                    <i className="fa-sharp fa-solid fa-location-dot mr-2"></i>
                    <a
                      href={` http://maps.google.co.uk/maps?q=${accAg.agency?.address}, ${accAg.agency?.postalCode} ${accAg.agency?.city}`}
                      target="blank"
                    >{`${accAg.agency?.address}, ${accAg.agency?.postalCode} ${accAg.agency?.city}`}</a>
                  </p>
                  <p className="mb-2 xl:mb-0">
                    <i className="fa-solid fa-phone mr-2"></i>
                    <a href={`tel:${accAg.agency?.phoneNumber}`}>
                      {accAg.agency?.phoneNumber.replace("+33", "0")}
                    </a>
                  </p>
                  <p className="mb-2 xl:mb-0">
                    <i className="fa-solid fa-envelope mr-2"></i>
                    <a href={`mailto:${accAg.agency?.email}`}>
                      {accAg.agency?.email}
                    </a>
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col-reverse xl:flex-row justify-around">
              <div className="w-full xl:w-7/12 relative">
                <img
                  src={
                    accAg.accomodation.photos[0]
                      ? fetchFromImgProxy(accAg.accomodation.photos[0].url, {
                          format: "webp",
                          width: 800
                        })
                      : imageNotAvailable
                  }
                  className="object-cover w-full max-h-96 2xl:max-h-[40rem] shadow-lg rounded-md mb-6"
                />

                <div className="relative">
                  <div className="flex justify-start items-center mb-6">
                    <p className="text-3xl font-bold text-nodimodarkblue">
                      {t(accAg.accomodation.type.slug)} {t("in")}{" "}
                      {getCityFromAddress(accAg.accomodation)} -{" "}
                      {formatPrice(
                        priceWithHonoraireCalculator(
                          displayPrice(accAg),
                          accAg.agency!.honoraire,
                          accAg.mandat?.customFees
                        )
                      )}
                    </p>
                  </div>

                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("propertyDescription")}
                  </p>
                  <div className="flex flex-col items-end relative lg:absolute right-0 top-0">
                    <div className="flex">
                      <p className="accomodationAsset">
                        <i className="fa-solid fa-camera"></i>{" "}
                        {accAg.accomodation.photos.length}{" "}
                        {t("photo", {
                          count: accAg.accomodation.photos.length
                        })}
                      </p>
                      {accAg.accomodation.videos.length > 0 && (
                        <p className="accomodationAsset">
                          <i className="fa-solid fa-video"></i>{" "}
                          {accAg.accomodation.videos.length}{" "}
                          {t("video", {
                            count: accAg.accomodation.videos.length
                          })}
                        </p>
                      )}
                    </div>
                  </div>
                  <p className="w-9/12">{accAg.accomodation.description}</p>
                </div>

                <div>
                  <div className="divider" />
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("propertyCharacteristics")}
                  </p>
                </div>

                <div className="rowWrapper justifySB">
                  <div className="flex flex-col">
                    <p>
                      - {t(accAg.accomodation.type.slug)}{" "}
                      {accAg.accomodation.buildingDate
                        ? t("of") +
                          " " +
                          accAg.accomodation.buildingDate +
                          " - "
                        : " - "}
                      {accAg.accomodation.buildUpArea} m²
                    </p>

                    <p>
                      - {t("field")} {accAg.accomodation.totalLandArea} m²{" "}
                      {accAg.accomodation.notSureTotalLandArea &&
                        `(${t("toCheck")})`}
                    </p>
                    {accAg.accomodation.doubleGlazing && (
                      <p>- {t("doubleGlazing")}</p>
                    )}

                    {accAg.accomodation.propertyTax > 0 && (
                      <p>
                        - {t("propertyTaxDisplay")} :{" "}
                        {accAg.accomodation.propertyTax} €
                      </p>
                    )}
                    {accAg.accomodation.annualFees > 0 && (
                      <p>
                        - {t("annualFees")} : {accAg.accomodation.annualFees} €
                      </p>
                    )}

                    {accAg.accomodation.tenYearGuarantee && (
                      <p>- {t("tenYearGuarantee")}</p>
                    )}

                    {accAg.accomodation.propertyCasualityInsurance && (
                      <p>- {t("propertyInsurance")}</p>
                    )}

                    {accAg.accomodation.coOwned && <p>- {t("coOwned")}</p>}

                    {accAg.accomodation.expansion && (
                      <p>- {t("expansionPotential")}</p>
                    )}

                    {accAg.accomodation.quiet && <p>- {t("quiet")}</p>}

                    {accAg.accomodation.noOpposite && (
                      <p>- {t("noOpposite")}</p>
                    )}

                    {accAg.accomodation.trainStationWalk && (
                      <p>- {t("trainStationWalk")}</p>
                    )}

                    {accAg.accomodation.forestAccessOnFoot && (
                      <p>- {t("forestAccessOnFoot")}</p>
                    )}

                    {accAg.accomodation.singleStorey && (
                      <p>- {t("singleStorey")}</p>
                    )}

                    {accAg.accomodation.sewageConnection && (
                      <p>- {t("sewageConnection")}</p>
                    )}

                    {accAg.accomodation.balcony && <p>- {t("balcony")}</p>}

                    {accAg.accomodation.basement && <p>- {t("basement")}</p>}

                    {accAg.accomodation.parkingPlaces && (
                      <p>- {t("parkingPlaces")}</p>
                    )}

                    {accAg.accomodation.terrace && <p>- {t("terrace")}</p>}

                    {accAg.accomodation.typeHeat && (
                      <p>
                        - {t("energyType")} :{" "}
                        {t(
                          TypeHeat[
                            accAg.accomodation
                              .typeHeat as unknown as keyof typeof TypeHeat
                          ]
                        )}
                      </p>
                    )}
                    {accAg.accomodation.secondTypeHeat && (
                      <p>
                        - {t("secondTypeEnergy")} :{" "}
                        {t(
                          TypeHeat[
                            accAg.accomodation
                              .secondTypeHeat as unknown as keyof typeof TypeHeat
                          ]
                        )}
                      </p>
                    )}

                    {accAg.accomodation.condition && (
                      <p>
                        -{" "}
                        {t(
                          ConditionType[
                            accAg.accomodation
                              .condition as unknown as keyof typeof ConditionType
                          ]
                        )}
                      </p>
                    )}

                    {accAg.accomodation.ges && (
                      <p>
                        -{" "}
                        {`${t("greenhouseGase")} : ${
                          EnergyClass[
                            accAg.accomodation
                              .ges as unknown as keyof typeof EnergyClass
                          ]
                        }`}
                      </p>
                    )}

                    {accAg.accomodation.energyClass && (
                      <p>
                        -{" "}
                        {`${t("energyDiagnosis")} : ${
                          EnergyClass[
                            accAg.accomodation
                              .energyClass as unknown as keyof typeof EnergyClass
                          ]
                        }`}
                      </p>
                    )}

                    {accAg.accomodation.commonWalls && (
                      <p>
                        - {accAg.accomodation.numberCommonWalls}{" "}
                        {t("commonWalls")}
                      </p>
                    )}

                    {accAg.accomodation.garage && (
                      <p>
                        - {accAg.accomodation.numberGarage} {t("garage")}
                      </p>
                    )}

                    {!!accAg.accomodation.belongsToSci && (
                      <p>
                        - {t("relativeSociety")}{" "}
                        {accAg.accomodation.belongsToSci.name}
                      </p>
                    )}

                    {accAg.accomodation.isRented && (
                      <>
                        <p>- {t("currentlyRentedTo")} :</p>
                        <p
                          className="ml-6 my-2"
                          dangerouslySetInnerHTML={{
                            __html: accAg.accomodation.rentersContactInformation
                          }}
                        />
                      </>
                    )}

                    {!!accAg.accomodation.dependenciesArea && (
                      <p>
                        -{" "}
                        {`${t("dependency")}
                          ${accAg.accomodation.dependenciesArea}
                        m2 - ${accAg.accomodation.dependenciesDescription}`}
                      </p>
                    )}

                    {accAg.accomodation.shopsWalkingDistance && (
                      <p>- {t("shopsWalkingDistance")}</p>
                    )}

                    {accAg.accomodation.boilerLess10Y && (
                      <p>- {t("boilerLess10Y")}</p>
                    )}
                  </div>
                </div>

                {accAg.accomodation.accomodationRooms.length > 0 && (
                  <div>
                    <div className="divider" />
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                      {t("interiorDesign")}
                    </p>

                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                      {accAg.accomodation.accomodationRooms.map((accR) => (
                        <AccomodationManagement
                          key={accR.id}
                          name={`${accR.quantity} ${
                            accR.label
                              ? accR.label
                              : t(accR.room!.slug, {
                                  count: accR.quantity
                                })
                          }`}
                          icon={
                            accR.room && accR.room.icon
                              ? fetchFromImgProxy(accR.room.icon, {
                                  format: "webp",
                                  width: 40
                                })
                              : "fa-solid fa-house"
                          }
                          haveIcon={!!accR.room && !!accR.room.icon}
                        />
                      ))}
                    </div>
                  </div>
                )}

                <div>
                  <div className="divider" />
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("outdoorDesign")}
                  </p>

                  <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                    {accAg.accomodation.forest && (
                      <AccomodationManagement
                        name={t("forest")}
                        icon={"fa-solid fa-apple-whole"}
                      />
                    )}{" "}
                    {accAg.accomodation.wood && (
                      <AccomodationManagement
                        name={t("wood")}
                        icon={"fa-solid fa-tree"}
                      />
                    )}
                    {accAg.accomodation.landscapedGarden && (
                      <AccomodationManagement
                        name={t("landscapedGarden")}
                        icon={"fa-solid fa-seedling"}
                      />
                    )}
                    {accAg.accomodation.unobstructedView && (
                      <AccomodationManagement
                        name={t("unobstructedView")}
                        icon={"fa-solid fa-panorama"}
                      />
                    )}
                    {accAg.accomodation.exceptionalView && (
                      <AccomodationManagement
                        name={t("exceptionalView")}
                        icon={"fa-solid fa-mountain-sun"}
                      />
                    )}
                    {accAg.accomodation.vegetableGarden && (
                      <AccomodationManagement
                        name={t("vegetableGarden")}
                        icon={"fa-solid fa-carrot"}
                      />
                    )}
                    {accAg.accomodation.tennis && (
                      <AccomodationManagement
                        name={t("tennis")}
                        icon={"fa-solid fa-table-tennis-paddle-ball"}
                      />
                    )}
                    {accAg.accomodation.pool && (
                      <AccomodationManagement
                        name={`${t("pool")}
                          ${
                            accAg.accomodation.poolHeating ? t("heated") : ""
                          } ${accAg.accomodation.poolSize}`}
                        icon={"fa-solid fa-water-ladder"}
                      />
                    )}
                  </div>
                </div>

                {accAg.accomodation.facing && (
                  <div>
                    <div className="divider" />
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                      {t("propertyFacing")}
                    </p>

                    <div className="flex justify-start items-center">
                      <i className="fa-solid fa-sun iconFont"></i>
                      <p>
                        {t(
                          FacingType[
                            accAg.accomodation
                              .facing as unknown as keyof typeof FacingType
                          ]
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="divider" />
                <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                  {upperFirst(t("photo_plural"))}
                </p>

                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                  {accAg.accomodation.photos.map((url, i) => (
                    <img
                      key={url.id}
                      src={
                        url
                          ? fetchFromImgProxy(url.url, {
                              format: "webp",
                              width: 200
                            })
                          : imageNotAvailable
                      }
                      className="accomodationPicture w-full h-24 cursor-pointer"
                      alt="Erreur de chargement"
                      onClick={() => handleDisplayPicture(i)}
                    />
                  ))}
                </div>
                {accAg.accomodation.videos.length > 0 && (
                  <>
                    <div className="divider" />

                    <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                      {upperFirst(t("video_plural"))}
                    </p>

                    <div className="accomodationManagementWrapper">
                      {accAg.accomodation.videos.map((url) => (
                        <ReactPlayer
                          key={url.id}
                          url={url.url}
                          controls={true}
                          muted={true}
                          playsinline={true}
                          width="320px"
                          height="180px"
                          className="accomodationVideos"
                        />
                      ))}
                    </div>
                  </>
                )}

                {accAg.accomodation.plans.length > 0 && (
                  <>
                    <div className="divider"></div>
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue ">
                      {upperFirst(t("plan_plural"))}
                    </p>

                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                      {accAg.accomodation.plans.map((url, i) => (
                        <img
                          key={url.id}
                          src={
                            url
                              ? fetchFromImgProxy(url.url, {
                                  format: "webp",
                                  width: 400
                                })
                              : imageNotAvailable
                          }
                          className="accomodationPicture w-full h-24"
                          alt="Erreur de chargement"
                          onClick={() => handleDisplayPlan(i)}
                        />
                      ))}
                    </div>
                  </>
                )}

                <div className="my-6">
                  <div className="divider" />
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("propertyAddress")}
                  </p>
                  <p className="mb-6">
                    {getCityFromAddress(accAg.accomodation)}
                  </p>
                  <div className="accomodationMap">
                    <div
                      ref={mapContainer}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <FsLightbox
              sourceIndex={imgIndex}
              toggler={togglerPicture}
              sources={accAg.accomodation.photos.map((m) =>
                fetchFromImgProxy(m.url, {
                  format: "webp",
                  width: 1000
                })
              )}
            />
            <FsLightbox
              sourceIndex={imgIndex}
              toggler={togglerPlan}
              sources={accAg.accomodation.plans.map((m) =>
                fetchFromImgProxy(m.url, {
                  format: "webp",
                  width: 1000
                })
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
});
