import {
  action,
  computed,
  makeAutoObservable,
  observable,
  runInAction
} from "mobx";

export default class LoaderStore {
  @observable
  count: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  @action
  startLoading() {
    runInAction(() => {
      this.count += 1;
    });
  }

  @action
  stopLoading() {
    runInAction(() => {
      if (this.count > 0) this.count -= 1;
      if (this.count === 0 || this.count < 0) this.count = 0;
    });
  }

  @computed
  get loading() {
    return this.count > 0;
  }
}
