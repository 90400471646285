import {
  AccomodationRoom,
  accomodationRoomFactory,
  IAccomodationRoom
} from "./AccomodationRoom";
import {
  AccomodationType,
  accomodationTypeFactory,
  IAccomodationType
} from "./AccomodationType";
import { MediaAttributes } from "./Media";
import { TimeSlotShape } from "./TimeSlot";
import { IUser, User, userFactory } from "./User";
import { IUserSCI, UserSCI, userSCIFactory } from "./UserSci";

export interface AddressFound {
  coordinates: number[];
  address: string;
}

export enum EnergyClass {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
  E = "E",
  F = "F",
  G = "G",
  "N/A" = "N/A"
}

export enum FacingType {
  N = "north",
  NE = "northEast",
  E = "east",
  SE = "southEast",
  S = "south",
  SW = "southWest",
  W = "west",
  NW = "northWest"
}

export enum IndividualSanitation {
  COMPLIANT = "compliant",
  NON_COMPLIANT = "nonCompliant"
}

export enum ConditionType {
  PERFECT = "perfectCondition",
  REFRESHMENTS = "refreshments",
  RENOVATION = "renovation"
}

export enum TypeHeat {
  FUEL_OIL = "fuelOil",
  GAS = "gas",
  TOWN_GAS = "townGas",
  ELECTRICITY = "electricity",
  HEAT_PUMP = "heatPump",
  GEOTHERMAL_ENERGY = "geothermalEnergy",
  WOOD_STOVE = "woodStove",
  PELLET_STOVE = "pelletStove",
  WOOD_PELLET_BOILER = "woodPelletBoiler",
  OTHERS = "others",
  COLLECTIVE_HEATING = "collectiveHeating"
}

export enum DoubleGlazing {
  PARTIAL = "partialDoubleGlazing",
  RT_2012 = "RT_2012",
  RT_2020 = "RT_2020",
  "N/A" = "N/A"
}

export enum Insulation {
  NON_EXISTANT = "nonExistant",
  LOW = "low",
  AVERAGE = "average",
  EXCELLENT = "excellent",
  "N/A" = "N/A"
}

export interface MediaReturn {
  id: string;
  url: string;
}

export interface AccomodationDTO {
  type: number;
  address: string;
  floor: number;
  buildUpArea: number;
  totalLandArea: number;
  notSureTotalLandArea: boolean;
  dependenciesArea: number;
  dependenciesDescription: string;
  buildingDate: number;
  energyClass: string | null;
  ges: string | null;
  notRealised: boolean;
  condition: string | null;
  conditionDetails: string;
  majorWork: boolean;
  tenYearGuarantee: boolean;
  propertyCasualityInsurance: boolean;
  propertyTax: number;
  facing: string | null;
  commonWalls: boolean;
  numberCommonWalls: string;
  garage: boolean;
  numberGarage: string;
  coOwned: boolean;
  annualFees: number;
  expansion: boolean;
  exceptionalView: boolean;
  landscapedGarden: boolean;
  pool: boolean;
  poolSize: string;
  poolHeating: boolean;
  tennis: boolean;
  quiet: boolean;
  noOpposite: boolean;
  trainStationWalk: boolean;
  forestAccessOnFoot: boolean;
  shopsWalkingDistance: boolean;
  singleStorey: boolean;
  prm: boolean;
  typeHeat: string | null;
  secondTypeHeat: string | null;
  othersTypeHeat: string;
  boilerLess10Y: boolean;
  doubleGlazing: DoubleGlazing;
  insulation: Insulation;
  sewageConnection: boolean;
  balcony: boolean;
  individualSanitation: string | null;
  convertibleAttic: boolean;
  spa: boolean;
  waterRoom: boolean;
  gym: boolean;
  petanque: boolean;
  summerKitchen: boolean;
  barbecue: boolean;
  wineCellar: boolean;
  gardenShed: boolean;
  workshop: boolean;
  greenhouse: boolean;
  poolHouse: boolean;
  bikeStorage: boolean;
  carport: boolean;
  horseStall: boolean;
  river: boolean;
  well: boolean;
  horseStable: boolean;
  bonfire: boolean;
  terrace: boolean;
  basement: boolean;
  parkingPlaces: boolean;
  description: string;
  photos: number[];
  videos: number[];
  plans: number[];
  price: number;
  owner: number;
  firstLastName?: string;
  email?: string;
  forest: boolean;
  vegetableGarden: boolean;
  wood: boolean;
  unobstructedView: boolean;
}

export interface IAccomodation {
  id: string;
  attributes: {
    accomodationRooms: { data: IAccomodationRoom[] | [] };
    address: string;
    balcony: boolean;
    individualSanitation: IndividualSanitation;
    convertibleAttic: boolean;
    spa: boolean;
    waterRoom: boolean;
    gym: boolean;
    petanque: boolean;
    summerKitchen: boolean;
    barbecue: boolean;
    wineCellar: boolean;
    gardenShed: boolean;
    workshop: boolean;
    greenhouse: boolean;
    poolHouse: boolean;
    bikeStorage: boolean;
    carport: boolean;
    horseStall: boolean;
    river: boolean;
    well: boolean;
    horseStable: boolean;
    bonfire: boolean;
    terrace: boolean;
    basement: boolean;
    boilerLess10Y: boolean;
    buildUpArea: number;
    buildingDate: number;
    coOwned: boolean;
    annualFees: number;
    commonWalls: boolean;
    numberCommonWalls: string;
    garage: boolean;
    numberGarage: string;
    condition: ConditionType;
    conditionDetails: string;
    dependenciesArea: number;
    dependenciesDescription: string;
    doubleGlazing: DoubleGlazing;
    insulation: Insulation;
    energyClass: EnergyClass;
    exceptionalView: boolean;
    expansion: boolean;
    facing: FacingType;
    floor: number;
    forestAccessOnFoot: boolean;
    shopsWalkingDistance: boolean;
    ges: EnergyClass;
    landscapedGarden: boolean;
    majorWork: boolean;
    noOpposite: boolean;
    notRealised: boolean;
    notSureTotalLandArea: boolean;
    othersTypeHeat: string;
    parkingPlaces: boolean;
    pool: boolean;
    poolSize: string;
    poolHeating: boolean;
    price: number;
    prm: boolean;
    propertyCasualityInsurance: boolean;
    propertyTax: number;
    quiet: boolean;
    sewageConnection: boolean;
    singleStorey: boolean;
    tenYearGuarantee: boolean;
    tennis: boolean;
    totalLandArea: number;
    trainStationWalk: boolean;
    type: { data: IAccomodationType };
    typeHeat: TypeHeat;
    secondTypeHeat: TypeHeat;
    description: string;
    photos: {
      data: MediaAttributes[];
    };
    videos: {
      data: MediaAttributes[];
    };
    plans: {
      data: MediaAttributes[];
    };
    visits?: {
      data: [
        {
          id: string;
          attributes: {
            day: string;
            timeslot: string;
          };
        }
      ];
    };
    beWarned: string;
    propertyProof: {
      data: MediaAttributes[];
    };
    firstLastName: string;
    email: string;
    forest: boolean;
    vegetableGarden: boolean;
    wood: boolean;
    unobstructedView: boolean;
    owner: {
      data?: IUser;
    };
    belongsToSci: {
      data: IUserSCI;
    };
    hasNotaire: boolean;
    notaireName: string;
    notaireAdress: string;
    isRented: boolean;
    rentersContactInformation: string;
  };
}

export class Accomodation {
  public id: string;

  public accomodationRooms: AccomodationRoom[];

  public address: AddressFound;

  public balcony: boolean;

  public individualSanitation: IndividualSanitation;

  public convertibleAttic: boolean;

  public spa: boolean;

  public waterRoom: boolean;

  public gym: boolean;

  public petanque: boolean;

  public summerKitchen: boolean;

  public barbecue: boolean;

  public wineCellar: boolean;

  public gardenShed: boolean;

  public workshop: boolean;

  public greenhouse: boolean;

  public poolHouse: boolean;

  public bikeStorage: boolean;

  public carport: boolean;

  public horseStall: boolean;

  public river: boolean;

  public well: boolean;

  public horseStable: boolean;

  public bonfire: boolean;

  public terrace: boolean;

  public basement: boolean;

  public boilerLess10Y: boolean;

  public buildUpArea: number;

  public buildingDate: number;

  public coOwned: boolean;

  public annualFees: number;

  public commonWalls: boolean;

  public numberCommonWalls: string;

  public garage: boolean;

  public numberGarage: string;

  public condition: ConditionType;

  public conditionDetails: string;

  public dependenciesArea: number;

  public dependenciesDescription: string;

  public doubleGlazing: DoubleGlazing;

  public insulation: Insulation;

  public energyClass: EnergyClass;

  public exceptionalView: boolean;

  public expansion: boolean;

  public facing: FacingType;

  public floor: number;

  public forestAccessOnFoot: boolean;

  public shopsWalkingDistance: boolean;

  public ges: EnergyClass;

  public landscapedGarden: boolean;

  public majorWork: boolean;

  public noOpposite: boolean;

  public notRealised: boolean;

  public notSureTotalLandArea: boolean;

  public othersTypeHeat: string;

  public parkingPlaces: boolean;

  public description: string;

  public pool: boolean;

  public poolSize: string;

  public poolHeating: boolean;

  public price: number;

  public prm: boolean;

  public propertyCasualityInsurance: boolean;

  public propertyTax: number;

  public quiet: boolean;

  public sewageConnection: boolean;

  public singleStorey: boolean;

  public tenYearGuarantee: boolean;

  public tennis: boolean;

  public totalLandArea: number;

  public trainStationWalk: boolean;

  public type: AccomodationType;

  public typeHeat: TypeHeat;

  public secondTypeHeat: TypeHeat;

  public photos: MediaReturn[];

  public videos: MediaReturn[];

  public plans: MediaReturn[];

  public visits: TimeSlotShape[];

  public beWarned: string;

  public propertyProof: MediaReturn[];

  public firstLastName: string;

  public email: string;

  public forest: boolean;

  public vegetableGarden: boolean;

  public wood: boolean;

  public unobstructedView: boolean;

  public owner?: User;

  public belongsToSci: UserSCI | null;

  public hasNotaire: boolean;

  public notaireName: string;

  public notaireAdress: string;

  public isRented: boolean;

  public rentersContactInformation: string;

  constructor(accFromFetch: IAccomodation) {
    this.id = accFromFetch.id;
    this.accomodationRooms = accFromFetch.attributes.accomodationRooms.data.map(
      (r) => accomodationRoomFactory(r)
    );
    this.address = JSON.parse(accFromFetch.attributes.address);
    this.balcony = accFromFetch.attributes.balcony;
    this.individualSanitation = accFromFetch.attributes.individualSanitation;
    this.convertibleAttic = accFromFetch.attributes.convertibleAttic;
    this.spa = accFromFetch.attributes.spa;
    this.waterRoom = accFromFetch.attributes.waterRoom;
    this.gym = accFromFetch.attributes.gym;
    this.petanque = accFromFetch.attributes.petanque;
    this.summerKitchen = accFromFetch.attributes.summerKitchen;
    this.barbecue = accFromFetch.attributes.barbecue;
    this.wineCellar = accFromFetch.attributes.wineCellar;
    this.gardenShed = accFromFetch.attributes.gardenShed;
    this.workshop = accFromFetch.attributes.workshop;
    this.greenhouse = accFromFetch.attributes.greenhouse;
    this.poolHouse = accFromFetch.attributes.poolHouse;
    this.bikeStorage = accFromFetch.attributes.bikeStorage;
    this.carport = accFromFetch.attributes.carport;
    this.horseStall = accFromFetch.attributes.horseStall;
    this.river = accFromFetch.attributes.river;
    this.well = accFromFetch.attributes.well;
    this.horseStable = accFromFetch.attributes.horseStable;
    this.bonfire = accFromFetch.attributes.bonfire;
    this.terrace = accFromFetch.attributes.terrace;
    this.basement = accFromFetch.attributes.basement;
    this.boilerLess10Y = accFromFetch.attributes.boilerLess10Y;
    this.buildUpArea = accFromFetch.attributes.buildUpArea;
    this.buildingDate = accFromFetch.attributes.buildingDate;
    this.coOwned = accFromFetch.attributes.coOwned;
    this.annualFees = accFromFetch.attributes.annualFees;
    this.commonWalls = accFromFetch.attributes.commonWalls;
    this.numberCommonWalls = accFromFetch.attributes.numberCommonWalls;
    this.garage = accFromFetch.attributes.garage;
    this.numberGarage = accFromFetch.attributes.numberGarage;
    this.condition = accFromFetch.attributes.condition;
    this.conditionDetails = accFromFetch.attributes.conditionDetails;
    this.dependenciesArea = accFromFetch.attributes.dependenciesArea;
    this.dependenciesDescription =
      accFromFetch.attributes.dependenciesDescription;
    this.doubleGlazing = accFromFetch.attributes.doubleGlazing;
    this.insulation = accFromFetch.attributes.insulation;
    this.energyClass = accFromFetch.attributes.energyClass;
    this.exceptionalView = accFromFetch.attributes.exceptionalView;
    this.expansion = accFromFetch.attributes.expansion;
    this.facing = accFromFetch.attributes.facing;
    this.floor = accFromFetch.attributes.floor;
    this.forestAccessOnFoot = accFromFetch.attributes.forestAccessOnFoot;
    this.shopsWalkingDistance = accFromFetch.attributes.shopsWalkingDistance;
    this.ges = accFromFetch.attributes.ges;
    this.landscapedGarden = accFromFetch.attributes.landscapedGarden;
    this.majorWork = accFromFetch.attributes.majorWork;
    this.noOpposite = accFromFetch.attributes.noOpposite;
    this.notRealised = accFromFetch.attributes.notRealised;
    this.notSureTotalLandArea = accFromFetch.attributes.notSureTotalLandArea;
    this.othersTypeHeat = accFromFetch.attributes.othersTypeHeat;
    this.parkingPlaces = accFromFetch.attributes.parkingPlaces;
    this.description = accFromFetch.attributes.description;
    this.pool = accFromFetch.attributes.pool;
    this.poolSize = accFromFetch.attributes.poolSize;
    this.poolHeating = accFromFetch.attributes.poolHeating;
    this.price = accFromFetch.attributes.price;
    this.prm = accFromFetch.attributes.prm;
    this.propertyCasualityInsurance =
      accFromFetch.attributes.propertyCasualityInsurance;
    this.propertyTax = accFromFetch.attributes.propertyTax;
    this.quiet = accFromFetch.attributes.quiet;
    this.sewageConnection = accFromFetch.attributes.sewageConnection;
    this.singleStorey = accFromFetch.attributes.singleStorey;
    this.tenYearGuarantee = accFromFetch.attributes.tenYearGuarantee;
    this.tennis = accFromFetch.attributes.tennis;
    this.totalLandArea = accFromFetch.attributes.totalLandArea;
    this.trainStationWalk = accFromFetch.attributes.trainStationWalk;
    this.type = accomodationTypeFactory(accFromFetch.attributes.type.data);
    this.typeHeat = accFromFetch.attributes.typeHeat;
    this.secondTypeHeat = accFromFetch.attributes.secondTypeHeat;
    this.photos = accFromFetch.attributes.photos.data.map((itm) => {
      const photo: MediaReturn = {
        url: itm.attributes.url,
        id: itm.id
      };
      return photo;
    });
    this.videos = accFromFetch.attributes.videos.data.map((itm) => {
      const video: MediaReturn = {
        url: itm.attributes.url.replace("http://", "https://"),
        id: itm.id
      };
      return video;
    });
    this.plans = accFromFetch.attributes.plans.data.map((itm) => {
      const plan: MediaReturn = {
        url: itm.attributes.url,
        id: itm.id
      };
      return plan;
    });
    this.visits = accFromFetch.attributes.visits
      ? accFromFetch.attributes.visits.data.map((visit) => {
          const shape: TimeSlotShape = {
            id: visit.id,
            day: visit.attributes.day,
            timeslot: JSON.parse(visit.attributes.timeslot)
          };

          return shape;
        })
      : [];
    this.beWarned = accFromFetch.attributes.beWarned;
    this.propertyProof = accFromFetch.attributes.propertyProof.data.map(
      (itm) => {
        const proof: MediaReturn = {
          url: itm.attributes.url,
          id: itm.id
        };
        return proof;
      }
    );
    this.firstLastName = accFromFetch.attributes.firstLastName;
    this.email = accFromFetch.attributes.email;
    this.forest = accFromFetch.attributes.forest;
    this.vegetableGarden = accFromFetch.attributes.vegetableGarden;
    this.wood = accFromFetch.attributes.wood;
    this.unobstructedView = accFromFetch.attributes.unobstructedView;
    this.owner = accFromFetch.attributes.owner
      ? userFactory(accFromFetch.attributes.owner.data!)
      : undefined;
    this.hasNotaire = accFromFetch.attributes.hasNotaire;
    this.notaireName = accFromFetch.attributes.notaireName;
    this.notaireAdress = accFromFetch.attributes.notaireAdress;
    this.isRented = accFromFetch.attributes.isRented;
    this.rentersContactInformation =
      accFromFetch.attributes.rentersContactInformation;
    this.belongsToSci =
      accFromFetch.attributes.belongsToSci &&
      accFromFetch.attributes.belongsToSci.data
        ? userSCIFactory(accFromFetch.attributes.belongsToSci?.data)
        : null;
  }
}

export const accomodationFactory = (data: IAccomodation): Accomodation => {
  return new Accomodation(data);
};
