import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { RESET_PASSWORD } from "../lib/gql/Passwords";
import { useLoaderStore } from "../lib/store";
import { ResetFormScheme, ResetFormValues } from "../lib/shape/FogotForm";
import logoUser from "../assets/images/nodimo.png";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const PasswordResetScreen = observer(() => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const query = useQuery();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const loader = useLoaderStore();

  const [mutateFunction, { loading }] = useMutation(RESET_PASSWORD, {
    onCompleted: () => {
      setSuccess(true);
      loader.stopLoading();
    },
    onError: (e) => {
      setError(e.message);
      loader.stopLoading();
    }
  });

  useEffect(() => {
    if (loading) {
      loader.startLoading();
    }
  }, [loader, loading]);

  return (
    <div className="w-full flex justify-center items-center relative">
      <div className="absolute left-0 top-0 mt-4 ml-4">
        <button
          className="rounded-lg bg-white shadow-lg text-black"
          onClick={() => navigate("/auth/login")}
        >
          <i className="fa-solid fa-chevron-left"></i>
          <span className="ml-2">{t("connect")}</span>
        </button>
      </div>
      <div className="flex justify-center items-center w-10/12 xl:max-w-md">
        <div className="bg-white px-10 pt-10 pb-4 rounded-md shadow-lg">
          <div className="flex justify-between items-center mb-10">
            <div className="basis-1/3 flex justify-center items-center">
              <img className="w-1/2" src={logoUser} alt="" />
            </div>
            <div className="basis-2/3 flex flex-col ">
              <h2 className="font-bold">{t("newPassword")}</h2>
              <p>{t("forYourAccount")} </p>
            </div>
          </div>

          {error && (
            <div className="bg-red-300 py-4 px-6 rounded mb-4 flex justify-between items-center">
              <i className="fa-solid fa-circle-exclamation text-red-900 pr-4 text-2xl"></i>
              <p className="font-bold text-red-900 text-sm">{t(error)}</p>
            </div>
          )}

          {success && (
            <div className="bg-green-300 py-4 px-6 rounded mb-4 flex justify-between items-center">
              <i className="fa-solid fa-circle-check text-green-900 pr-4 text-2xl"></i>
              <p className="font-bold text-green-900 text-sm">
                {t("resetSuccess")}
              </p>
            </div>
          )}

          <Formik
            initialValues={{ password: "", passwordConfirmation: "" }}
            validationSchema={ResetFormScheme}
            onSubmit={(values: ResetFormValues, { setSubmitting }) => {
              setError("");
              mutateFunction({
                variables: Object.assign(values, {
                  code: query.get("code")
                })
              });
              setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              initialErrors,
              dirty
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="formField">
                  <Field
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Mot de passe"
                  />
                  <span className="field-required">
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>

                <div className="formField">
                  <Field
                    type="password"
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    placeholder="Confirmation mot de passe"
                  />
                  <span className="field-required">
                    {errors.passwordConfirmation &&
                      touched.passwordConfirmation &&
                      errors.passwordConfirmation}
                  </span>
                </div>

                <div className="formField">
                  <button
                    className="rounded-full w-full bg-nodimoblue"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !!initialErrors.password ||
                      !!initialErrors.passwordConfirmation ||
                      !dirty
                    }
                  >
                    {t("confirm")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
