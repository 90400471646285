export interface IUserSCI {
  id: string;
  attributes: {
    name: string;
    siret: string;
    address: string;
    postalCode: string;
    city: string;
    form: string;
    legalRepresentative: string;
    email: string;
  };
}

export class UserSCI {
  public id: string;

  public name: string;

  public siret: string;

  public address: string;

  public postalCode: string;

  public city: string;

  public form: string;

  public legalRepresentative: string;

  public email: string;

  constructor(userSCIFromFetch: IUserSCI) {
    this.id = userSCIFromFetch.id;
    this.name = userSCIFromFetch.attributes.name;
    this.siret = userSCIFromFetch.attributes.siret;
    this.address = userSCIFromFetch.attributes.address;
    this.postalCode = userSCIFromFetch.attributes.postalCode;
    this.city = userSCIFromFetch.attributes.city;
    this.form = userSCIFromFetch.attributes.form;
    this.legalRepresentative = userSCIFromFetch.attributes.legalRepresentative;
    this.email = userSCIFromFetch.attributes.email;
  }
}

export const userSCIFactory = (data: IUserSCI): UserSCI => {
  return new UserSCI(data);
};
