import React, { useEffect, useState } from "react";
import { QueryResult, useLazyQuery, useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { LOGIN_MUTATION } from "../lib/gql/Auth";
import { GET_ME } from "../lib/gql/Me";
import { UserModel } from "../lib/models/User";
import {
  useAgencyStore,
  useAuthStore,
  useLoaderStore,
  useUserStore
} from "../lib/store";
import { LoginFormScheme, LoginFormValues } from "../lib/shape/LoginForm";
import logoUser from "../assets/images/nodimo.png";
import { GET_AGENCY_FROM_USER } from "../lib/gql/Agency";
import { toast } from "react-toastify";
import { agencyFactory } from "../lib/models/Agency";
import { observer } from "mobx-react-lite";

export const LoginScreen = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loader = useLoaderStore();
  const auth = useAuthStore();
  const user = useUserStore();
  const agency = useAgencyStore();

  const [curUser, setCurUser] = useState<UserModel | null>(null);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [errorPass, setErrorPass] = useState(false);

  const [getAgency] = useLazyQuery(GET_AGENCY_FROM_USER, {
    fetchPolicy: "network-only",
    onCompleted: async ({
      usersPermissionsUser
    }: {
      usersPermissionsUser: QueryResult;
    }) => {
      user.setCurrentUser(curUser!);
      if (!usersPermissionsUser) {
        loader.stopLoading();
        return;
      }

      if (
        !usersPermissionsUser.data.attributes.agency.data &&
        curUser?.role?.name !== "Agency"
      ) {
        navigate("/auth/agency-creator");
        loader.stopLoading();
        return;
      }

      if (!usersPermissionsUser.data.attributes.agency.data) {
        toast.error(t("agencyLogError"), {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        return;
      }

      await agency.setAgency(
        agencyFactory(usersPermissionsUser.data.attributes.agency.data)
      );

      if (agency.agency) {
        toast.success(
          t("welcomeUser", {
            firstName: curUser!.firstName,
            lastName: curUser!.lastName
          }),
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true
          }
        );

        if (!agency.agency.honoraire || agency.agency?.honoraire.length === 0) {
          navigate("/auth/agency-fees");
          loader.stopLoading();
          return;
        }

        navigate("/dashboard");
      }

      loader.stopLoading();
    },
    onError: (e) => {
      console.log(e);
    }
  });

  const [meQuery] = useLazyQuery(GET_ME, {
    onCompleted: async ({ me }: { me: UserModel }) => {
      setCurUser(me);
      await getAgency({ variables: { id: me.id } });
    },
    onError: () => {
      loader.stopLoading();
    }
  });

  const [mutateFunction, { loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: async ({
      login
    }: {
      login: { jwt: string; user: UserModel };
    }) => {
      auth.login(login);
      await meQuery();
    },
    onError: () => {
      loader.stopLoading();
      setErrorPass(true);
    }
  });

  useEffect(() => {
    if (loading) {
      loader.startLoading();
    }
  }, [loader, loading]);

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex justify-center items-center w-10/12 xl:max-w-md">
        <div className="bg-white px-10 pt-10 pb-4 rounded-md shadow-lg">
          <div className="flex justify-between items-center mb-10">
            <div className="basis-1/3 flex justify-center items-center">
              <img className="w-1/2" src={logoUser} alt="" />
            </div>
            <div className="basis-2/3 flex flex-col ">
              <h2 className="font-bold">{t("login")}</h2>
              <p>{t("manageUserProperties")}</p>
            </div>
          </div>

          <Formik
            initialValues={{ email: "", password: "" }}
            validationSchema={LoginFormScheme}
            onSubmit={(values: LoginFormValues, { setSubmitting }) => {
              mutateFunction({ variables: values });
              values.password = "";
              setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              initialErrors,
              dirty
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="formField">
                  <Field
                    type="email"
                    name="email"
                    id="loginID"
                    placeholder="Identifiant"
                  />
                  <span className="field-required">
                    {errors.email && touched.email && errors.email}
                  </span>
                </div>
                <div className="formField">
                  <div className="formIcon">
                    <Field
                      type={showPass ? "text" : "password"}
                      name="password"
                      id="passwordID"
                      placeholder="Mot de passe"
                      autoComplete="password"
                    />
                    <div
                      className="passIcon"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <i
                        className={`fa-solid fa-eye${
                          !showPass ? "-slash" : ""
                        } text-xl`}
                      ></i>
                    </div>
                  </div>
                  <span className="field-required">
                    {errorPass
                      ? "Nom d'utilisateur ou mot de passe invalide."
                      : errors.password && touched.password && errors.password}
                  </span>
                </div>
                <div className="mt-4 mb-4 flex justify-center">
                  <Link to={"/auth/password-forgot"}>
                    {t("forgotPassword")} ?
                  </Link>
                </div>

                <div className="formField">
                  <button
                    className="rounded-full w-full bg-nodimoblue"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !!initialErrors.email ||
                      !!initialErrors.password ||
                      !dirty
                    }
                  >
                    {t("login")}
                  </button>
                </div>
                <div className="formField">
                  <button
                    type="button"
                    className="rounded-full w-full bg-nodimoblue"
                    onClick={() => navigate("/auth/register")}
                  >
                    {t("register")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
