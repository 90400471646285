import { action, observable, runInAction, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { Agency } from "../../models/Agency";
import RootStore from "../RootStore";

/**
 * AgencyStore used to manage Agency entity.
 */
export default class AgencyStore {
  /** Agency linked to current user connected */
  @observable
  agency: Agency | null = null;

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "AgencyStore",
      properties: ["agency"],
      storage: window.localStorage
    });
  }

  /** Used to set this.agency */
  @action
  async setAgency(newAgencies: Agency | null) {
    runInAction(() => {
      this.agency = newAgencies;
    });
  }

  /** Used to reset store value */
  @action
  async reset() {
    this.setAgency(null);
  }
}
