/**
 * TimeSlotWriterFunction properties
 */
type TimeSlotWriterFunction = (
  /** TimeSlot ["0800", "0900"], used to write hour */
  timeSlot: string[],
  /** Used to separate 2 hours 08h00-09h00, here is '-' */
  separator: string,
  /** Used to add 'h' at the end of the first hour */
  displayHour?: boolean
) => string;

/**
 * timeSlotWriter used to display timeSlot.
 */
export const timeSlotWriter: TimeSlotWriterFunction = (
  timeSlot,
  separator,
  displayHour = false
) => {
  return `${
    timeSlot[0].slice(2) === "00"
      ? `${timeSlot[0].slice(0, 2)}${displayHour ? "h" : ""}`
      : `${timeSlot[0].slice(0, 2)}h${timeSlot[0].slice(2)}`
  }${separator}${
    timeSlot[1].slice(2) === "00"
      ? `${timeSlot[1].slice(0, 2)}h`
      : `${timeSlot[1].slice(0, 2)}h${timeSlot[1].slice(2)}`
  }`;
};
