import React, { useEffect, useState } from "react";
import { QueryResult, useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import logoUser from "../assets/images/nodimo.png";
import placeholder from "../assets/images/placeholder.png";

import { CREATE_AGENCY } from "../lib/gql/Agency";
import { AgencyFormScheme, AgencyFormValues } from "../lib/shape/AgencyForm";
import {
  useAgencyStore,
  useAuthStore,
  useLoaderStore,
  useUserStore
} from "../lib/store";
import { AgencyDTO, agencyFactory } from "../lib/models/Agency";
import { UPLOAD_FILE } from "../lib/gql/Mandat";
import { formatPhone, makeLonLat } from "../lib/utils/utils";
import { UPDATE_ME } from "../lib/gql/Me";
import { userFactory } from "../lib/models";

export const AgencyCreatorScreen = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [tmpPicture, setTmpPicture] = useState<string | null>(null);

  const loader = useLoaderStore();
  const user = useUserStore();
  const auth = useAuthStore();
  const agency = useAgencyStore();

  const [uploadFile] = useMutation(UPLOAD_FILE, {
    onError: (e) => {
      console.log("e", e);
    }
  });

  const [updateCurrUser] = useMutation(UPDATE_ME, {
    onError: () => {
      loader.stopLoading();
    }
  });

  const [mutateFunction, { loading }] = useMutation(CREATE_AGENCY, {
    onCompleted: async ({ createAgency }: { createAgency: QueryResult }) => {
      loader.stopLoading();
      const me = await updateCurrUser({
        variables: {
          id: user.currentUser!.id,
          data: { role: 3 }
        }
      });

      user.setCurrentUser(userFactory(me.data.updateUsersPermissionsUser.data));
      if (!createAgency) {
        navigate("/auth/login");
      } else {
        agency.setAgency(agencyFactory(createAgency.data));
        navigate("/auth/agency-fees");
      }
    },
    onError: () => {
      loader.stopLoading();
    }
  });

  const handleCreateAgency = async (val: AgencyFormValues) => {
    const lonlat = await makeLonLat(val.address, val.postalCode, val.city);

    await uploadFile({ variables: { file: val.thumbnail } }).then((pic) => {
      const newAgency: AgencyDTO = {
        ...val,
        users: [user.currentUser?.id!],
        lonlat,
        phoneNumber: formatPhone(val.phoneNumber),
        thumbnail: pic.data.upload.data.id,
        searchVisible: false
      };

      mutateFunction({ variables: { data: newAgency } });
    });
  };

  useEffect(() => {
    if (loading) {
      loader.startLoading();
    }
  }, [loader, loading]);

  const handleLogout = () => {
    auth.logout();
    agency.reset();

    navigate("/");
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex justify-center items-center w-10/12 xl:w-8/12">
        <div className="bg-white px-10 pt-10 pb-4 rounded-md shadow-lg my-10">
          <div className="flex justify-between items-center mb-10">
            <div className="basis-1/3 flex justify-center items-center">
              <img className="w-1/2" src={logoUser} alt="" />
            </div>
            <div className="basis-2/3 flex flex-col ">
              <h2 className="font-bold">{t("creationAgency")}</h2>
              <p>{t("warnAgencyUser")}</p>
            </div>
          </div>
          <Formik
            initialValues={{
              name: "",
              address: "",
              postalCode: "",
              city: "",
              email: "",
              phoneNumber: "",
              siret: "",
              legalRepresentative: "",
              proCardNumber: "",
              deliveredByDPT: "",
              financialGuarantee: "",
              enseigne: "",
              honoraire: "",
              website: "",
              thumbnail: "",
              juridicForm: "",
              description: ""
            }}
            validationSchema={AgencyFormScheme}
            onSubmit={(values: AgencyFormValues, actions) => {
              handleCreateAgency(values);
              actions.setSubmitting(false);
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              initialErrors,
              setFieldValue
            }) => (
              <form onSubmit={handleSubmit} className="agencyForm">
                <div className="formField">
                  <label htmlFor="name">{t("agencyName")}</label>
                  <Field
                    type="text"
                    name="name"
                    id="nameID"
                    placeholder={t("agencyName")}
                  />
                  <span className="field-required">
                    {errors.name && touched.name && errors.name}
                  </span>
                </div>
                <div className="formField">
                  <label htmlFor="address">{t("address")}</label>
                  <Field
                    type="text"
                    name="address"
                    id="addressID"
                    placeholder={t("address")}
                  />
                  <span className="field-required">
                    {errors.address && touched.address && errors.address}
                  </span>
                </div>
                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="postalCode">{t("postalCode")}</label>
                    <Field
                      type="text"
                      name="postalCode"
                      id="postalCodeID"
                      placeholder={t("postalCode")}
                    />
                    <span className="field-required">
                      {errors.postalCode &&
                        touched.postalCode &&
                        errors.postalCode}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="city">{t("city")}</label>
                    <Field
                      type="text"
                      name="city"
                      id="cityID"
                      placeholder={t("city")}
                    />
                    <span className="field-required">
                      {errors.city && touched.city && errors.city}
                    </span>
                  </div>
                </div>
                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="email">{t("email")}</label>
                    <Field
                      type="text"
                      name="email"
                      id="emailID"
                      placeholder={t("email")}
                    />
                    <span className="field-required">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="phoneNumber">{t("phoneNumber")}</label>
                    <Field
                      type="text"
                      name="phoneNumber"
                      id="phoneNumberID"
                      placeholder={t("phoneNumber")}
                    />
                    <span className="field-required">
                      {errors.phoneNumber &&
                        touched.phoneNumber &&
                        errors.phoneNumber}
                    </span>
                  </div>
                </div>
                <div className="formField">
                  <label htmlFor="siret">{t("enterpriseSiret")}</label>
                  <Field
                    type="text"
                    name="siret"
                    id="siretID"
                    placeholder={t("enterpriseSiret")}
                  />
                  <span className="field-required">
                    {errors.siret && touched.siret && errors.siret}
                  </span>
                </div>
                <div className="formField">
                  <label htmlFor="description">{t("description")}</label>
                  <Field
                    type="text"
                    name="description"
                    id="descriptionID"
                    placeholder={t("description")}
                    component="textarea"
                    rows={4}
                  />
                  <span className="field-required">
                    {errors.description &&
                      touched.description &&
                      errors.description}
                  </span>
                </div>
                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="juridicForm">{t("juridicForm")}</label>
                    <Field
                      type="text"
                      name="juridicForm"
                      id="juridicFormID"
                      placeholder={t("juridicForm")}
                    />
                    <span className="field-required">
                      {errors.juridicForm &&
                        touched.juridicForm &&
                        errors.juridicForm}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="enseigne">{t("signLinkedToAgency")}</label>
                    <Field
                      type="text"
                      name="enseigne"
                      id="enseigneID"
                      placeholder={t("signLinkedToAgency")}
                    />
                    <span className="field-required">
                      {errors.enseigne && touched.enseigne && errors.enseigne}
                    </span>
                  </div>
                </div>

                <div className="formField">
                  <label htmlFor="legalRepresentative">
                    {t("legalRepresentative")}
                  </label>
                  <Field
                    type="text"
                    name="legalRepresentative"
                    id="legalRepresentativeID"
                    placeholder={t("legalRepresentative")}
                  />
                  <span className="field-required">
                    {errors.legalRepresentative &&
                      touched.legalRepresentative &&
                      errors.legalRepresentative}
                  </span>
                </div>

                <div className="halfWrapper">
                  <div className="formField">
                    <label htmlFor="proCardNumber">{t("proCardNumber")}</label>
                    <Field
                      type="text"
                      name="proCardNumber"
                      id="proCardNumberID"
                      placeholder={t("proCardNumber")}
                    />
                    <span className="field-required">
                      {errors.proCardNumber &&
                        touched.proCardNumber &&
                        errors.proCardNumber}
                    </span>
                  </div>
                  <div className="formField">
                    <label htmlFor="deliveredByDPT">
                      {t("deliveredByDPT")}
                    </label>
                    <Field
                      type="text"
                      name="deliveredByDPT"
                      id="deliveredByDPTID"
                      placeholder={t("deliveredByDPT")}
                    />
                    <span className="field-required">
                      {errors.deliveredByDPT &&
                        touched.deliveredByDPT &&
                        errors.deliveredByDPT}
                    </span>
                  </div>
                </div>
                <div className="formField">
                  <label htmlFor="financialGuarantee">
                    {t("financialGuarantee")}
                  </label>
                  <Field
                    type="text"
                    name="financialGuarantee"
                    id="financialGuaranteeID"
                    placeholder={t("financialGuarantee")}
                  />
                  <span className="field-required">
                    {errors.financialGuarantee &&
                      touched.financialGuarantee &&
                      errors.financialGuarantee}
                  </span>
                </div>
                <div className="formField">
                  <label htmlFor="website">{t("agencyWebsite")}</label>
                  <Field
                    type="text"
                    name="website"
                    id="websiteID"
                    placeholder={t("agencyWebsite")}
                  />
                  <span className="field-required">
                    {errors.website && touched.website && errors.website}
                  </span>
                </div>
                <div className="your-file">
                  <div className="formField">
                    <label className="mb-2" htmlFor="thumbnail">
                      {t("agencyLogo")}
                    </label>
                    <div className="your-file-preview">
                      <img src={tmpPicture ? tmpPicture : placeholder} alt="" />
                    </div>
                  </div>
                  <label className="button rounded-full bg-nodimoblue text-center">
                    {t("import")}
                  </label>
                  <input
                    id="thumbnailID"
                    name="thumbnail"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      setFieldValue("thumbnail", event.target.files![0]);
                      setTmpPicture(
                        URL.createObjectURL(event.target.files![0])
                      );
                    }}
                  />
                  <span className="field-required">
                    {errors.thumbnail && touched.thumbnail && errors.thumbnail}
                  </span>
                </div>

                <div className="formField">
                  <button
                    type="submit"
                    className="rounded-full w-full bg-nodimoblue"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !!initialErrors.name ||
                      !!initialErrors.address ||
                      !!initialErrors.postalCode ||
                      !!initialErrors.city ||
                      !!initialErrors.email ||
                      !!initialErrors.phoneNumber ||
                      !!initialErrors.siret ||
                      !!initialErrors.legalRepresentative ||
                      !!initialErrors.proCardNumber ||
                      !!initialErrors.deliveredByDPT ||
                      !!initialErrors.financialGuarantee ||
                      !!initialErrors.enseigne ||
                      !!initialErrors.juridicForm ||
                      !!initialErrors.website ||
                      !tmpPicture
                    }
                  >
                    {t("continueCreation")}
                  </button>
                </div>
                <div className="formField">
                  <button
                    className="rounded-full w-full bg-red-400"
                    onClick={handleLogout}
                  >
                    {t("logout")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
});
