import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { upperCase } from "lodash";

import { Header } from "../components/Header/Header";
import {
  useAccomodationStore,
  useAgencyStore,
  useAmendmentCreatorStore,
  useLoaderStore
} from "../lib/store";
import { formatPrice } from "../lib/utils/utils";
import { useMutation } from "@apollo/client";
import { UPDATE_AMENDMENT } from "../lib/gql/Amendment";
import { AccomodationAgency } from "../lib/models/AccomodationAgency";

export const AmendmentScreen = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const agencyStore = useAgencyStore();
  const accomodationStore = useAccomodationStore();
  const amendmentCreatorStore = useAmendmentCreatorStore();
  const loader = useLoaderStore();

  const [feeChange, setFeeChange] = useState<"NO" | "CHANGE">("NO");
  const [fees, setFees] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);

  const [updateAmendment] = useMutation(UPDATE_AMENDMENT, {
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("errored here", e);
      loader.stopLoading();
    }
  });

  const handleCreate = async () => {
    loader.startLoading();
    amendmentCreatorStore.setFees(fees);

    await updateAmendment({
      variables: {
        id: accomodationStore.selected?.amendments
          .slice()
          .sort((a, b) => Number(b.id) - Number(a.id))
          .find((am) => !am.completed)!.id,
        data: {
          fees
        }
      }
    });

    navigate("sign");
    loader.stopLoading();
  };

  const disabled: boolean =
    !checked ||
    (feeChange === "CHANGE" &&
      (fees.length === 0 ||
        !fees ||
        isNaN(Number(fees)) ||
        Number(fees) < 1 ||
        (accomodationStore.selected!.mandat!.customFees.length > 0 &&
          Number(fees) >=
            Number(accomodationStore.selected!.mandat!.customFees!))));

  const displayPrice = (acc: AccomodationAgency) => {
    const lastAmendmentCompleted = acc.amendments
      .slice()
      .sort((a, b) => Number(b.id) - Number(a.id))
      .find((amd) => amd.completed);

    return lastAmendmentCompleted
      ? formatPrice(lastAmendmentCompleted.price)
      : formatPrice(acc.accomodation.price);
  };

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />
      <div className="flex justify-center items-center w-full mt-12 mb-12">
        <div className="bg-white w-11/12 2xl:w-8/12 flex flex-col items-center justify-center p-10 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-nodimodarkblue m-0">
            {t("validAmendment")}
          </h2>
          <div className="formField">
            <p className="mb-2 my-4">
              {accomodationStore.selected!.accomodation.owner!.firstName}{" "}
              {upperCase(
                accomodationStore.selected!.accomodation.owner!.lastName
              )}{" "}
              {t("changePriceProperty")}{" "}
              {formatPrice(
                accomodationStore.selected?.amendments
                  .slice()
                  .sort((a, b) => Number(b.id) - Number(a.id))
                  .find((a) => !a.completed)!.price!
              )}{" "}
              {t("insteadOf")} {displayPrice(accomodationStore.selected!)}
            </p>

            <div className="my-4">
              <p>{t("onYourSide")} </p>
              <div className="flex flex-col">
                <label>
                  <input
                    type="radio"
                    checked={feeChange === "NO"}
                    onChange={() => setFeeChange("NO")}
                    value="NO"
                    name="gender"
                  />{" "}
                  {t("remunerationUnchanged")}
                </label>
                <label>
                  <input
                    type="radio"
                    checked={feeChange === "CHANGE"}
                    onChange={() => setFeeChange("CHANGE")}
                    value="CHANGE"
                    name="gender"
                  />{" "}
                  {t("remunerationModified")}
                </label>
              </div>
              {feeChange === "CHANGE" && (
                <div className="mt-2 flex flex-row items-center justify-between">
                  <p className="text-sm w-4/12 italic">{t("customisedFee")}</p>
                  <div className="w-3/12">
                    <input
                      type="text"
                      value={fees}
                      placeholder={
                        accomodationStore.selected?.mandat?.customFees || "10"
                      }
                      onChange={(e) => setFees(e.target.value)}
                      className={`feesInput ${
                        isNaN(Number(fees)) ||
                        (fees && Number(fees) < 1) ||
                        (accomodationStore.selected!.mandat!.customFees.length >
                          0 &&
                          Number(fees) >=
                            Number(
                              accomodationStore.selected!.mandat!.customFees!
                            ))
                          ? "errorField"
                          : null
                      }`}
                    />
                  </div>
                  <p className="text-sm w-4/12 ml-2 italic">
                    {Number(fees) > 99
                      ? t("commisionEuros")
                      : t("commisionPercent")}
                  </p>
                </div>
              )}
            </div>

            <div className="flex flex-col justify-center align-middle">
              <div className="flex flex-row mt-3 mb-3 align-middle justify-center">
                <label>
                  <input
                    className="mr-3"
                    type="checkbox"
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  {t("readAndApproveAmendment")}
                </label>
              </div>
              <button
                type="submit"
                className="w-full bg-nodimoblue mt-6"
                onClick={handleCreate}
                disabled={disabled}
              >
                {t("validAmendment")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
