import React, { FC } from "react";
import { upperCase } from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { timeSlotWriter } from "../../lib/utils/TimeSlot";
import { TimeSlotComponent } from "./TimeSlot.type";

/**
 * TimeSlot used to display calendar with selectable Time Slot.
 */
export const TimeSlot: FC<TimeSlotComponent> = ({
  timeSlot,
  current,
  handleChangeWeek,
  handleSelectTimeSlot,
  handleValidate
}) => {
  const { t } = useTranslation();

  return (
    <div className="timeSlotWrapper">
      <div className="timeSlotTitle">
        <div
          className="timeSlotSwitch"
          onClick={() => handleChangeWeek("remove")}
        >
          <p>{`<`}</p>
        </div>
        <p>
          {upperCase(moment(timeSlot[timeSlot.length - 1].date).format("MMMM"))}{" "}
          {moment(timeSlot[timeSlot.length - 1].date).format("YYYY")}
        </p>
        <div className="timeSlotSwitch" onClick={() => handleChangeWeek("add")}>
          <p>{`>`}</p>
        </div>
      </div>
      <div className="timeSlotList">
        {timeSlot.map((timeSlot) => (
          <div key={Math.random()} className="item w-18 2xl:w-24">
            <p className="day">
              {upperCase(moment(timeSlot.date).format("ddd"))}
            </p>
            <p className="date">
              {upperCase(moment(timeSlot.date).format("DD"))}
            </p>
            {timeSlot.timeslot.map((ts) => (
              <div
                key={Math.random()}
                className={`timeSlot w-full hover:bg-nodimodarkblue ${
                  current &&
                  current.date === timeSlot.date &&
                  current.timeSlot === ts &&
                  `current`
                }`}
                onClick={() => handleSelectTimeSlot(timeSlot.date, ts)}
              >
                <p>{timeSlotWriter(ts, "-")}</p>
              </div>
            ))}
          </div>
        ))}
      </div>
      <div
        className={`button w-full mt-4 ${!current && "disabled"}`}
        onClick={handleValidate}
      >
        <p>{t("confirmTimeslot")}</p>
      </div>
    </div>
  );
};
