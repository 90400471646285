import { gql } from "@apollo/client";

export const GET_LEGAL_TEXT = gql`
  query ($type: String!) {
    legalTexts(filters: { type: { eq: $type } }, sort: "createdAt:desc") {
      data {
        id
        attributes {
          content
          type
          createdAt
        }
      }
    }
  }
`;
