import { gql } from "@apollo/client";

export const GET_ME = gql`
  query GetMe {
    me {
      id
      username
      email
      confirmed
      blocked
      firstName
      lastName
      role {
        id
        name
        description
        type
      }
    }
  }
`;

export const UPDATE_ME = gql`
  mutation ($id: ID!, $data: UsersPermissionsUserInput!) {
    updateUsersPermissionsUser(id: $id, data: $data) {
      data {
        id
        attributes {
          username
          email
          confirmed
          blocked
          firstName
          lastName
          role {
            data {
              id
              attributes {
                name
                description
                type
              }
            }
          }
        }
      }
    }
  }
`;
