import { MediaReturn } from "./Accomodation";
import { MediaAttributes } from "./Media";

export interface IMandat {
  id: string;
  attributes: {
    completed: boolean;
    isSigned: boolean;
    mandateNumber: string;
    customFees?: string;
    mandatpdf: {
      data: MediaAttributes[];
    };
    ownerSigned: boolean;
    ownersValidated: boolean;
    signature: {
      data: MediaAttributes[] | null;
    };
    signatureAgency: {
      data: MediaAttributes | null;
    };
    signatureDate: Date;
    relatedUsers?: string;
  };
}

export class Mandat {
  public id: string;

  public mandatpdf: string[];

  public signatureDate: Date;

  public isSigned: boolean;

  public signature: MediaReturn[];

  public signatureAgency: MediaReturn | null;

  public ownersValidated: boolean;

  public ownerSigned: boolean;

  public completed: boolean;

  public mandateNumber: string;

  public customFees: string;

  public relatedUsers: string;

  constructor(mandatFromFetch: IMandat) {
    this.id = mandatFromFetch.id;
    this.mandatpdf = mandatFromFetch.attributes.mandatpdf.data.map(
      (itm) => itm.attributes.url
    );
    this.signatureDate = mandatFromFetch.attributes.signatureDate;
    this.isSigned = mandatFromFetch.attributes.isSigned;
    this.signature = mandatFromFetch.attributes.signature.data
      ? mandatFromFetch.attributes.signature.data.map((sign) => {
          return {
            url: sign.attributes.url,
            id: sign.id
          };
        })
      : [];
    this.signatureAgency = mandatFromFetch.attributes.signatureAgency.data
      ? {
          url: mandatFromFetch.attributes.signatureAgency.data.attributes.url,
          id: mandatFromFetch.attributes.signatureAgency.data.id
        }
      : null;
    this.ownersValidated = mandatFromFetch.attributes.ownersValidated;
    this.ownerSigned = mandatFromFetch.attributes.ownerSigned;
    this.completed = mandatFromFetch.attributes.completed;
    this.mandateNumber = mandatFromFetch.attributes.mandateNumber;
    this.customFees = mandatFromFetch.attributes.customFees || "";
    this.relatedUsers = mandatFromFetch.attributes.relatedUsers || "";
  }
}

export const mandatFactory = (data: IMandat): Mandat => {
  return new Mandat(data);
};
