import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { REGISTER_MUTATION } from "../lib/gql/Auth";
import { useLoaderStore } from "../lib/store";
import {
  RegisterFormScheme,
  RegisterFormValues
} from "../lib/shape/RegisterForm";
import logoUser from "../assets/images/nodimo.png";
import { formatPhone } from "../lib/utils/utils";

export const RegisterScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const loader = useLoaderStore();

  const [userError, setUserError] = useState(false);
  const [showPass, setShowPass] = useState<boolean>(false);

  const [mutateFunction, { loading }] = useMutation(REGISTER_MUTATION, {
    onCompleted: () => {
      loader.stopLoading();
      navigate("/auth/login");
    },
    onError: (error) => {
      loader.stopLoading();
      error.message == "Email or Username are already taken"
        ? setUserError(true)
        : setUserError(false);
    }
  });

  useEffect(() => {
    if (loading) {
      loader.startLoading();
    }
  }, [loader, loading]);

  return (
    <div className="w-full flex justify-center items-center relative">
      <div className="absolute left-0 top-0 mt-4 ml-4">
        <button
          className="rounded-lg bg-white shadow-lg text-black"
          onClick={() => navigate("/auth/login")}
        >
          <i className="fa-solid fa-chevron-left"></i>
          <span className="ml-2">{t("goBack")}</span>
        </button>
      </div>
      <div className="flex justify-center items-center w-10/12 xl:max-w-md">
        <div className="bg-white px-10 pt-10 pb-4 rounded-md shadow-lg">
          <div className="flex justify-between items-center mb-10">
            <div className="basis-1/3 flex justify-center items-center">
              <img className="w-1/2" src={logoUser} alt="" />
            </div>
            <div className="basis-2/3 flex flex-col">
              <h2 className="font-bold">{t("register")}</h2>
            </div>
          </div>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
              phoneNumber: "",
              readCondition: false
            }}
            validationSchema={RegisterFormScheme}
            onSubmit={(values: RegisterFormValues) => {
              mutateFunction({
                variables: {
                  input: {
                    ...values,
                    username: values.email,
                    phoneNumber: formatPhone(values.phoneNumber)
                  }
                }
              });
            }}
          >
            {({
              errors,
              touched,
              handleSubmit,
              isSubmitting,
              isValid,
              initialErrors,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="formField">
                  <Field
                    type="text"
                    name="firstName"
                    id="firstNameID"
                    placeholder={t("firstName")}
                  />
                  <span className="field-required">
                    {errors.firstName && touched.firstName && errors.firstName}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="text"
                    name="lastName"
                    id="lastNameID"
                    placeholder={t("lastName")}
                  />
                  <span className="field-required">
                    {errors.lastName && touched.lastName && errors.lastName}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="email"
                    name="email"
                    id="loginID"
                    placeholder={t("email")}
                  />
                  <span className="field-required">
                    {errors.email && touched.email && errors.email}
                  </span>
                  <span className="field-required">
                    {userError && "Email déjà utilisé."}
                  </span>
                </div>
                <div className="formField">
                  <div className="formIcon">
                    <Field
                      type={showPass ? "text" : "password"}
                      name="password"
                      id="passwordID"
                      placeholder={t("password")}
                      autoComplete="password"
                    />
                    <div
                      className="passIcon"
                      onClick={() => setShowPass(!showPass)}
                    >
                      <i
                        className={`fa-solid fa-eye${
                          !showPass ? "-slash" : ""
                        } text-xl`}
                      ></i>
                    </div>
                  </div>
                  <span className="field-required">
                    {errors.password && touched.password && errors.password}
                  </span>
                </div>
                <div className="formField">
                  <Field
                    type="text"
                    name="phoneNumber"
                    id="phoneNumberID"
                    placeholder={t("phoneNumber")}
                  />
                  <span className="field-required">
                    {errors.phoneNumber &&
                      touched.phoneNumber &&
                      errors.phoneNumber}
                  </span>
                </div>
                <div className="flex items-center mb-6">
                  <Field
                    type="checkbox"
                    name="readCondition"
                    id="readConditionID"
                  />
                  <label className="cguInfo text-sm ml-2">
                    {t("readAndAcceptCondition")}
                    <span onClick={() => navigate("/cgu")}> {t("CGU")} </span>
                    {t("and")}
                    <span onClick={() => navigate("/cgv")}> {t("CGV")}</span>
                  </label>
                  <span className="field-required">
                    {errors.readCondition &&
                      touched.readCondition &&
                      errors.readCondition}
                  </span>
                </div>
                <div className="formField">
                  <button
                    type="submit"
                    className="rounded-full w-full bg-nodimoblue"
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !!initialErrors.email ||
                      !!initialErrors.password ||
                      !!initialErrors.firstName ||
                      !!initialErrors.lastName ||
                      !!initialErrors.phoneNumber ||
                      !!initialErrors.readCondition ||
                      !values.readCondition
                    }
                  >
                    {t("register")}
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
