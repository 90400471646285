import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { QueryResult, useLazyQuery, useMutation } from "@apollo/client";
import { Document, Page, pdfjs } from "react-pdf";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Header } from "../components/Header/Header";
import { useAgencyStore, useLoaderStore } from "../lib/store";
import { CREATE_MANDAT } from "../lib/gql/Mandat";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";
import { ACCOMODATIONS_FETCH_ALL } from "../lib/gql/Accomodation";
import {
  AccomodationAgency,
  IAccomodationAgency,
  accomodationAgencyFactory
} from "../lib/models/AccomodationAgency";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

export const CreateMandatScreen = observer(() => {
  const agencyStore = useAgencyStore();
  const accomodationStore = useAccomodationStore();
  const loader = useLoaderStore();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [checkAgency, setCheckAgency] = useState<boolean>(false);

  const previousPage = () => {
    if (currentPage > 1) setCurrentPage((currentPage) => currentPage - 1);
  };

  const nextPage = () => {
    if (currentPage < numPages)
      setCurrentPage((currentPage) => currentPage + 1);
  };

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const [createMandat] = useMutation(CREATE_MANDAT, {
    onError: (e) => {
      console.log("errored here", e);
    }
  });

  const [fetchAcc] = useLazyQuery(ACCOMODATIONS_FETCH_ALL, {
    fetchPolicy: "network-only",
    onCompleted: ({
      accomodationAgencies
    }: {
      accomodationAgencies: QueryResult;
    }) => {
      const accs: AccomodationAgency[] = accomodationAgencies.data.map(
        (acc: IAccomodationAgency) => {
          return accomodationAgencyFactory(acc);
        }
      );

      accomodationStore.setAccomodations(accs);

      accomodationStore.setSelected(
        accomodationStore.accomodations!.find(
          (acc) =>
            acc.accomodation.id === accomodationStore.selected?.accomodation.id
        )
      );
      loader.stopLoading();
    },
    onError: (e) => {
      console.log("error : Fetch All Accomodation", e);
      loader.stopLoading();
    }
  });

  const handleCreate = () => {
    try {
      loader.startLoading();
      createMandat({
        variables: {
          data: {
            accomodationAgency: accomodationStore.selected?.id,
            users_permissions_user:
              accomodationStore.selected?.accomodation.owner!.id,
            isSigned: false,
            signatureDate: null,
            mandateNumber,
            customFees
          }
        }
      }).then(() => {
        fetchAcc();
      });
    } catch (error) {
      loader.stopLoading();
      console.log("error : Mandat Creator :>> ", error);
    }
  };

  const [mandateNumber, setMandateNumber] = useState<string>(
    accomodationStore.selected?.mandat?.mandateNumber || ""
  );

  const [customFees, setCustomFees] = useState<string>(
    accomodationStore.selected?.mandat?.customFees || ""
  );

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />
      <div className="flex justify-center items-center w-full mt-12 mb-12">
        <div className="bg-white w-11/12 2xl:w-8/12 flex flex-col items-center justify-center p-10 rounded-lg shadow-lg">
          <h2 className="text-3xl mb-12 font-bold text-nodimodarkblue">
            {t("createMandate")}
          </h2>
          <div className="formField">
            <p className="ml-5 mb-2">{t("mandateNumber")}</p>
            <input
              type="text"
              value={mandateNumber}
              placeholder={t("mandateNumber")}
              onChange={(e) => setMandateNumber(e.target.value)}
              disabled={!!accomodationStore.selected?.mandat?.mandateNumber}
            />

            <div className="mt-2 flex flex-row items-center justify-between">
              <p className="text-sm w-4/12 italic">{t("customisedFee")}</p>
              <div className="w-3/12">
                <input
                  type="text"
                  value={customFees}
                  placeholder="10"
                  onChange={(e) => setCustomFees(e.target.value)}
                  disabled={!!accomodationStore.selected?.mandat?.customFees}
                  className={`feesInput ${
                    isNaN(Number(customFees)) ||
                    (customFees && Number(customFees) < 1)
                      ? "errorField"
                      : null
                  }`}
                />
              </div>
              <p className="text-sm w-4/12 ml-2 italic">
                {Number(customFees) > 99
                  ? t("commisionEuros")
                  : t("commisionPercent")}
              </p>
            </div>
          </div>

          {accomodationStore.selected?.mandat?.mandateNumber && (
            <div>
              <Document
                file={accomodationStore.selected?.mandat.mandatpdf[0]}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page pageNumber={currentPage} width={800} />
              </Document>

              <div className="pdfToolBar">
                <i
                  className="fa-solid fa-chevron-left"
                  onClick={previousPage}
                ></i>
                <p>
                  {t("page")} {currentPage} {t("sur")} {numPages}
                </p>
                <i className="fa-solid fa-chevron-right" onClick={nextPage}></i>
              </div>
            </div>
          )}

          <div className="w-full mt-12">
            {!accomodationStore.selected?.mandat?.mandateNumber ? (
              <button
                type="submit"
                className="w-full bg-nodimoblue"
                disabled={
                  mandateNumber.length === 0 ||
                  (customFees.length > 0 &&
                    (isNaN(Number(customFees)) || Number(customFees) < 1))
                }
                onClick={handleCreate}
              >
                {t("continue")}
              </button>
            ) : (
              <div className="flex flex-col justify-center align-middle">
                <div className="flex flex-row mt-3 mb-3 align-middle justify-center">
                  <label>
                    <input
                      className="mr-3"
                      type="checkbox"
                      checked={checkAgency}
                      onChange={() => setCheckAgency(!checkAgency)}
                    />
                    {t("readAndApproveAgency")}
                  </label>
                </div>
                <button
                  type="submit"
                  className="w-full bg-nodimoblue mt-6"
                  onClick={() => navigate("sign")}
                  disabled={!checkAgency}
                >
                  {t("sign")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
