import React, { FC } from "react";

import { AccomodationManagementComponent } from "./AccomodationManagement.type";

/**
 * AccomodationManagement used to display icon and name information of accomodation.
 */
export const AccomodationManagement: FC<AccomodationManagementComponent> = ({
  name,
  icon,
  haveIcon = false
}) => (
  <div className="accomodationManagementItemWrapper">
    {haveIcon ? <img src={icon} alt="" /> : <i className={icon}></i>}
    <p className="accomodationManagementFont px-2">{name}</p>
  </div>
);
