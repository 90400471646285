import { object, string, mixed } from "yup";

export type AgencyFormValues = {
  name: string;
  address: string;
  postalCode: string;
  city: string;
  email: string;
  phoneNumber: string;
  siret: string;
  juridicForm: string;
  legalRepresentative: string;
  proCardNumber: string;
  deliveredByDPT: string;
  financialGuarantee: string;
  enseigne: string;
  website: string;
  thumbnail: string;
  description: string;
};

export const AgencyFormScheme = object().shape({
  name: string().required("Champs requis !"),
  address: string().required("Champs requis !"),
  postalCode: string()
    .matches(/^[0-9]+$/, "Doit être composé uniquement de chiffres !")
    .min(5, "Doit être égal à exactement 5 chiffre")
    .max(5, "Doit être égal à exactement 5 chiffre")
    .required("Champs requis !"),
  email: string()
    .email("Ce champ doit correspondre à un e-mail !")
    .required("Champs requis !"),
  phoneNumber: string()
    .matches(/^[0-9]+$/, "Doit être composé uniquement de chiffres !")
    .min(10, "Doit être égal à exactement 10 chiffre")
    .max(10, "Doit être égal à exactement 10 chiffre")
    .required("Champs requis !"),
  siret: string()
    .matches(/^[0-9]+$/, "Doit être composé uniquement de chiffres !")
    .min(14, "Doit être égal à exactement 14 chiffre")
    .max(14, "Doit être égal à exactement 14 chiffre")
    .required("Champs requis !"),
  legalRepresentative: string().required("Champs requis !"),
  juridicForm: string().required("Champs requis !"),
  proCardNumber: string().required("Champs requis !"),
  deliveredByDPT: string().required("Champs requis !"),
  enseigne: string().required("Champs requis !"),
  website: string()
    .url("Ce champ doit être une url ! ( ex: http://votrenomdedomaine.fr )")
    .required("Champs requis !"),
  description: string().required("Champs requis !"),
  thumbnail: mixed().required("Champs requis !")
});
