import { action, observable, runInAction, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { UserModel } from "../../models/User";
import LoaderStore from "../loader/LoaderStore";
import UserStore from "../user/UserStore";

export default class AuthStore {
  public loader: LoaderStore;

  public user: UserStore;

  @observable
  isLogged: boolean = false;

  @observable
  userToken: string | undefined = undefined;

  constructor(private readonly rootStore: any) {
    this.loader = rootStore.loaderStore;
    this.user = rootStore.userStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: "AuthStore",
      properties: ["userToken", "isLogged"],
      storage: window.localStorage
    });
  }

  @action
  async login(login: { jwt: string; user: UserModel }) {
    runInAction(() => {
      this.userToken = login.jwt;
      this.isLogged = true;
    });
  }

  @action
  logout() {
    runInAction(() => {
      this.userToken = undefined;
      this.isLogged = false;
      this.user.resetCurrentUser();
    });
    return true;
  }
}
