export interface ILegalText {
  id: string;
  attributes: {
    content: string;
    type?: string;
    createdAt?: Date;
  };
}

export class LegalText {
  public id: string;

  public content: string;

  public type?: string;

  public createdAt?: Date;

  constructor(roomFromFetch: ILegalText) {
    this.id = roomFromFetch.id;
    this.content = roomFromFetch.attributes.content;
    this.type = roomFromFetch.attributes.type || "";
    this.createdAt = roomFromFetch.attributes.createdAt || new Date();
  }
}

export const legalTextFactory = (data: ILegalText): LegalText => {
  return new LegalText(data);
};
