import {
  action,
  observable,
  runInAction,
  makeAutoObservable,
  computed
} from "mobx";
import { makePersistable } from "mobx-persist-store";

import { Conversation } from "../../models/Conversation";
import { Message } from "../../models/Message";
import RootStore from "../RootStore";

/**
 * ConversationStore used to manage Agency entity.
 */
export default class ConversationStore {
  /** Conversations linked to current agency connected */
  @observable
  conversations: Conversation[] | [] = [];

  /** Conversations selected to show messages */
  @observable
  selectedConversation: Conversation | null = null;

  /** All messages from conversation */
  @observable
  allMessages: Message[] | [] = [];

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "ConversationStore",
      properties: ["conversations", "selectedConversation", "allMessages"],
      storage: window.localStorage
    });
  }

  /** Used to set this.conversations */
  @action
  async setConversations(newVal: Conversation[] | []) {
    runInAction(() => {
      this.conversations = newVal;
      this.setAllMessages(newVal);
    });
  }

  /** Used to set this.selectedConversation */
  @action
  async setSelectedConversation(newVal: Conversation | null) {
    runInAction(() => {
      this.selectedConversation = newVal;
    });
  }

  /** Used to set this.allMessages */
  @action
  async setAllMessages(newVal: Conversation[] | []) {
    runInAction(() => {
      this.allMessages = newVal.flatMap((conv) => conv.messages);
    });
  }

  /** Used to reset store value */
  @action
  async reset() {
    this.setConversations([]);
    this.setSelectedConversation(null);
    this.setAllMessages([]);
  }

  /** Used to check if user have new messages inbox */
  @computed
  get haveNewMessage() {
    return this.allMessages.length > 0 &&
      this.allMessages.find(
        (mes) => !mes.isRead && mes.owner.roleName !== "Agency"
      )
      ? true
      : false;
  }
}
