import { action, observable, runInAction, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import type { UserModel } from "../../models/User";

export default class UserStore {
  @observable
  currentUser: UserModel | null = null;

  constructor(private readonly rootStore: any) {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "UserStore",
      properties: ["currentUser"],
      storage: window.localStorage
    });
  }

  @action
  setCurrentUser(user: any) {
    runInAction(() => {
      this.currentUser = user;
    });
  }

  @action
  resetCurrentUser() {
    runInAction(() => {
      this.currentUser = null;
    });
  }
}
