import { initMomentLocalization } from "../moment/localization";
import AuthStore from "./auth/AuthStore";
import LoaderStore from "./loader/LoaderStore";
import TimeSlotStore from "./timeslot/TimeSlotStore";
import SettingsStore from "./settings/SettingsStore";
import UserStore from "./user/UserStore";
import AgencyStore from "./agency/AgencyStore";
import AccomodationStore from "./accomodation/AccomodationStore";
import ConversationStore from "./conversation/ConversationStore";
import AmendmentCreatorStore from "./amendment/AmendmentCreatorStore";

export default class RootStore {
  loaderStore: LoaderStore;

  authStore: AuthStore;

  userStore: UserStore;

  settingsStore: SettingsStore;

  timeSlotStore: TimeSlotStore;

  agencyStore: AgencyStore;

  accomodationStore: AccomodationStore;

  conversationStore: ConversationStore;

  amendmentCreatorStore: AmendmentCreatorStore;

  constructor() {
    const version = localStorage.getItem("version");

    if (!version || version !== process.env.REACT_APP_VERSION) {
      console.log("remove");
      localStorage.clear();
      localStorage.setItem("version", String(process.env.REACT_APP_VERSION));
    }

    initMomentLocalization();
    this.loaderStore = new LoaderStore();
    this.settingsStore = new SettingsStore(this);
    this.userStore = new UserStore(this);
    this.authStore = new AuthStore(this);
    this.agencyStore = new AgencyStore(this);
    this.accomodationStore = new AccomodationStore(this);
    this.conversationStore = new ConversationStore(this);
    this.amendmentCreatorStore = new AmendmentCreatorStore(this);
    this.timeSlotStore = new TimeSlotStore(this);
  }
}
