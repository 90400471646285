import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { upperFirst } from "lodash";
import mapboxgl from "mapbox-gl";
import ReactPlayer from "react-player";
import FsLightbox from "fslightbox-react";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { QueryResult, useMutation, useLazyQuery } from "@apollo/client";
import moment from "moment";

import { Header } from "../components/Header/Header";
import { TimeSlot } from "../components/TimeSlot/TimeSlot";
import { useAgencyStore, useLoaderStore, useTimeSlotStore } from "../lib/store";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";
import {
  fetchFromImgProxy,
  formatPrice,
  getCityFromAddress
} from "../lib/utils/utils";
import { AccomodationManagement } from "../components/AccomodationManagement/AccomodationManagement";
import {
  ConditionType,
  DoubleGlazing,
  EnergyClass,
  FacingType,
  Insulation,
  IndividualSanitation,
  TypeHeat
} from "../lib/models/Accomodation";
import { UPDATE_ACCOMODATION_AGENCY } from "../lib/gql/Agency";
import {
  SEND_REFUSED_MESSAGE,
  SEND_RESERVATION_MESSAGE
} from "../lib/gql/Customs";
import {
  AccomodationAgency,
  accomodationAgencyFactory
} from "../lib/models/AccomodationAgency";
import { PopupActions } from "../lib/utils/Type";
import { Pastille } from "../components/Pastille/Pastille";
import { timeSlotWriter } from "../lib/utils/TimeSlot";

// eslint-disable-next-line @typescript-eslint/no-var-requires
const imageNotAvailable = require("../assets/images/image-not-available.jpeg");

mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_API_KEY!;

export const AccomodationScreen = observer(() => {
  const { accomodationId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const mapContainer = useRef(null);
  const popUpRef = useRef<PopupActions | null>(null);

  const agencyStore = useAgencyStore();
  const accomodationStore = useAccomodationStore();
  const timeSlotStore = useTimeSlotStore();
  const loader = useLoaderStore();

  const [mode, setMode] = useState<string>("pick"); // pick / send / validate / error
  const [refusedMessage, setRefusedMessage] = useState<string>("");

  // slider
  const [togglerPicture, setTogglerPicture] = useState<boolean>(false);
  const [togglerPlan, setTogglerPlan] = useState<boolean>(false);
  const [togglerProof, setTogglerProof] = useState<boolean>(false);
  const [imgIndex, setImgIndex] = useState<number>(0);
  const [visitDetails, setVisitDetails] = useState<string>("");
  const [visitLoader, setVisitLoader] = useState<boolean>(false);

  const [sendReservationMessage] = useLazyQuery(SEND_RESERVATION_MESSAGE, {
    onCompleted() {
      setVisitLoader(false);
      setMode("validate");
      setVisitDetails("");
      timeSlotStore.setCurrent(null);
    },
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("error : Message send", e);
      setVisitLoader(false);
      setMode("error");
    }
  });

  const [sendRefusedMessage] = useLazyQuery(SEND_REFUSED_MESSAGE, {
    fetchPolicy: "network-only",
    onError: (e) => {
      console.log("error : Message send", e);
      setMode("error");
      loader.stopLoading();
    }
  });

  const [update] = useMutation(UPDATE_ACCOMODATION_AGENCY, {
    onCompleted: ({
      updateAccomodationAgency
    }: {
      updateAccomodationAgency: QueryResult;
    }) => {
      const acc: AccomodationAgency = accomodationAgencyFactory(
        updateAccomodationAgency.data
      );

      accomodationStore.setSelected(acc);

      loader.stopLoading();
      toast.success(t("refusalMandat"), {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      });
    },
    onError: (e) => {
      console.log("e :>> ", e);
      toast.error(t("agencyLogError"), {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      loader.stopLoading();
    }
  });

  const handleDisplayPicture = (index: number) => {
    setImgIndex(index);
    setTogglerPicture(!togglerPicture);
  };

  const handleDisplayPlan = (index: number) => {
    setImgIndex(index);
    setTogglerPlan(!togglerPlan);
  };

  const handleDisplayProof = (index: number) => {
    setImgIndex(index);
    setTogglerProof(!togglerPlan);
  };

  useEffect(() => {
    accomodationStore.setSelected(
      accomodationStore.accomodations!.find(
        (acc) => acc.accomodation.id === accomodationId
      )
    );
  }, [accomodationId]);

  useEffect(() => {
    if (
      accomodationStore.selected &&
      accomodationStore.selected.accomodation.visits.length > 0
    ) {
      const sortVisits = [...accomodationStore.selected.accomodation.visits];

      timeSlotStore.setShape(
        sortVisits.sort((a, b) => Number(a.day) - Number(b.day))
      );
    }
  }, [accomodationStore.selected]);

  const handleChangeWeek = (mode: string) => {
    mode === "add"
      ? timeSlotStore.setWeek(timeSlotStore.week + 1)
      : timeSlotStore.setWeek(timeSlotStore.week - 1);
  };

  const handleSelectTimeSlot = (date: string, ts: string[]) => {
    if (
      timeSlotStore.current &&
      timeSlotStore.current.date === date &&
      timeSlotStore.current.timeSlot === ts
    ) {
      timeSlotStore.setCurrent(null);
    } else {
      timeSlotStore.setCurrent({ date, timeSlot: ts });
    }
  };

  const handleValidate = () => {
    if (timeSlotStore.current) {
      timeSlotStore.makeTimeStep();
      setMode("send");
    }
  };

  const handleSend = () => {
    /* 
    TODO: Faire et envoyer les message
    TODO: Gérer le retour d'erreur
    TODO: Gérér le retour en succès
    */
    setVisitLoader(true);
    const dateFormat = `Le ${moment(timeSlotStore!.current!.date).format(
      "DD/MM/YYYY"
    )} entre ${timeSlotWriter(timeSlotStore!.current!.timeSlot, " et ")}`;
    sendReservationMessage({
      variables: {
        id: accomodationStore.selected?.id,
        content: `Bonjour, des clients sont intéréssés par votre bien à l'adresse ${
          accomodationStore!.selected!.accomodation.address.address
        } et souhaiteraient le visiter.

Nous vous proposons la date suivante : ${dateFormat}
                
Un peu plus d'informations concernant notre demande : 

${visitDetails}

En vous souhaitant une bonne journée.
        `,
        date: dateFormat
      }
    });
  };

  const handleFinish = () => {
    setMode("pick");
  };

  const handleRetry = () => {
    setMode("send");
  };

  const handleRefuse = () => {
    loader.startLoading();
    popUpRef.current!.close();

    sendRefusedMessage({
      variables: {
        id: accomodationStore.selected?.id,
        content: `Bonjour, nous avons le regrets de vous annoncer que votre bien n'a pas été retenu par notre agence. 

Voici la raison du refus : ${refusedMessage}

En vous souhaitant une bonne journée.
        `,
        date: new Date()
      }
    });
    update({
      variables: {
        id: accomodationStore.selected?.id,
        data: { refused: true }
      }
    });
  };

  const handleGoBack = () => {
    navigate("/accomodations");
  };

  useEffect(() => {
    if (!mapContainer.current) return;
    const map = new mapboxgl.Map({
      container: mapContainer.current!,
      style: "mapbox://styles/mapbox/light-v10",
      center: [
        (accomodationStore.selected &&
          accomodationStore.selected.accomodation.address.coordinates[0]) ||
          3.882936,
        (accomodationStore.selected &&
          accomodationStore.selected.accomodation.address.coordinates[1]) ||
          45.042768
      ],
      zoom: 12
    });

    if (accomodationStore.selected) {
      new mapboxgl.Marker({ anchor: "bottom" })
        .setLngLat([
          accomodationStore.selected.accomodation.address.coordinates[0],
          accomodationStore.selected.accomodation.address.coordinates[1]
        ])
        .addTo(map);
    }

    // cleanup function to remove map on unmount
    return () => map!.remove();
  }, [accomodationStore.selected]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `http://localhost:3000/accomodation-forward/${accomodationStore.selected?.id}`
    );

    toast.success(t("linkCopiedClipboard"), {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  };

  const displayPrice = (acc: AccomodationAgency) => {
    try {
      const lastAmendmentCompleted = acc.amendments
        .slice()
        .sort((a, b) => Number(b.id) - Number(a.id))
        .find((amd) => amd.completed);

      return lastAmendmentCompleted
        ? formatPrice(lastAmendmentCompleted.price)
        : formatPrice(acc.accomodation.price);
    } catch (e) {
      return formatPrice(acc.accomodation.price);
    }
  };

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />

      <div className="mt-6 px-6">
        {accomodationStore.selected && (
          <div>
            <div className="flex justify-start items-center mb-6">
              <button
                className="rounded-lg bg-white shadow-lg text-black m-0"
                onClick={handleGoBack}
              >
                <i className="fa-solid fa-chevron-left"></i>
                <span className="ml-2">{t("goBack")}</span>
              </button>
              <p className="text-3xl font-bold text-nodimodarkblue ml-4">
                {t(accomodationStore.selected.accomodation.type.slug)} {t("in")}{" "}
                {getCityFromAddress(accomodationStore.selected.accomodation)} -{" "}
                {displayPrice(accomodationStore.selected)}
              </p>
            </div>

            <div className="flex flex-col-reverse xl:flex-row justify-around">
              <div className="w-full xl:w-7/12 relative">
                <img
                  src={
                    accomodationStore.selected.accomodation.photos[0]
                      ? fetchFromImgProxy(
                          accomodationStore.selected.accomodation.photos[0].url,
                          {
                            format: "webp",
                            width: 800
                          }
                        )
                      : imageNotAvailable
                  }
                  className="object-cover w-full max-h-96 2xl:max-h-[40rem] shadow-lg rounded-md mb-6"
                />
                <Pastille
                  type={
                    accomodationStore.selected.refused
                      ? "refused"
                      : accomodationStore.selected.mandat?.isSigned
                      ? "available"
                      : "waiting"
                  }
                />

                <div className=" relative">
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("propertyDescription")}
                  </p>
                  <div className="flex flex-col items-end relative lg:absolute right-0 top-0">
                    <div className="flex">
                      <p className="accomodationAsset">
                        <i className="fa-solid fa-camera"></i>{" "}
                        {accomodationStore.selected.accomodation.photos.length}{" "}
                        {t("photo", {
                          count:
                            accomodationStore.selected.accomodation.photos
                              .length
                        })}
                      </p>
                      {accomodationStore.selected.accomodation.videos.length >
                        0 && (
                        <p className="accomodationAsset">
                          <i className="fa-solid fa-video"></i>{" "}
                          {
                            accomodationStore.selected.accomodation.videos
                              .length
                          }{" "}
                          {t("video", {
                            count:
                              accomodationStore.selected.accomodation.videos
                                .length
                          })}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row items-center mt-4">
                      <p
                        onClick={handleCopyLink}
                        className="accomodationDownload mr-4"
                      >
                        {t("sharingProperty")}
                      </p>
                      <i
                        onClick={() =>
                          navigate(
                            `/accomodation-forward/${accomodationStore.selected?.id}`
                          )
                        }
                        className="fa-solid fa-arrow-up-right-from-square accomodationDownload"
                      ></i>
                    </div>
                  </div>
                  <p className="w-9/12">
                    {accomodationStore.selected.accomodation.description}
                  </p>
                </div>

                <div>
                  <div className="divider" />
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("propertyCharacteristics")}
                  </p>
                </div>

                <div className="rowWrapper justifySB">
                  <div className="flex flex-col">
                    <p>
                      - {t(accomodationStore.selected.accomodation.type.slug)}{" "}
                      {accomodationStore.selected.accomodation.buildingDate
                        ? t("of") +
                          " " +
                          accomodationStore.selected.accomodation.buildingDate +
                          " - "
                        : " - "}
                      {accomodationStore.selected.accomodation.buildUpArea} m²
                    </p>

                    <p>
                      - {t("field")}{" "}
                      {accomodationStore.selected.accomodation.totalLandArea} m²{" "}
                      {accomodationStore.selected.accomodation
                        .notSureTotalLandArea && `(${t("toCheck")})`}
                    </p>

                    {accomodationStore.selected.accomodation.doubleGlazing && (
                      <p>
                        - {t("doubleGlazing")} :{" "}
                        {t(
                          DoubleGlazing[
                            accomodationStore.selected.accomodation
                              .doubleGlazing as unknown as keyof typeof DoubleGlazing
                          ]
                        )}
                      </p>
                    )}
                    {accomodationStore.selected.accomodation.propertyTax >
                      0 && (
                      <p>
                        - {t("propertyTaxDisplay")} :{" "}
                        {accomodationStore.selected.accomodation.propertyTax} €
                      </p>
                    )}
                    {accomodationStore.selected.accomodation.annualFees > 0 && (
                      <p>
                        - {t("annualFees")} :{" "}
                        {accomodationStore.selected.accomodation.annualFees} €
                      </p>
                    )}

                    {accomodationStore.selected.accomodation
                      .tenYearGuarantee && <p>- {t("tenYearGuarantee")}</p>}

                    {accomodationStore.selected.accomodation
                      .propertyCasualityInsurance && (
                      <p>- {t("propertyInsurance")}</p>
                    )}

                    {accomodationStore.selected.accomodation.coOwned && (
                      <p>- {t("coOwned")}</p>
                    )}

                    {accomodationStore.selected.accomodation.expansion && (
                      <p>- {t("expansionPotential")}</p>
                    )}

                    {accomodationStore.selected.accomodation.quiet && (
                      <p>- {t("quiet")}</p>
                    )}

                    {accomodationStore.selected.accomodation.noOpposite && (
                      <p>- {t("noOpposite")}</p>
                    )}

                    {accomodationStore.selected.accomodation
                      .trainStationWalk && <p>- {t("trainStationWalk")}</p>}

                    {accomodationStore.selected.accomodation
                      .forestAccessOnFoot && <p>- {t("forestAccessOnFoot")}</p>}

                    {accomodationStore.selected.accomodation.singleStorey && (
                      <p>- {t("singleStorey")}</p>
                    )}

                    {accomodationStore.selected.accomodation
                      .sewageConnection && <p>- {t("sewageConnection")}</p>}

                    {accomodationStore.selected.accomodation.balcony && (
                      <p>- {t("balcony")}</p>
                    )}

                    {accomodationStore.selected.accomodation.basement && (
                      <p>- {t("basement")}</p>
                    )}

                    {accomodationStore.selected.accomodation.parkingPlaces && (
                      <p>- {t("parkingPlaces")}</p>
                    )}

                    {accomodationStore.selected.accomodation.terrace && (
                      <p>- {t("terrace")}</p>
                    )}

                    {accomodationStore.selected.accomodation.spa && (
                      <p>- {t("spa")}</p>
                    )}

                    {accomodationStore.selected.accomodation.gym && (
                      <p>- {t("gym")}</p>
                    )}

                    {accomodationStore.selected.accomodation.waterRoom && (
                      <p>- {t("waterRoom")}</p>
                    )}

                    {accomodationStore.selected.accomodation.petanque && (
                      <p>- {t("petanque")}</p>
                    )}

                    {accomodationStore.selected.accomodation.horseStable && (
                      <p>- {t("paddock")}</p>
                    )}

                    {accomodationStore.selected.accomodation.summerKitchen && (
                      <p>- {t("summerKitchen")}</p>
                    )}

                    {accomodationStore.selected.accomodation.barbecue && (
                      <p>- {t("barbecue")}</p>
                    )}

                    {accomodationStore.selected.accomodation.wineCellar && (
                      <p>- {t("wineCellar")}</p>
                    )}

                    {accomodationStore.selected.accomodation.gardenShed && (
                      <p>- {t("gardenShed")}</p>
                    )}

                    {accomodationStore.selected.accomodation.workshop && (
                      <p>- {t("workshop")}</p>
                    )}

                    {accomodationStore.selected.accomodation.greenhouse && (
                      <p>- {t("greenhouse")}</p>
                    )}

                    {accomodationStore.selected.accomodation.poolHouse && (
                      <p>- {t("poolHouse")}</p>
                    )}

                    {accomodationStore.selected.accomodation.bikeStorage && (
                      <p>- {t("bikeStorage")}</p>
                    )}

                    {accomodationStore.selected.accomodation.carport && (
                      <p>- {t("carport")}</p>
                    )}

                    {accomodationStore.selected.accomodation.river && (
                      <p>- {t("river")}</p>
                    )}

                    {accomodationStore.selected.accomodation.bonfire && (
                      <p>- {t("bonfire")}</p>
                    )}

                    {accomodationStore.selected.accomodation.well && (
                      <p>- {t("well")}</p>
                    )}

                    {accomodationStore.selected.accomodation.horseStall && (
                      <p>- {t("horseStall")}</p>
                    )}

                    {accomodationStore.selected.accomodation
                      .individualSanitation && (
                      <p>
                        - {t("energyType")} :{" "}
                        {t(
                          IndividualSanitation[
                            accomodationStore.selected.accomodation
                              .individualSanitation as unknown as keyof typeof IndividualSanitation
                          ]
                        )}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation
                      .convertibleAttic && <p>- {t("convertibleAttic")}</p>}

                    {accomodationStore.selected.accomodation.typeHeat && (
                      <p>
                        - {t("energyType")} :{" "}
                        {t(
                          TypeHeat[
                            accomodationStore.selected.accomodation
                              .typeHeat as unknown as keyof typeof TypeHeat
                          ]
                        )}
                      </p>
                    )}
                    {accomodationStore.selected.accomodation.secondTypeHeat && (
                      <p>
                        - {t("secondTypeEnergy")} :{" "}
                        {t(
                          TypeHeat[
                            accomodationStore.selected.accomodation
                              .secondTypeHeat as unknown as keyof typeof TypeHeat
                          ]
                        )}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation.condition && (
                      <p>
                        -{" "}
                        {t(
                          ConditionType[
                            accomodationStore.selected.accomodation
                              .condition as unknown as keyof typeof ConditionType
                          ]
                        )}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation
                      .conditionDetails && (
                      <p className="w-9/12">
                        -{t("conditionDetails")} :{" "}
                        {
                          accomodationStore.selected.accomodation
                            .conditionDetails
                        }
                      </p>
                    )}

                    {accomodationStore.selected.accomodation.ges && (
                      <p>
                        -{" "}
                        {`${t("greenhouseGase")} : ${
                          EnergyClass[
                            accomodationStore.selected.accomodation
                              .ges as unknown as keyof typeof EnergyClass
                          ]
                        }`}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation.energyClass && (
                      <p>
                        -{" "}
                        {`${t("energyDiagnosis")} : ${
                          EnergyClass[
                            accomodationStore.selected.accomodation
                              .energyClass as unknown as keyof typeof EnergyClass
                          ]
                        }`}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation.commonWalls && (
                      <p>
                        -{" "}
                        {
                          accomodationStore.selected.accomodation
                            .numberCommonWalls
                        }{" "}
                        {t("commonWalls")}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation.garage && (
                      <p>
                        - {accomodationStore.selected.accomodation.numberGarage}{" "}
                        {t("garage")}
                      </p>
                    )}

                    {!!accomodationStore.selected.accomodation.belongsToSci && (
                      <p>
                        - {t("relativeSociety")}{" "}
                        {
                          accomodationStore.selected.accomodation.belongsToSci
                            .name
                        }
                      </p>
                    )}

                    {accomodationStore.selected.accomodation.isRented && (
                      <>
                        <p>- {t("currentlyRentedTo")} :</p>
                        <p
                          className="ml-6 my-2"
                          dangerouslySetInnerHTML={{
                            __html:
                              accomodationStore.selected.accomodation
                                .rentersContactInformation
                          }}
                        />
                      </>
                    )}

                    {!!accomodationStore.selected.accomodation
                      .dependenciesArea && (
                      <p>
                        -{" "}
                        {`${t("dependency")}
                          ${
                            accomodationStore.selected.accomodation
                              .dependenciesArea
                          }
                        m2 - ${
                          accomodationStore.selected.accomodation
                            .dependenciesDescription
                        }`}
                      </p>
                    )}

                    {accomodationStore.selected.accomodation
                      .shopsWalkingDistance && (
                      <p>- {t("shopsWalkingDistance")}</p>
                    )}

                    {accomodationStore.selected.accomodation.boilerLess10Y && (
                      <p>- {t("boilerLess10Y")}</p>
                    )}
                    {accomodationStore.selected.accomodation.insulation && (
                      <p>
                        - {t("insulation")} :{" "}
                        {t(
                          Insulation[
                            accomodationStore.selected.accomodation
                              .insulation as unknown as keyof typeof Insulation
                          ]
                        )}
                      </p>
                    )}
                  </div>
                </div>

                {accomodationStore.selected.accomodation.accomodationRooms
                  .length > 0 && (
                  <div>
                    <div className="divider" />
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                      {t("interiorDesign")}
                    </p>

                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                      {accomodationStore.selected.accomodation.accomodationRooms.map(
                        (accR) => (
                          <AccomodationManagement
                            key={accR.id}
                            name={`${accR.quantity} ${
                              accR.label
                                ? accR.label
                                : t(accR.room!.slug, {
                                    count: accR.quantity
                                  })
                            }`}
                            icon={
                              accR.room && accR.room.icon
                                ? fetchFromImgProxy(accR.room.icon, {
                                    format: "webp",
                                    width: 40
                                  })
                                : "fa-solid fa-house"
                            }
                            haveIcon={!!accR.room && !!accR.room.icon}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}

                <div>
                  <div className="divider" />
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("outdoorDesign")}
                  </p>

                  <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                    {accomodationStore.selected.accomodation.forest && (
                      <AccomodationManagement
                        name={t("forest")}
                        icon={"fa-solid fa-apple-whole"}
                      />
                    )}{" "}
                    {accomodationStore.selected.accomodation.wood && (
                      <AccomodationManagement
                        name={t("wood")}
                        icon={"fa-solid fa-tree"}
                      />
                    )}
                    {accomodationStore.selected.accomodation
                      .landscapedGarden && (
                      <AccomodationManagement
                        name={t("landscapedGarden")}
                        icon={"fa-solid fa-seedling"}
                      />
                    )}
                    {accomodationStore.selected.accomodation
                      .unobstructedView && (
                      <AccomodationManagement
                        name={t("unobstructedView")}
                        icon={"fa-solid fa-panorama"}
                      />
                    )}
                    {accomodationStore.selected.accomodation
                      .exceptionalView && (
                      <AccomodationManagement
                        name={t("exceptionalView")}
                        icon={"fa-solid fa-mountain-sun"}
                      />
                    )}
                    {accomodationStore.selected.accomodation
                      .vegetableGarden && (
                      <AccomodationManagement
                        name={t("vegetableGarden")}
                        icon={"fa-solid fa-carrot"}
                      />
                    )}
                    {accomodationStore.selected.accomodation.tennis && (
                      <AccomodationManagement
                        name={t("tennis")}
                        icon={"fa-solid fa-table-tennis-paddle-ball"}
                      />
                    )}
                    {accomodationStore.selected.accomodation.pool && (
                      <AccomodationManagement
                        name={`${t("pool")}
                          ${
                            accomodationStore.selected.accomodation.poolHeating
                              ? t("heated")
                              : ""
                          } ${
                          accomodationStore.selected.accomodation.poolSize
                        }`}
                        icon={"fa-solid fa-water-ladder"}
                      />
                    )}
                  </div>
                </div>

                {accomodationStore.selected.accomodation.facing && (
                  <div>
                    <div className="divider" />
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                      {t("propertyFacing")}
                    </p>

                    <div className="flex justify-start items-center">
                      <i className="fa-solid fa-sun iconFont"></i>
                      <p>
                        {t(
                          FacingType[
                            accomodationStore.selected.accomodation
                              .facing as unknown as keyof typeof FacingType
                          ]
                        )}
                      </p>
                    </div>
                  </div>
                )}
                <div className="divider" />
                <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                  {upperFirst(t("photo_plural"))}
                </p>

                <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                  {accomodationStore.selected.accomodation.photos.map(
                    (url, i) => (
                      <img
                        key={url.id}
                        src={
                          url
                            ? fetchFromImgProxy(url.url, {
                                format: "webp",
                                width: 200
                              })
                            : imageNotAvailable
                        }
                        className="accomodationPicture w-full h-24 cursor-pointer"
                        alt={t("loadingError")}
                        onClick={() => handleDisplayPicture(i)}
                      />
                    )
                  )}
                </div>
                {accomodationStore.selected.accomodation.videos.length > 0 && (
                  <>
                    <div className="divider" />

                    <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                      {upperFirst(t("video_plural"))}
                    </p>

                    <div className="accomodationManagementWrapper">
                      {accomodationStore.selected.accomodation.videos.map(
                        (url) => (
                          <ReactPlayer
                            key={url.id}
                            url={url.url}
                            controls={true}
                            muted={true}
                            playsinline={true}
                            width="320px"
                            height="180px"
                            className="accomodationVideos"
                          />
                        )
                      )}
                    </div>
                  </>
                )}

                {accomodationStore.selected.accomodation.plans.length > 0 && (
                  <>
                    <div className="divider"></div>
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue ">
                      {upperFirst(t("plan_plural"))}
                    </p>

                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                      {accomodationStore.selected.accomodation.plans.map(
                        (url, i) => (
                          <img
                            key={url.id}
                            src={
                              url
                                ? fetchFromImgProxy(url.url, {
                                    format: "webp",
                                    width: 400
                                  })
                                : imageNotAvailable
                            }
                            className="accomodationPicture w-full h-24"
                            alt={t("loadingError")}
                            onClick={() => handleDisplayPlan(i)}
                          />
                        )
                      )}
                    </div>
                  </>
                )}

                <div>
                  <div className="divider" />
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue">
                    {t("propertyAddress")}
                  </p>
                  <p className="mb-6">
                    {accomodationStore.selected.accomodation.address.address}
                  </p>
                  <div className="accomodationMap">
                    <div
                      ref={mapContainer}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              </div>
              <div className="mb-6 xl:mb-0 xl:ml-6 flex flex-col items-center w-full xl:w-5/12">
                <div className="shadowBox marginBottom">
                  <p className="text-xl mb-6 font-bold text-nodimodarkblue ">
                    {t("salesMandate")}
                  </p>
                  <div className="flex flex-col 2xl:flex-row justify-between">
                    {!accomodationStore.selected.mandat &&
                      !accomodationStore.selected.refused && (
                        <>
                          <p>
                            <i className="fa-regular fa-square-check"></i>{" "}
                            {t("noMandateYet")}
                          </p>
                          <div className="flex flex-row 2xl:flex-col mt-8 2xl:mt-0 justify-center 2xl:items-end items-center self-end">
                            <Link to="create-mandat">
                              <span className="mandatDownload">
                                {t("createMandate")}
                                <i className="fa-solid fa-plus ml-2"></i>
                              </span>
                            </Link>
                            <span
                              className="mandatRefused mt-0 2xl:mt-6"
                              onClick={() => popUpRef.current?.open()}
                            >
                              {t("refusingMandate")}{" "}
                              <i className="fa-solid fa-trash"></i>
                            </span>
                          </div>
                        </>
                      )}
                    {accomodationStore.selected.mandat &&
                      !accomodationStore.selected.mandat?.signatureAgency &&
                      !accomodationStore.selected.refused && (
                        <>
                          <p>
                            <i className="fa-regular fa-square-check"></i>{" "}
                            {t("waitingForAgencySign")}
                          </p>
                          <Link
                            to="create-mandat"
                            className="mt-8 2xl:mt-0 self-end"
                          >
                            <span className="mandatDownload">
                              {t("signMandate")}{" "}
                              <i className="fa-solid fa-signature ml-2"></i>
                            </span>
                          </Link>
                        </>
                      )}
                    {accomodationStore.selected.mandat &&
                      !!accomodationStore.selected.mandat?.signatureAgency &&
                      !accomodationStore.selected.mandat?.isSigned &&
                      !accomodationStore.selected.refused && (
                        <>
                          <p>
                            <i className="fa-regular fa-square-check"></i>{" "}
                            {t("WaitingForSeller")}
                          </p>
                          <a
                            href={
                              accomodationStore?.selected?.mandat?.mandatpdf[0]
                            }
                            target="_blank"
                            download
                            rel="noreferrer"
                            className="mt-8 2xl:mt-0 self-end"
                          >
                            <span className="mandatDownload">
                              {t("downloadMandate")}{" "}
                              <i className="fa-solid fa-download ml-2"></i>
                            </span>
                          </a>
                        </>
                      )}
                    {accomodationStore.selected.mandat?.isSigned && (
                      <>
                        <p>
                          <i className="fa-regular fa-square-check"></i>{" "}
                          {t("canScheduleVisits")}
                        </p>
                        <a
                          href={
                            accomodationStore?.selected?.mandat?.mandatpdf[0]
                          }
                          target="_blank"
                          download
                          rel="noreferrer"
                          className="mt-8 2xl:mt-0 self-end"
                        >
                          <span className="mandatDownload">
                            {t("downloadMandate")}{" "}
                            <i className="fa-solid fa-download ml-2"></i>
                          </span>
                        </a>
                      </>
                    )}
                    {accomodationStore.selected.refused && (
                      <>
                        <p>{t("youRefuseMandat")}</p>
                      </>
                    )}
                  </div>
                </div>

                {accomodationStore.selected.amendments &&
                  accomodationStore.selected.amendments.length > 0 && (
                    <div className="shadowBox marginBottom">
                      <div className="col-span-2 2xl:col-span-1 flex flex-col h-72 2xl:h-[20rem] bg-white">
                        <div className="flex flex-row items-center w-full mb-6">
                          <div className="w-10/12">
                            <p className="text-xl font-bold text-nodimodarkblue">
                              {t("amendment", {
                                count:
                                  accomodationStore.selected.amendments.length
                              })}{" "}
                              (
                              {accomodationStore.selected.amendments.length ||
                                0}
                              )
                            </p>
                            <p className="text-lg text-gray-400">
                              {t("amendmentList")}
                            </p>
                          </div>
                        </div>
                        <div className="border-t-2 border-grey-500 h-full overflow-y-scroll">
                          <div className="flex flex-col h-full items-center w-full">
                            {accomodationStore.selected.amendments
                              .slice()
                              .sort((a, b) => Number(b.id) - Number(a.id))
                              .map((amd) => (
                                <div
                                  key={amd.id}
                                  className="border-b-2 border-grey-500 w-full p-2 hover:bg-gray-100 cursor-pointer select-none flex justify-between items-center"
                                  onClick={() => {
                                    if (!amd.signatureAgency)
                                      navigate("amendment");
                                    else if (amd.amendmentPdf[0])
                                      window.open(
                                        amd.amendmentPdf[0],
                                        "_blank"
                                      );
                                  }}
                                >
                                  <div>
                                    <p className="text-base 2xl:text-lg text-gray-400 mb-2">
                                      <span
                                        className={`${
                                          amd.completed
                                            ? "text-nodimodarkblue"
                                            : !amd.signatureAgency
                                            ? "text-nodimoerror"
                                            : "text-nodimororange"
                                        } font-bold`}
                                      >
                                        {amd.completed
                                          ? t("availableAmendment")
                                          : !amd.signatureAgency
                                          ? t("signAmendment")
                                          : t("waitingUsersSign")}
                                      </span>
                                    </p>
                                    <p className="text-gray-400 text-sm">
                                      {t("price")} : {formatPrice(amd.price)}{" "}
                                      {!!amd.fees &&
                                        `- ${t("justFees")} : ${amd.fees} ${
                                          Number(amd.fees) > 99
                                            ? t("commisionEuros")
                                            : t("commisionPercent")
                                        }`}
                                    </p>
                                  </div>
                                  <i
                                    className={`fa-solid fa-chevron-right ${
                                      amd.completed
                                        ? "text-nodimodarkblue"
                                        : !amd.signatureAgency
                                        ? "text-nodimoerror"
                                        : "text-nodimororange"
                                    } justify-self-end`}
                                  ></i>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {timeSlotStore.timeSlot.length > 0 &&
                  accomodationStore.selected.mandat?.isSigned && (
                    <div className="shadowBox marginBottom">
                      <p className="text-xl mb-6 font-bold text-nodimodarkblue ">
                        {t("organisationVisits")}
                      </p>
                      {mode === "pick" && (
                        <div>
                          <p>{t("selectCinvenientTime")}</p>
                          <div className="accomodationTS">
                            <TimeSlot
                              timeSlot={timeSlotStore.timeSlot}
                              current={timeSlotStore.current}
                              handleChangeWeek={handleChangeWeek}
                              handleSelectTimeSlot={handleSelectTimeSlot}
                              handleValidate={handleValidate}
                            />
                          </div>
                        </div>
                      )}
                      {mode === "send" && (
                        <div>
                          <p className="mb-2">{t("fillInformationOptional")}</p>
                          <div className="informationsVisitWrapper">
                            <textarea
                              rows={8}
                              className="visitInfo"
                              value={visitDetails}
                              onChange={(e) => setVisitDetails(e.target.value)}
                            />
                            <div
                              className="button w-full mt-4"
                              onClick={handleSend}
                            >
                              {visitLoader ? (
                                <p>
                                  <i className="fa-solid fa-spinner text-white animate-spin mr-2"></i>
                                  {t("sendingMessage")}
                                </p>
                              ) : (
                                <p>{t("suggestVisitSeller")}</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}

                      {mode === "validate" && (
                        <div>
                          <p className="mb-6 bg-green-300 text-green-700 border-green-400 border-2 rounded-lg p-4 font-bold">
                            {t("requestAppointmentSent")}
                          </p>
                          <div className="informationsVisitWrapper">
                            <div
                              className="button w-full"
                              onClick={handleFinish}
                            >
                              <p>{t("finish")}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {mode === "error" && (
                        <div>
                          <p className="mb-6 bg-red-300 text-red-700 border-red-400 border-2 rounded-lg p-4 font-bold">
                            {t("oopsRequestNotThrough")}
                          </p>
                          <div className="informationsVisitWrapper">
                            <div
                              className="button w-full"
                              onClick={handleRetry}
                            >
                              <p>{t("tryAgain")}</p>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                {accomodationStore.selected.accomodation.propertyProof.length >
                  0 && (
                  <div className="shadowBox">
                    <p className="text-xl mb-6 font-bold text-nodimodarkblue ">
                      {t("supportingDocs")}
                    </p>

                    <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-7 gap-2">
                      {accomodationStore.selected.accomodation.propertyProof.map(
                        (url, i) => (
                          <img
                            key={url.id}
                            src={
                              url
                                ? fetchFromImgProxy(url.url, {
                                    format: "webp",
                                    width: 400
                                  })
                                : imageNotAvailable
                            }
                            className="accomodationPicture w-full h-24"
                            alt={t("loadingError")}
                            onClick={() => handleDisplayProof(i)}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FsLightbox
              sourceIndex={imgIndex}
              toggler={togglerPicture}
              sources={accomodationStore.selected.accomodation.photos.map((m) =>
                fetchFromImgProxy(m.url, {
                  format: "webp",
                  width: 1000
                })
              )}
            />
            <FsLightbox
              sourceIndex={imgIndex}
              toggler={togglerPlan}
              sources={accomodationStore.selected.accomodation.plans.map((m) =>
                fetchFromImgProxy(m.url, {
                  format: "webp",
                  width: 1000
                })
              )}
            />

            <FsLightbox
              sourceIndex={imgIndex}
              toggler={togglerProof}
              sources={accomodationStore.selected.accomodation.propertyProof.map(
                (m) =>
                  fetchFromImgProxy(m.url, {
                    format: "webp",
                    width: 1000
                  })
              )}
            />
          </div>
        )}
        <Popup ref={popUpRef} modal>
          <div className="bg-white shadow rounded-lg w-96 h-80 p-10">
            <p className="text-center text-l bold">{t("sureRefuseMandat")}</p>

            <div className="my-6">
              <p className="text-sm mb-2">{t("writeReasonRefusal")}</p>
              <input
                type="text"
                value={refusedMessage}
                onChange={(e) => setRefusedMessage(e.target.value)}
              />
            </div>

            <div className="flex justify-around">
              <button
                className="rounded-full w-6/12 bg-nodimoblue m-4"
                onClick={handleRefuse}
                disabled={refusedMessage.length === 0}
              >
                {t("yes")}
              </button>
              <button
                className="rounded-full w-6/12 bg-red-400 m-4"
                onClick={() => popUpRef.current!.close()}
              >
                {t("no")}
              </button>
            </div>
          </div>
        </Popup>
      </div>
    </div>
  );
});
