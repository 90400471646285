import { object, string } from "yup";

export type LoginFormValues = {
  email: string;
  password: string;
};

export const LoginFormScheme = object().shape({
  email: string()
    .email("Ce champ doit correspondre à un e-mail !")
    .required("Champs requis !"),
  password: string().required("Champs requis !")
});
