import React, { useEffect } from "react";
import { QueryResult, useLazyQuery } from "@apollo/client";
import { observer } from "mobx-react-lite";
import { Outlet, useNavigate, useOutlet } from "react-router-dom";

import { Navigation } from "../components/Navigation/Navigation";
import { ACCOMODATIONS_FETCH_ALL } from "../lib/gql/Accomodation";
import { FETCH_ALL_CONVS } from "../lib/gql/Conversations";
import {
  AccomodationAgency,
  accomodationAgencyFactory,
  IAccomodationAgency
} from "../lib/models/AccomodationAgency";
import {
  Conversation,
  conversationFactory,
  IConversation
} from "../lib/models/Conversation";
import { useAgencyStore } from "../lib/store";
import { useAccomodationStore } from "../lib/store/accomodation/useAccomodationStore";
import { useConversationStore } from "../lib/store/conversation/useConversationStore";

export const HomeScreen = observer(() => {
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if (outlet) return;

    navigate({
      pathname: `/dashboard`
    });
  }, [outlet]);

  const agency = useAgencyStore();
  const accomodation = useAccomodationStore();
  const conversationStore = useConversationStore();

  const [fetchAcc] = useLazyQuery(ACCOMODATIONS_FETCH_ALL, {
    onCompleted: ({
      accomodationAgencies
    }: {
      accomodationAgencies: QueryResult;
    }) => {
      const accs: AccomodationAgency[] = accomodationAgencies.data
        .filter((acc: IAccomodationAgency) => acc.attributes.accomodation.data)
        .map((acc: IAccomodationAgency) => {
          return accomodationAgencyFactory(acc);
        });

      accomodation.setAccomodations(accs);
    },
    onError: (e) => {
      console.log("error", e);
    }
  });

  const [fetchConvs] = useLazyQuery(FETCH_ALL_CONVS, {
    onCompleted: ({ conversations }: { conversations: QueryResult }) => {
      const convs: Conversation[] = conversations.data.map(
        (conv: IConversation) => {
          return conversationFactory(conv);
        }
      );

      conversationStore.setConversations(convs);
    },
    onError: (e) => {
      console.log("error", e);
    }
  });

  useEffect(() => {
    if (agency.agency && agency.agency.id) {
      fetchAcc({ variables: { id: agency.agency.id } });
      fetchConvs();
    }
  }, []);

  return (
    <section className="appWrapper relative">
      <Navigation />
      <div className="relative z-0 ml-20  2xl:ml-52 w-full">
        <Outlet />
      </div>
    </section>
  );
});
