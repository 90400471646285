import { MediaAttributes } from "./Media";

export interface IRoom {
  id?: string;
  attributes: {
    label?: string;
    slug: string;
    weigth?: number;
    icon: {
      data: MediaAttributes;
    };
    isLivable?: boolean;
  };
}

export class Room {
  public id: string;

  public label?: string;

  public slug: string;

  public weigth?: number;

  public icon: string;

  public isLivable?: boolean;

  constructor(roomFromFetch: IRoom) {
    this.id = roomFromFetch.id ? roomFromFetch.id : String(Math.random());
    this.label = roomFromFetch.attributes.label || "";
    this.slug = roomFromFetch.attributes.slug;
    this.weigth = roomFromFetch.attributes.weigth || 0;
    this.icon = roomFromFetch.attributes.icon.data.attributes.url;
    this.isLivable = roomFromFetch.attributes.isLivable;
  }
}

export const roomFactory = (data: IRoom): Room => {
  return new Room(data);
};
