import { action, observable, runInAction, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";

import { Accomodation } from "../../models/Accomodation";
import RootStore from "../RootStore";

/**
 * AmendmentCreatorStore used to manage pipe of amendment creation.
 */
export default class AmendmentCreatorStore {
  @observable
  selectedAccomodation: Accomodation | null = null;

  @observable
  fees: string = "";

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "AmendmentCreatorStore",
      properties: ["selectedAccomodation", "fees"],
      storage: window.localStorage
    });
  }

  /** Used to set selectedAccomodation */
  @action
  async setSelectedAccomodation(newVal: Accomodation | null) {
    runInAction(() => {
      this.selectedAccomodation = newVal;
    });
  }

  /** Used to set fees */
  @action
  async setFees(newVal: string) {
    runInAction(() => {
      this.fees = newVal;
    });
  }

  /** Used to reset creation stepper */
  @action
  async reset() {
    this.setSelectedAccomodation(null);
    this.setFees("");
  }
}
