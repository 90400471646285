import { action, observable, runInAction, makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
import { toast } from "react-toastify";

import { DEFAULT, LOCAL, STAGING, PROD } from "../../env/env";
import { CUSTOM_ENV } from "../../env/env.type";
import LoaderStore from "../loader/LoaderStore";

export default class SettingsStore {
  public loader: LoaderStore;

  @observable
  env: CUSTOM_ENV = DEFAULT;

  constructor(private readonly rootStore: any) {
    this.loader = rootStore.loaderStore;

    makeAutoObservable(this);

    makePersistable(this, {
      name: "SettingsStore",
      properties: ["env"],
      storage: window.localStorage
    });
  }

  @action
  async setEnv(envName: string) {
    runInAction(() => {
      this.env =
        {
          LOCAL,
          STAGING,
          PROD
        }[envName] || DEFAULT;
    });

    toast.success(`Environement changé vers ${this.env.REACT_APP_ENV_NAME} !`, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true
    });
  }
}
