import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/client";
import { Field, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { FORGOT_PASSWORD } from "../lib/gql/Passwords";
import { useLoaderStore } from "../lib/store";
import { ForgotFormScheme, ForgotFormValues } from "../lib/shape/FogotForm";
import logoUser from "../assets/images/nodimo.png";

export const PasswordForgotScreen = observer(() => {
  const [forgetSuccess, setForgetSuccess] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const loader = useLoaderStore();

  const [mutateFunction, { loading }] = useMutation(FORGOT_PASSWORD, {
    onCompleted: () => {
      loader.stopLoading();
      setForgetSuccess(true);
    },
    onError: () => {
      loader.stopLoading();
    }
  });

  useEffect(() => {
    if (loading) {
      loader.startLoading();
    }
  }, [loader, loading]);

  return (
    <div className="w-full flex justify-center items-center relative">
      <div className="absolute left-0 top-0 mt-4 ml-4">
        <button
          className="rounded-lg bg-white shadow-lg text-black"
          onClick={() => navigate("/auth/login")}
        >
          <i className="fa-solid fa-chevron-left"></i>
          <span className="ml-2">{t("goBack")}</span>
        </button>
      </div>
      <div className="flex justify-center items-center w-10/12 xl:max-w-md">
        <div className="bg-white px-10 pt-10 pb-4 rounded-md shadow-lg">
          <div className="flex justify-between items-center mb-10">
            <div className="basis-1/3 flex justify-center items-center">
              <img className="w-1/2" src={logoUser} alt="" />
            </div>
            <div className="basis-2/3 flex flex-col ">
              <h2 className="font-bold">{t("forgotPassword")} ?</h2>
              <p>{t("changeItWithForm")}</p>
            </div>
          </div>

          {!forgetSuccess ? (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={ForgotFormScheme}
              onSubmit={(values: ForgotFormValues, { setSubmitting }) => {
                mutateFunction({ variables: values });
                setSubmitting(false);
              }}
            >
              {({
                errors,
                touched,
                handleSubmit,
                isSubmitting,
                isValid,
                initialErrors,
                dirty
                /* and other goodies */
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="formField">
                    <Field
                      type="email"
                      name="email"
                      id="loginID"
                      placeholder="Identifiant"
                    />
                    <span className="field-required">
                      {errors.email && touched.email && errors.email}
                    </span>
                  </div>

                  <div className="formField">
                    <button
                      className="rounded-full w-full bg-nodimoblue"
                      type="submit"
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        !!initialErrors.email ||
                        !dirty
                      }
                    >
                      {t("askPasswordReset")}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <div className="mb-6">
              <p>{t("ifWeFindAccount")}</p>
              <p className="mt-4">{t("followProcedure")}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
