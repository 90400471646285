import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { HeaderComponent } from "./Header.type";
import undefinedPeople from "../../assets/images/undefined_people.png";

/**
 * Header used to render agency information in app.
 */
export const Header: FC<HeaderComponent> = ({ agency }) => {
  const { t } = useTranslation();

  return (
    <div className="agencyHeader bg-white">
      <img
        src={agency && agency.thumbnail ? agency.thumbnail : undefinedPeople}
        className="agencyThumbnail"
      />
      <p className="agencyName">
        {agency && agency.enseigne
          ? agency.enseigne
          : agency && agency.name
          ? agency.name
          : t("agency")}
      </p>
      <i className="fa-solid fa-chevron-down"></i>
    </div>
  );
};
