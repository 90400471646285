/**
 * Role interface from API
 */
export interface RoleModel {
  /** Role id */
  id: string;
  /** Role name */
  name: string;
  /** Role description */
  description: string;
  /** Role types */
  types: string;
}

export interface IRole {
  id: string;
  attributes: {
    name: string;
    description?: string;
    type?: string;
  };
}
export class Role {
  public id: string;

  public name: string;

  public description?: string;

  public type?: string;

  constructor(typeFromFetch: IRole) {
    this.id = typeFromFetch.id;
    this.name = typeFromFetch.attributes.name;
    this.description = typeFromFetch.attributes.description;
    this.type = typeFromFetch.attributes.type;
  }
}

export const roleFactory = (data: IRole): Role => {
  return new Role(data);
};
