import React from "react";
import { observer } from "mobx-react-lite";

export const DebugScreen = observer(() => {
  return (
    <div>
      <h1>Debug Screen</h1>
    </div>
  );
});
