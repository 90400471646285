import { isEqual } from "lodash";
import {
  action,
  observable,
  runInAction,
  makeAutoObservable,
  reaction
} from "mobx";
import { makePersistable } from "mobx-persist-store";
import moment from "moment";

import { CurrentTimeSlot, TimeSlot, TimeSlotShape } from "../../models";
import RootStore from "../RootStore";

export default class TimeSlotStore {
  @observable
  week: number = Number(moment().format("w"));

  @observable
  year: number = Number(moment().format("y"));

  @observable
  current: CurrentTimeSlot | null = null;

  @observable
  timeSlot: TimeSlot[] = [];

  @observable
  shape: TimeSlotShape[] = [];

  constructor(private readonly rootStore: RootStore) {
    makeAutoObservable(this);

    makePersistable(this, {
      name: "TimeSlotStore",
      properties: ["current"],
      storage: window.localStorage
    });

    reaction(
      () => this.shape,
      () => this.makeTimeStep()
    );

    reaction(
      () => this.week,
      () => this.makeTimeStep()
    );
  }

  @action
  setShape(shape: TimeSlotShape[]) {
    runInAction(() => {
      this.shape = shape;
    });
  }

  @action
  setWeek(week: number) {
    runInAction(() => {
      this.week = week;
    });
  }

  @action
  setTimeSlot(timeSlot: TimeSlot[]) {
    runInAction(() => {
      this.timeSlot = timeSlot;
    });
  }

  @action
  setCurrent(curr: CurrentTimeSlot | null) {
    runInAction(() => {
      this.current = curr;
    });
  }

  @action
  checkTakenSlot(timeSlot: TimeSlot[]) {
    if (this.current) {
      const newTimeSlot = timeSlot.map((item) => {
        const newTs = item.timeslot.filter(
          (ts) =>
            !(
              isEqual(this.current?.timeSlot, ts) &&
              this.current?.date === item.date
            )
        );

        return { ...item, timeslot: newTs };
      });

      this.setTimeSlot(newTimeSlot);
    } else {
      this.setTimeSlot(timeSlot);
    }
  }

  @action
  getIsoWeek(): string[] {
    const simple = new Date(this.year, 0, 1 + (this.week - 1) * 7);
    const dow = simple.getDay();
    const ISOweekStart = simple;

    if (dow <= 4) {
      ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
    } else {
      ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
    }

    const temp = {
      d: ISOweekStart.getDate(),
      m: ISOweekStart.getMonth(),
      y: ISOweekStart.getFullYear()
    };

    const numDaysInMonth = new Date(temp.y, temp.m + 1, 0).getDate();

    return Array.from({ length: 7 }, (_) => {
      if (temp.d > numDaysInMonth) {
        temp.m += 1;
        temp.d = 1;
      }

      return new Date(temp.y, temp.m, temp.d++).toISOString();
    });
  }

  @action
  makeTimeStep = () => {
    const dateArr = this.getIsoWeek();

    const newTimeSlot: TimeSlot[] = this.shape.map((day: TimeSlotShape) => {
      const stp = day.step || "0100";

      const step = Number(stp.slice(0, 2)) * 60 + Number(stp.slice(2));

      const timeSlotsComplete = day.timeslot
        .reduce((previous, current) => {
          const [start, end] = current;
          return [
            ...previous,
            ...this.generateNextStepIfPossible(start, end, step)
          ];
        }, [])
        .map((item) => {
          const [start, end]: string = item;

          return [
            this.convertTimeToString(start),
            this.convertTimeToString(end)
          ];
        });

      return {
        day: day.day,
        date: dateArr[Number(day.day)],
        timeslot: timeSlotsComplete
      };
    });

    this.checkTakenSlot(newTimeSlot);
  };

  generateNextStepIfPossible(start: string, end: any, step: number): any {
    const convertedStart = Number(start);
    const convertedEnd = Number(end);
    let increment = convertedStart + step;
    const minuts = String(increment).slice(-2);
    if (Number(minuts) > 59) {
      increment += 100 - Number(minuts) + (Number(minuts) - 60);
    }

    const timeSlot = [convertedStart, increment];

    if (increment > convertedEnd) return [];
    return [
      timeSlot,
      ...this.generateNextStepIfPossible(String(increment), end, step)
    ];
  }

  convertTimeToString(number: any) {
    if (String(number).length === 3) return `0${number}`;
    return String(number);
  }
}
