import React, { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { ProtectedRouteComponent } from "./ProtectedRoutes.type";

/**
 * ProtectedRoute used to restric access to page.
 */
export const ProtectedRoute: FC<ProtectedRouteComponent> = ({
  children,
  isAllowed,
  redirectPath = "/landing"
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};
