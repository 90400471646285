import { gql } from "@apollo/client";

export const ACCOMODATIONS_TOTAL = gql`
  query {
    accomodationsTotal
  }
`;

export const ACCOMODATIONS_FETCH_ALL = gql`
  query {
    accomodationAgencies(pagination: { limit: -1 }) {
      data {
        id
        attributes {
          accomodation {
            data {
              id
              attributes {
                accomodationRooms(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      label
                      quantity
                      room {
                        data {
                          id
                          attributes {
                            icon {
                              data {
                                id
                                attributes {
                                  url
                                }
                              }
                            }
                            isLivable
                            label
                            slug
                            weigth
                          }
                        }
                      }
                    }
                  }
                }
                address
                annualFees
                balcony
                individualSanitation
                convertibleAttic
                spa
                waterRoom
                gym
                petanque
                summerKitchen
                barbecue
                wineCellar
                gardenShed
                workshop
                greenhouse
                poolHouse
                bikeStorage
                carport
                horseStall
                river
                well
                horseStable
                bonfire
                basement
                beWarned
                boilerLess10Y
                buildUpArea
                buildingDate
                coOwned
                commonWalls
                condition
                conditionDetails
                dependenciesArea
                doubleGlazing
                insulation
                description
                dependenciesDescription
                email
                energyClass
                exceptionalView
                expansion
                facing
                firstLastName
                floor
                forest
                forestAccessOnFoot
                garage
                ges
                hasNotaire
                isRented
                majorWork
                landscapedGarden
                noOpposite
                notRealised
                notSureTotalLandArea
                notaireAdress
                notaireName
                numberCommonWalls
                numberGarage
                othersTypeHeat
                owner {
                  data {
                    attributes {
                      username
                      email
                      firstName
                      lastName
                      phoneNumber
                      role {
                        data {
                          id
                          attributes {
                            name
                          }
                        }
                      }
                    }
                    id
                  }
                }
                parkingPlaces
                photos(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                plans(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                pool
                poolHeating
                price
                poolSize
                prm
                propertyCasualityInsurance
                propertyProof(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                propertyTax
                quiet
                rentersContactInformation
                secondTypeHeat
                sewageConnection
                belongsToSci {
                  data {
                    id
                    attributes {
                      name
                      form
                      siret
                      address
                      postalCode
                      city
                      legalRepresentative
                      email
                    }
                  }
                }
                shopsWalkingDistance
                singleStorey
                tenYearGuarantee
                tennis
                terrace
                totalLandArea
                trainStationWalk
                type {
                  data {
                    id
                    attributes {
                      slug
                      label
                      weigth
                      type_rules(pagination: { limit: -1 }) {
                        data {
                          attributes {
                            label
                          }
                        }
                      }
                      thumbnail {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                typeHeat
                unobstructedView
                updatedAt
                vegetableGarden
                videos(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                visits(pagination: { limit: -1 }) {
                  data {
                    attributes {
                      day
                      timeslot
                    }
                    id
                  }
                }
                wood
              }
            }
          }
          mandat {
            data {
              id
              attributes {
                completed
                isSigned
                mandateNumber
                customFees
                mandatpdf(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                ownerSigned
                ownersValidated
                signature {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                signatureAgency {
                  data {
                    attributes {
                      url
                    }
                    id
                  }
                }
                signatureDate
              }
            }
          }
          amendments {
            data {
              id
              attributes {
                price
                fees
                amendmentPdf(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                isSigned
                signatureDate
                completed
                ownerTown
                relatedUsers
                signature {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                signatureAgency {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
          refused
          updatedAt
        }
      }
    }
  }
`;

export const GET_ACC_BY_ID = gql`
  query ($id: ID!) {
    accomodationAgency(id: $id) {
      data {
        id
        attributes {
          accomodation {
            data {
              id
              attributes {
                accomodationRooms(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      label
                      quantity
                      room {
                        data {
                          id
                          attributes {
                            icon {
                              data {
                                id
                                attributes {
                                  url
                                }
                              }
                            }
                            isLivable
                            label
                            slug
                            weigth
                          }
                        }
                      }
                    }
                  }
                }
                address
                annualFees
                balcony
                individualSanitation
                convertibleAttic
                spa
                waterRoom
                gym
                petanque
                summerKitchen
                barbecue
                wineCellar
                gardenShed
                workshop
                greenhouse
                poolHouse
                bikeStorage
                carport
                horseStall
                river
                well
                horseStable
                bonfire
                basement
                beWarned
                boilerLess10Y
                buildUpArea
                buildingDate
                coOwned
                commonWalls
                condition
                conditionDetails
                dependenciesArea
                doubleGlazing
                insulation
                description
                dependenciesDescription
                email
                energyClass
                exceptionalView
                expansion
                facing
                firstLastName
                floor
                forest
                forestAccessOnFoot
                garage
                ges
                hasNotaire
                isRented
                majorWork
                landscapedGarden
                noOpposite
                notRealised
                notSureTotalLandArea
                notaireAdress
                notaireName
                numberCommonWalls
                numberGarage
                othersTypeHeat
                parkingPlaces
                photos(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                plans(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                pool
                poolHeating
                price
                poolSize
                prm
                propertyCasualityInsurance
                propertyProof(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                propertyTax
                quiet
                rentersContactInformation
                secondTypeHeat
                sewageConnection
                shopsWalkingDistance
                singleStorey
                tenYearGuarantee
                tennis
                terrace
                totalLandArea
                trainStationWalk
                type {
                  data {
                    id
                    attributes {
                      slug
                      label
                      weigth
                      type_rules(pagination: { limit: -1 }) {
                        data {
                          attributes {
                            label
                          }
                        }
                      }
                      thumbnail {
                        data {
                          id
                          attributes {
                            url
                          }
                        }
                      }
                    }
                  }
                }
                typeHeat
                unobstructedView
                updatedAt
                vegetableGarden
                videos(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                wood
              }
            }
          }
          agency {
            data {
              id
              attributes {
                name
                address
                postalCode
                city
                thumbnail {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                email
                honoraire
                phoneNumber
                lonlat
                searchVisible
              }
            }
          }
          mandat {
            data {
              id
              attributes {
                completed
                isSigned
                mandateNumber
                customFees
                mandatpdf(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                ownerSigned
                ownersValidated
                signature {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                signatureAgency {
                  data {
                    attributes {
                      url
                    }
                    id
                  }
                }
                signatureDate
                relatedUsers
              }
            }
          }
          amendments {
            data {
              id
              attributes {
                price
                fees
                amendmentPdf(pagination: { limit: -1 }) {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                isSigned
                signatureDate
                completed
                ownerTown
                relatedUsers
                signature {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
                signatureAgency {
                  data {
                    id
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
