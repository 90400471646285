import jsSHA from "jssha";
import { encode } from "base-64";
import axios from "axios";

import { Accomodation } from "../models/Accomodation";
import { IHonoraire } from "../models/Agency";
import { Amendment } from "../models/Amendment";

/**
 * Used to get city from AddressFound item
 * @params Accomodation
 * @returns string contain city
 */
export const getCityFromAddress = (acc: Accomodation) => {
  const pattern = /\d{5}(.*)$/g;
  return acc.address.address.match(pattern)?.toString().slice(6);
};

/**
 * Used to get postal code from AddressFound item
 * @params Accomodation
 * @returns string contain postal code
 */
export const getPostalCodeFromAddress = (acc: Accomodation) => {
  const pattern = /\d{5}(.*)$/g;
  return acc.address.address.match(pattern)?.toString().slice(0, 6);
};

/**
 * Used to return price formated with locales.
 * @params number contain price
 * @returns string contain price
 */
export const formatPrice = (price: number): string =>
  Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(
    price
  );

type ImageOptions = {
  format?: "jpg" | "jpeg" | "png" | "webp" | "gif";
  width?: number;
  height?: number;
  resize?: string;
};

export const fetchFromImgProxy = (url: string, options: ImageOptions) => {
  function hex2a(hexx: any) {
    const hex = hexx.toString(); //force conversion
    let str = "";
    for (let i = 0; i < hex.length; i += 2)
      str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
  }

  // URL generator logic
  function generateProxyUrl(opts: any) {
    const encodedUrl = encode(opts.url)
      .replace(/[=]/g, "")
      .replace(/\//g, "_")
      .replace(/\+/g, "-");
    const path =
      "/rs:" +
      opts.resize +
      ":" +
      opts.width +
      ":" +
      opts.height +
      ":" +
      opts.enlarge +
      "/g:" +
      opts.gravity +
      "/" +
      encodedUrl +
      "." +
      opts.extension;
    const shaObj = new jsSHA("SHA-256", "BYTES");
    shaObj.setHMACKey(opts.key, "HEX");
    shaObj.update(hex2a(opts.salt));
    shaObj.update(path);
    const hmac = shaObj
      .getHMAC("B64")
      .replace(/[=]/g, "")
      .replace(/\//g, "_")
      .replace(/\+/g, "-");
    return opts.proxyUrl + "/" + hmac + path;
  }

  if (url.includes("https"))
    return generateProxyUrl({
      url,
      proxyUrl: process.env.REACT_APP_IMGPROXY_URL,
      resize: options.resize || "",
      width: options.width || "",
      height: options.height || "",
      extension: options.format || "webp",
      enlarge: 0,
      gravity: "no",
      key: process.env.REACT_APP_IMGPROXY_KEY,
      salt: process.env.REACT_APP_IMGPROXY_SALT
    });

  return url;
};

export const formatPhone = (phoneNumber: string) => {
  return `+33${phoneNumber.slice(1)}`;
};

export const formatPhoneReverse = (phoneNumber: string) => {
  return `0${phoneNumber.slice(3)}`;
};

export const makeLonLat = async (
  address: string,
  postalCode: string,
  city: string
) => {
  const req = axios.create({
    baseURL: "https://api-adresse.data.gouv.fr/"
  });

  try {
    const rslt = await req.get(
      `search/?q=${address},${postalCode + city}&limit=10`
    );

    return rslt.data.features[0].geometry.coordinates;
  } catch (e) {
    console.log("e :>> ", e);
  }
};

/**
 * Distance calculator between two coordinates objects.
 */
export const distanceCalculator = (acc: number[], agency: number[]) => {
  // The math module contains a function
  // named toRadians which converts from
  // degrees to radians.
  const accLon = (acc[0] * Math.PI) / 180;
  const agencyLon = (agency[0] * Math.PI) / 180;
  const accLat = (acc[1] * Math.PI) / 180;
  const agencyLat = (agency[1] * Math.PI) / 180;

  // Haversine formula
  const dlon = agencyLon - accLon;
  const dlat = agencyLat - accLat;
  const a =
    Math.pow(Math.sin(dlat / 2), 2) +
    Math.cos(accLat) * Math.cos(agencyLat) * Math.pow(Math.sin(dlon / 2), 2);

  const c = 2 * Math.asin(Math.sqrt(a));

  // Radius of earth in kilometers. Use 3956
  // for miles
  const r = 6371;

  // calculate the result
  return c * r;
};

export const priceWithHonoraireCalculator = (
  price: number,
  honoraires: IHonoraire[],
  customFees: string | undefined
): number => {
  if (honoraires.length === 0) return price;

  let honoraire = honoraires.find((item) => {
    return item.to >= price && item.from <= price;
  });

  if (!honoraire) honoraire = honoraires[honoraires.length - 1];
  if (customFees) honoraire!.percent = Number(customFees);
  if (String(honoraire.percent).length > 2) return price + honoraire.percent;

  return price + (honoraire.percent / 100) * price;
};

/** Used to get last amendment signed by agency */
export const getLastSigned = (amds: Amendment[]): string | null => {
  return (
    amds
      .slice()
      .sort((a, b) => Number(b.id) - Number(a.id))
      .find((amd) => !!amd.signatureAgency)?.id || null
  );
};

/** Used check if we display amendment signatory panel */
export const displayAmendment = (amendments: Amendment[]): boolean => {
  return amendments.length > 0 &&
    amendments
      .slice()
      .sort((a, b) => Number(b.id) - Number(a.id))
      .find(
        (amendment) =>
          !amendment.signatureAgency?.id &&
          (!getLastSigned(amendments) ||
            (getLastSigned(amendments) &&
              Number(getLastSigned(amendments)) < Number(amendment.id)))
      )
    ? true
    : false;
};
