import React, { FC } from "react";

import { PastilleComponent } from "./Pastille.type";

/**
 * Pastille used to display pastille.
 */
export const Pastille: FC<PastilleComponent> = ({ type }) => {
  switch (type) {
    case "available":
      return (
        <div className="absolute rounded-full w-10 h-10 top-0 left-0 mt-2 ml-2 bg-nodimoblue flex justify-center items-center text-white shadow-lg">
          <i className="fa-solid fa-file"></i>
        </div>
      );
      break;

    case "refused":
      return (
        <div className="absolute rounded-full w-10 h-10 top-0 left-0 mt-2 ml-2 bg-nodimoerror flex justify-center items-center text-white shadow-lg">
          <i className="fa-solid fa-clock"></i>
        </div>
      );
      break;

    default:
      return (
        <div className="absolute rounded-full w-10 h-10 top-0 left-0 mt-2 ml-2 bg-gray-400 flex justify-center items-center text-white shadow-lg">
          <i className="fa-solid fa-clock"></i>
        </div>
      );
      break;
  }

  return <></>;
};
