import { useLazyQuery, useQuery } from "@apollo/client";
import { reaction } from "mobx";
import { observer } from "mobx-react-lite";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, Outlet } from "react-router-dom";
import { Header } from "../components/Header/Header";

import { FETCH_ALL_CONVS, MARK_AS_READ } from "../lib/gql/Conversations";
import {
  Conversation,
  conversationFactory,
  IConversation
} from "../lib/models/Conversation";
import { Message } from "../lib/models/Message";
import { useAgencyStore, useUserStore } from "../lib/store";
import { useConversationStore } from "../lib/store/conversation/useConversationStore";

export const ChatSelectorScreen = observer(() => {
  const agencyStore = useAgencyStore();
  const conversationStore = useConversationStore();
  const userStore = useUserStore();

  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [newList, setNewList] = useState<Conversation[]>([]);

  const handleSearch = (search: string) => {
    if (conversationStore.conversations.length === 0) return;
    setNewList(
      conversationStore.conversations
        .filter(
          (f) =>
            search === "" ||
            f.user.firstName
              .concat(" ", f.user.lastName)
              .toLowerCase()
              .includes(search.toLowerCase())
        )
        .sort((a, b) => a.user.lastName.localeCompare(b.user.lastName))
        .sort((a, b) => {
          if (a.messages.length === 0 && b.messages.length === 0) return 0;
          if (a.messages.length === 0 && b.messages.length > 0) return 1;
          if (b.messages.length === 0 && a.messages.length > 0) return -1;

          return moment(b.messages[b.messages.length - 1].date).diff(
            moment(a.messages[a.messages.length - 1].date)
          );
        })
    );
  };

  const { data, refetch } = useQuery(FETCH_ALL_CONVS, {
    fetchPolicy: "cache-and-network",
    pollInterval: 5000,
    notifyOnNetworkStatusChange: true
  });

  const [markAsRead] = useLazyQuery(MARK_AS_READ, {
    fetchPolicy: "network-only",
    onCompleted: () => refetch()
  });

  useEffect(() => {
    if (!data) return;

    conversationStore.setConversations(
      data.conversations.data.map((conv: IConversation) =>
        conversationFactory(conv)
      )
    );
    handleSearch(searchTerm);
  }, [data]);

  const handleSelectConv = (conv: Conversation) => {
    markAsRead({ variables: { id: conv.id } });
    conversationStore.setSelectedConversation(conv);
  };

  useEffect(
    reaction(
      () => conversationStore.selectedConversation?.messages.slice(),
      () => refetch()
    ),
    []
  );

  useEffect(() => {
    handleSearch(searchTerm);
  }, [searchTerm]);

  const displayPastillas = (messages: Message[]) => {
    if (
      messages.length > 0 &&
      messages.find((mes) => !mes.isRead && mes.owner.roleName !== "Agency")
    )
      return true;
    return false;
  };

  return (
    <div className="wrapper">
      <Header agency={agencyStore.agency!} />
      <div className="messagesWrapper">
        <div className="messagesList w-96">
          <h2 className="text-3xl mb-6 font-bold text-nodimodarkblue mt-6">
            {t("messages")}
          </h2>
          <div className="messagesInputWrapper">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              type="text"
              className="agencySearch"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          {newList && newList.length > 0 ? (
            newList.map((conv) => (
              <Link
                key={conv.id}
                to={`${conv.id}`}
                style={{ textDecoration: "none", color: "black" }}
              >
                <div
                  className={`messagesItem p-4 ${
                    conversationStore.selectedConversation &&
                    conversationStore.selectedConversation.id === conv.id
                      ? "messagesItem--current"
                      : ""
                  }`}
                  onClick={() => handleSelectConv(conv)}
                >
                  <p className="flex flex-row items-center">
                    <span>
                      {conv.user.firstName + " " + conv.user.lastName}{" "}
                    </span>
                    {displayPastillas(conv.messages) && (
                      <span className="ml-4">
                        <span className="inline-flex rounded-full h-2 w-2 bg-nodimoblue"></span>
                      </span>
                    )}
                  </p>
                  {conv.messages.length > 0 ? (
                    <p className="mt-2">
                      {moment(
                        conv.messages[conv.messages.length - 1].date
                      ).fromNow()}
                    </p>
                  ) : null}
                  {conv.messages.length > 0 ? (
                    <>
                      <p className="mt-2">
                        {conv.messages[conv.messages.length - 1].owner.id ===
                        userStore.currentUser!.id
                          ? t("you")
                          : conv.user.firstName}{" "}
                        -{" "}
                        {conv.messages[conv.messages.length - 1].content
                          .length > 0
                          ? conv.messages[conv.messages.length - 1].content
                              .length > 20
                            ? `${conv.messages[
                                conv.messages.length - 1
                              ].content.slice(0, 20)}...`
                            : conv.messages[conv.messages.length - 1].content
                          : conv.messages[conv.messages.length - 1].contentFile
                              .length +
                            " " +
                            t("file", {
                              count:
                                conv.messages[conv.messages.length - 1]
                                  .contentFile.length
                            })}
                      </p>
                    </>
                  ) : (
                    <p className="mt-2">{t("noMessage")}</p>
                  )}
                </div>
              </Link>
            ))
          ) : (
            <div>
              <p>{t("noConversationsFound")}</p>
            </div>
          )}
        </div>

        <Outlet />
      </div>
    </div>
  );
});
