import { gql } from "@apollo/client";

export const UPDATE_AMENDMENT = gql`
  mutation ($id: ID!, $data: AmendmentInput!) {
    updateAmendment(id: $id, data: $data) {
      data {
        id
        attributes {
          price
          fees
          amendmentPdf(pagination: { limit: -1 }) {
            data {
              id
              attributes {
                url
              }
            }
          }
          isSigned
          signatureDate
          completed
          ownerTown
          relatedUsers
          signature {
            data {
              id
              attributes {
                url
              }
            }
          }
          signatureAgency {
            data {
              id
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
